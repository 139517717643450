<div class="main-content">
    <div class="container-fluid">
        <div class="row" *ngIf="!formSent">
            <form #form="ngForm" (ngSubmit)="onSubmit(form)">
                <div class="col-md-10 col-md-offset-1">
                    <h3>How Can We Help?</h3>
                    <div class="row">
                        <div class="col-sm-6 col-md-5">
                            <mat-form-field appearance="outline">
                                <mat-label>Team</mat-label>
                                <mat-select [(ngModel)]="survey.teamId" name="teamId" required>
                                    <mat-option *ngFor="let team of teams" [value]="team.id">{{ team.name }}</mat-option>
                                </mat-select>
                                <mat-error>Please select a team</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-6 col-md-5">
                            <mat-form-field appearance="outline">
                                <mat-label>Time Zone</mat-label>
                                <mat-select [(ngModel)]="survey.timeZone" name="timeZone" required>
                                    <mat-option *ngFor="let timeZone of USTimeZones" [value]="timeZone.value">{{ timeZone.name }}</mat-option>
                                </mat-select>
                                <mat-error>Please select time zone</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="row survey-info">
                            Click All That Apply
                        </div>
                        <div class="row">
                            <mat-checkbox [(ngModel)]="survey.inHomeAssessment"  name="inHomeAssessment">In-Home Assessment</mat-checkbox>
                        </div>
                        <div class="row">
                            <mat-checkbox [(ngModel)]="survey.findingAFacility" name="findingAFacility">Finding a Facility</mat-checkbox>
                        </div>
                        <div class="row">
                            <mat-checkbox [(ngModel)]="survey.findingInHomeServices" name="findingInHomeServices">Finding In-Home Services</mat-checkbox>
                        </div>
                        <div class="row">
                            <mat-checkbox [(ngModel)]="survey.legalAdvice" name="legalAdvice">Legal Advice</mat-checkbox>
                        </div>
                        <div class="row">
                            <mat-checkbox [(ngModel)]="survey.financialEducation" name="financialEducation">Financial Education</mat-checkbox>
                        </div>
                    </div>

                    <div class="col-md-5">
                        <div class="row survey-info">
                            Click All That Apply
                        </div>
                        <div class="row">
                            <mat-checkbox [(ngModel)]="survey.planningForTheFuture" name="planningForTheFuture">Planning for the Future</mat-checkbox>
                        </div>
                        <div class="row">
                            <mat-checkbox [(ngModel)]="survey.needAssistanceNow" name="needAssistanceNow">Need Assistance Now</mat-checkbox>
                        </div>
                        <div class="row">
                            <div>Personal Message</div>
                            <textarea [(ngModel)]="survey.personalMessage" name="personalMessage"></textarea>
                        </div>
                    </div>

                    <div class="col-auto col-align-center-justify-center" style="clear: both;">
                        <button type="submit" class="btn btn-coach" [disabled]="disableButton">
                            Send
                        </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="row coaching-message" *ngIf="formSent">
            <div class="col-xs-12">
                <h2>Thank you for your request.</h2>
                <img src="assets/img/care-coach.jpg">
                <h2>A Care Coach will message you directly to assist.</h2>
            </div>
        </div>
    </div>
</div>
