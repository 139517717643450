import {
  AbstractControl,
  AsyncValidator,
  NG_ASYNC_VALIDATORS,
  ValidationErrors
} from '@angular/forms';
import { Directive, Input } from '@angular/core';
import { UserService } from '../../providers/user.service';
import { from, Observable, throwError } from 'rxjs';
import { Logger } from '../../providers/logger.service';
import { catchError, map } from 'rxjs/operators';
import { LocalData } from '../../providers/local-data.service';
import { UserModel } from '../../models/user.model';


@Directive({
  selector: '[appPasswordValidator]',
  providers: [{
    provide: NG_ASYNC_VALIDATORS,
    useExisting: PasswordValidatorDirective,
    multi: true
  }]
})


export class PasswordValidatorDirective implements AsyncValidator {
  private source = 'PasswordValidatorDirective';
  @Input() teamId: string;
  @Input() recipientId: string;

  user: UserModel

  constructor(private userService: UserService, private logger: Logger, private localData: LocalData) {
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    const validationResult = this.userService.passwordValidator(null,this.teamId,this.recipientId)(control)
    const observable = from(validationResult);
    return observable.pipe(
      map((validationErrors: ValidationErrors | null) => {
        return validationErrors;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'validate', err, 'API Error');
        return throwError(err);
      })
    );
  }
}
