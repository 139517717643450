import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from 'providers/auth.service';
import { Logger } from 'providers/logger.service';
import { UserService } from 'providers/user.service';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private logger: Logger,
    private userService: UserService,
    private authService: AuthService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (this.isLoggedIn()) {
      return this.authService.isAuthenticated()
        .pipe(
          catchError((err) => {
            // TODO: Redirect to error page
            return of(false);
          }),
        );
    }
    this.logoutWithReturnUrl(state.url)

    return of(false);
  }

  logoutWithReturnUrl(url: string) {
    this.authService.signOut().subscribe(() => {
      if (url.includes("login")) {
        this.router.navigate([url]);
      } else {
        this.router.navigate(['/login'],
          { queryParams: { return_url: url } }
        );
      }
    })
  }

  public isLoggedIn(): boolean {
    return !!localStorage.getItem('loggedIn');
  }
}
