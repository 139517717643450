<nav class="navbar navbar-fixed-top">
  <div class="container">
    <div class="navbar-header">
      <div class="login-brand">
        <div class="logo">
          <div class="logo-normal">
            <a
              [href]="landingPageUrl"
              class="simple-text simple-text-title"
              target="_blank"
            >
              <img
                class="word-mark"
                src="./assets/img/logo_BH-horizontal-white.png"
                alt="Bright Horizons"
              />
            </a>
          </div>

          <div class="service-labels">
            <span class="service-label">
              Senior Services & Support
            </span>
            <span class="service-label">
              Coaching & Planning through Elder Care
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</nav>
