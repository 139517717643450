<br>
<br>
<br>
<div class="row">
  <div class="col-lg-2">

  </div>
  <div class="col-lg-8">
    <div class="alert alert-success text-center" role="alert" *ngIf="success">
      <h4 class="header-text">
        You have been successfully verified!
      </h4>
      <a mat-button color="accent" href="/register?token={{this.token}}"> Continue Registration </a>
    </div>

    <div class="alert alert-danger" role="alert" *ngIf="failure" >
      <h4 class="header-text text-center">
        Sorry, we are not able to verify you.
      </h4>
    </div>
  </div>

  <div class="col-lg-2">

  </div>
</div>

<footer>
  <div>
    <div class="brand-name">BH Elder Care</div> powered by eLivelihood
  </div>
</footer>
