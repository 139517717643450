import { UserModel } from './user.model';
import {PaginatorModel} from './paginator.model';
import {languages} from '../constants/languages';

export class TeamModel {
  id: string;
  name: string;
  ownerId: string
  avatarUrl: string;
  activeSubscriptionType: number;
  billingCustomerId: string;
  billingCustomerActiveTokenId: string;
  billingSubscriptionId: string;
  billingSubscriptionActivePlanId: string;
  billingCycleAnchor: Date;
  created: Date;
  updated: Date;
  deleted: Date;
  teamUsers: Array<TeamUserModel>;
  status: number;
  primaryLanguage: string;
  referrer: string;
  client?: string;
  public originalInvitedByEmail?: string
  public coachId?: string
  public coachName?: string
}

export class TeamUserModel {
  userId: string;
  teamRoleId: number;
  permissions: TeamPermissionsModel;
  user: UserModel;
  timeSpentTotal: number;
  isOwner: boolean;
  selected = false;
  // injected at UI
  permissionsString: string;
  newMessages: number;

  public originalInvitedByEmail?: string
}

export class TeamPermissionsModel {
  messages: number;
  calendar: number;
  media: number;
  safekeeper: number;
  health: number;
}

export class UserAndTeams {
  user: UserModel;
  teams: Array<TeamModel>;
  constructor (user: UserModel, teams: Array<TeamModel>) {
    this.user = user;
    this.teams = teams;
  }
}

export class Teams {
  items: Array<TeamModel>;
}

export class CoachTeamModel extends TeamModel {
  lastLogin: Date;
  coachId: string;
  coachName: string;
  coachStatus: number;
  requestId: string;
  adminName: string;
  adminEmail: string;
  timeZone: string;
  phone?: string;
}

export class GetCoachTeamsModel extends PaginatorModel {
  direction: 'asc'|'desc' = 'desc';
  orderBy = 'date';
  filter: string;
  coachId: string;
  filterColumn?: string;
}

export class OwnershipTransfer {
  id: string;
  teamId: string;
  oldOwnerId: string;
  newOwnerId: string;
  created: Date;
  updated: Date;
  deleted: Date;
}

export const TeamRoles = {
  ADMIN: 1,
  RECIPIENT: 2,
  CONTRIBUTOR: 3,
  COACH: 4
}

export class TeamConstants {
  // team role
  ADMIN = 1;
  RECIPIENT = 2;
  CONTRIBUTOR = 3;
  COACH = 4;

  // team member roles (not recipient)
  TEAM_MEMBER_ROLES = ['Administrator', 'Contributor', 'Coach'];

  // status
  FREE = 0;
  ACTIVE = 2;
  TEST = 4;
  SUSPEND_NON_PAY = 11;
  SUSPEND_FRAUD = 97;
  CLOSED = 98;
  DELETED = 99;

  // user states allowed for admin change
  ADMIN_TEAM_STATUS = [
    { number: 0, name: 'FREE'},
    { number: 2, name: 'ACTIVE'},
    { number: 4, name: 'TEST'},
    { number: 11, name: 'SUSPEND_NON_PAY'},
    { number: 97, name: 'SUSPEND_FRAUD'},
    { number: 98, name: 'CLOSED'},
    { number: 99, name: 'DELETED'}
  ];

  // billing
  ACTIVE_SUB_TYPE_MEDIA_ONLY = 1;
  ACTIVE_SUB_TYPE_CARE_MGMT = 2;

  // permissions
  TEAM_PERMISSIONS = ['Create/Edit', 'Read Only', 'No Access'];
  TEAM_PERMISSIONS_MESSAGES = ['Create/Edit', 'No Access'];
  TEAM_PERMISSION_NOACCESS = 'No Access';
  TEAM_PERMISSION_NOACCESS_NUM = 1;
  TEAM_PERMISSION_READONLY = 'Read Only';
  TEAM_PERMISSION_READONLY_NUM = 2;
  TEAM_PERMISSION_EDIT = 'Create/Edit';
  TEAM_PERMISSION_EDIT_NUM = 3;

  // default permissions
  ADMIN_PERMISSIONS = {media: 3, health: 2, calendar: 3, messages: 3, safekeeper: 3};
  RECIPIENT_PERMISSIONS = {media: 3, health: 2, calendar: 3, messages: 3, safekeeper: 1};
  CONTRIBUTOR_PERMISSIONS = {media: 3, health: 2, calendar: 3, messages: 3, safekeeper: 3};

  teamDefaultPermissions(teamRoleId) {
    switch (teamRoleId) {
      case this.ADMIN: {
        return this.ADMIN_PERMISSIONS;
      }
      case this.RECIPIENT: {
        return this.RECIPIENT_PERMISSIONS;
      }
      case this.CONTRIBUTOR: {
        return this.CONTRIBUTOR_PERMISSIONS;
      }
      default: {
        return null;
      }
    }
  }
}
