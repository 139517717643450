export class MediaItem {
  id: string;
  type: number;
  ownerId: string;
  teamId: string;
  pathId: string;
  title: string;
  summary: string;
  mediaUrl: string;
  mediaType: string;
  mediaFilename: string
  thumbnailUrl: string;
  thumbnailFilename: string;
  created: Date;
  updated: Date;
  deleted: Date;
  // injected at UX
  ownerName: string;
  fileUri: string;
  editMode: boolean;
}

export class Media {
  items: Array<MediaItem>;
}

export class FileUploadStatus {
  fileName: string;
  status: string;
  error: boolean;
  class: string;
}

export class MediaConstants {
  // default avatar
  DEFAULT_VIDEO_THUMBNAIL = '/assets/img/default-video-thumbnail.png';

  // type
  IMAGE = 0;
  VIDEO = 1;
}