import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

// rxjs
import {
  throwError as observableThrowError,
  Observable,
} from 'rxjs';
import { catchError, map } from 'rxjs/operators';

// models
import { CoverRightClick } from 'models/coverright-click.model';

// providers
import { environment } from './../environments/environment';
import { Utils } from './utils.service';
import { Logger } from './logger.service';

@Injectable()
export class ActivitiesService {
  private source = 'ActivitiesService';
  private apiBaseUrl = environment.apiBaseUrl;

  constructor(
    private http: HttpClient,
    private utils: Utils,
    private logger: Logger
  ) { }

  coverRightClicked(coverRightClick: CoverRightClick): Observable<CoverRightClick> {
    this.logger.logInfo(this.source, 'createUser', 'API post /activities/coverright-clicked');
    const body = coverRightClick;
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/activities/coverright-clicked', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'coverRightClicked', { status: res.status, statusText: res.statusText }, 'API post /activities/coverright-clicked');
        this.logger.logTrace(this.source, 'coverRightClicked', null, 'API post /activities/coverright-clicked');
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'coverRightClicked', err, 'API Error');
        return observableThrowError(err);
      }));
  }
}
