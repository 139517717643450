import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { CodeInputComponent } from 'angular-code-input';
import { AuthService } from 'providers/auth.service';
import { UserService } from 'providers/user.service';
import {
  BehaviorSubject,
  Observable,
  timer,
} from 'rxjs';
import {
  filter,
  map,
  switchMap,
} from 'rxjs/operators';

export interface DialogData {
  phonePreview?: string;
  phoneVerified?: boolean;
}

export type Status = 'existing-phone'|'update-phone'|'code-sent'|'loading';

@Component({
  selector: 'app-verification-dialog',
  templateUrl: './verification-dialog.component.html',
  styleUrls: ['./verification-dialog.component.scss'],
})
export class VerificationDialogComponent implements OnInit {
  private source = 'VerificationDialogComponent';
  @ViewChild('codeInput') codeInput!: CodeInputComponent;
  status: Status = 'existing-phone';
  resendDelayPeriodInSeconds = 60;
  counterStartPoint$ = new BehaviorSubject(this.resendDelayPeriodInSeconds);
  counter$: Observable<string>;
  resendDisabled$: Observable<boolean>;
  phoneNumForm: FormGroup;
  // request is saved for 'resend'.
	sendCodeReq$: Observable<boolean>;
  errMsg: string = null;
  lastPhoneNumber:string = null;
  lastStatus:Status;

  constructor(
    public dialogRef: MatDialogRef<VerificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private userService: UserService,
    private router: Router,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.initCounter();

    this.phoneNumForm = this.fb.group({
      phoneNumber: [null, [Validators.required]]
    });

    if (this.data.phoneVerified) {
      this.status = 'loading';
      this.onSendCodeClick();
    }

    if (!this.data.phonePreview?.length) {
      this.status = 'update-phone';
    }
  }

  initCounter() {
    const counterNum = this.counterStartPoint$.pipe(
      switchMap((s) => {
        return timer(0, 1000).pipe(
          map((t) => s - t),
          filter(t => t >= 0),
        );
      }),
    );
    this.counter$ = counterNum.pipe(map(c => c > 0 ? c.toString() : ''));
    this.resendDisabled$ = counterNum.pipe(map(c => c > 0 ));
  }

  onSendCodeClick() {
    this.prepareSendCodeRequest();
    this.sendSendCodeRequest();
  }

  onResendCodeClick() {
    this.sendSendCodeRequest();
  }

  prepareSendCodeRequest() {
    if (this.status === 'update-phone' && this.phoneNumForm.invalid) {
      this.phoneNumForm.markAllAsTouched();
      return;
    }

    if (this.data.phoneVerified) {
      this.sendCodeReq$ = this.userService.sendLoginVerification();
    } else if (this.status === 'update-phone') {
      this.sendCodeReq$ = this.userService.sendPhoneNumberVerification(this.phoneNumForm.value.phoneNumber);
    } else {
      this.sendCodeReq$ = this.userService.sendPhoneNumberVerification();
    }
  }

  sendSendCodeRequest() {
    this.sendCodeReq$.subscribe(sent => {
      if (sent) {
        this.lastPhoneNumber = this.phoneNumForm.get('phoneNumber').value;
        this.resetAndSwitchState('code-sent');
        this.errMsg = null;
      }
    },
    err => this.handleSendCodeApiError(err));
  }

  handleSendCodeApiError(err){
    if (err.status === 400 && err.error.Message === "bad-phone-number" && !this.data.phoneVerified) {
      var msg = "We cannot send verification code to this number, please enter a valid number and try again.";
      this.errMsg = msg;
      this.snackBar.open(msg, 'close', {
        duration: 4000
      });
      this.resetAndSwitchState('update-phone');
      return;
    }

    var msg = "Something went wrong, please try again.";
      this.errMsg = msg;
    this.snackBar.open(msg, 'close', {
      duration: 4000
    });
  }

  onChangeNumClicked() {
    this.lastStatus = this.status;
    this.status = 'update-phone';
  }

  onCancelClick() {
    if (this.lastStatus) {
      this.status = this.lastStatus;
    }
    this.phoneNumForm.reset();
    this.phoneNumForm.get('phoneNumber').setValue(this.lastPhoneNumber);
  }

  resetAndSwitchState(status: Status) {
    this.resetResendCounter();
    this.status = status;
    if (this.codeInput) {
      this.codeInput.reset();
    }
  }

  resetResendCounter() {
    this.counterStartPoint$.next(this.resendDelayPeriodInSeconds);
  }

  onCodeCompleted(code) {
    this.status = 'loading';

    let req$: Observable<boolean>;
    if (this.data.phoneVerified) {
      req$ = this.userService.verifyLogin(code);
    } else {
      let phoneNum = this.phoneNumForm.get('phoneNumber')?.value
      phoneNum = phoneNum?.length ? phoneNum : null
      req$ = this.userService.verifyPhoneNumber(code, phoneNum);
    }

    req$.subscribe(verified => {
      if (verified) {
        this.dialogRef.close(true);
      } else {
        this.snackBar.open("The code you entered is invalid, please try again.", 'close', {
          duration: 4000
        });
      }
      this.status = 'code-sent';
      if (this.codeInput) {
        this.codeInput.reset();
      }
    },
    err => {
      this.snackBar.open("Something went wrong, please try again.", 'close', {
        duration: 4000
      });
      this.status = 'code-sent';
      if (this.codeInput) {
        this.codeInput.reset();
      }
    });
  }

  logout() {
    this.authService.signOut()
      .subscribe(() => {
        this.dialogRef.close(false);
        this.router.navigate(['login']);
      });
  }

  get phonePreview() {
    return this.phoneNumForm.get('phoneNumber')?.value ?? this.data.phonePreview;
  }

  get showCancelButton() {
    return !this.data.phoneVerified &&
      this.status === 'update-phone' &&
      (this.lastPhoneNumber || !!this.data.phonePreview?.length)
  }
}
