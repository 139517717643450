<h1 mat-dialog-title>Your password has expired</h1>
<form [formGroup]="form" (submit)='onSubmit()'>
  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>Current Password</mat-label>
      <input type="password" matInput formControlName="currentPassword">
      <mat-error *ngIf="form.get('currentPassword').hasError('required')">Current password is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>New Password</mat-label>
      <input type="password" matInput formControlName="password">
      <mat-error *ngIf="form.get('password').hasError('required')">Password is required</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Repeat New Password</mat-label>
      <input type="password" matInput formControlName="confirmPassword">
      <mat-error *ngIf="form.get('confirmPassword').hasError('required')">Password Confirmation is required</mat-error>
      <mat-error *ngIf="form.get('confirmPassword').hasError('noMatch')">Password does not match</mat-error>
    </mat-form-field>
  </div>
  <div class="row">
    <div class="col-sm-12">
              <span *ngIf="formErrMsg" class="text-danger">{{ formErrMsg }}
                <br>
              </span>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button color="primary" type="submit">Submit</button>
  </div>
</form>
