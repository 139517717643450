import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TeamService } from '../../../../providers/team.service';
import { ReplaceRecipientEmailModel } from '../../../../models/replace-recipient-email-model';
import { ApiResponseModel } from '../../../../models/api-response.model';

export interface DialogData{
  teamId: string
}
@Component({
  selector: 'recipient-remove-email',
  templateUrl: 'recipient-remove-email.component.html'
})
export class RecipientRemoveEmailComponent implements OnInit{
  removeMailForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<RecipientRemoveEmailComponent>,
              private fb: FormBuilder,
              private snackBar: MatSnackBar,
              private teamService: TeamService,
              @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    console.log(data)

  }
  handleSubmit = () => {
    if(this.removeMailForm.valid){
      let model = new ReplaceRecipientEmailModel()
      model.teamId = this.data.teamId
      model.username = this.removeMailForm.get('username').value
      this.teamService.updateReplaceRecipientEmail(model).subscribe((res: ApiResponseModel) => {
        this.showSnackBar(res.message)
        if(!res.isError)
            this.dialogRef.close(true)
      })
    }
  }
  showSnackBar(message) {
    this.snackBar.open(message, "close", {
      duration: 2000,
    });
  }
  ngOnInit(): void {
    this.removeMailForm = this.fb.group({
      'username': ['', Validators.required],
    });
  }

}
