import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

// rxjs
import {throwError as observableThrowError, of as observableOf,  BehaviorSubject ,  Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';

// models
import { DeviceData } from './../models/device.model';
import { UserModel, UserConstants } from '../models/user.model';

// providers
import { environment } from './../environments/environment';

@Injectable()
export class Logger {
  private userConstants: UserConstants = new UserConstants();
  private FATAL = 'FATAL';
  private WARN = 'WARN';
  private ERROR = 'ERROR';
  private INFO = 'INFO';
  private DEBUG = 'DEBUG';
  private TRACE = 'TRACE';

  public userId: string;
  public device: DeviceData;
  public logLevels: any;
  private log = true;
  public logToCloud: boolean;
  public logToConsole: boolean;
  private apiBaseUrl = environment.apiBaseUrl;

  public retryTransmitLogLineToCloud: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public logLineCacheCount: number;
  private cacheBusy = false;

  constructor(
    private http: HttpClient,
  ) {
    this.logLevels = environment.defaultLogLevels;
    this.logToCloud = environment.logToCloud;
    this.logToConsole = environment.logToConsole;
    this.device = new DeviceData();

    const logLineCache: Array<string> = JSON.parse(localStorage.getItem('logLineCache'));
    this.logLineCacheCount = logLineCache ? logLineCache.length : 0;

    // subscribe for retry
    this.retryTransmitLogLineToCloud
      .subscribe(retry => {
        if (retry && this.logToCloud) {
          const isLoggedIn  = JSON.parse(localStorage.getItem('loggedIn'));
          if (isLoggedIn && !this.cacheBusy) {
            this.doRetryTransmitToCloud();
          }
        }
      }, err => {
        this.manageLogLineCache(err);
      });
  }

  logFatal(source: string, func: string, json: any, message: string) {
    if (this.log && this.logLevels.fatal) {
      this.logLine(this.FATAL, source, func, json, message);
    }
  }

  logError(source: string, func: string, json: any, message: string) {
    if (this.log && this.logLevels.error) {
      this.logLine(this.ERROR, source, func, json, message);
    }
  }

  logWarn(source: string, func: string, message: string) {
    if (this.log && this.logLevels.warn) {
      this.logLine(this.WARN, source, func, null, message);
    }
  }

  logInfo(source: string, func: string, message: string) {
    if (this.log && this.logLevels.info) {
      this.logLine(this.INFO, source, func, null, message);
    }
  }

  logDebug(source: string, func: string, json: any, message: string) {
    if (this.log && this.logLevels.debug) {
      this.logLine(this.DEBUG, source, func, json, message);
    }
  }

  logTrace(source: string, func: string, json: any, message: string) {
    if (this.log && this.logLevels.trace) {
      this.logLine(this.TRACE, source, func, json, message);
    }
  }

  logLine(level: string, source: string, func: string, json: any, message: string) {
    const jsonMsg = {
      level: level, when: new Date().toUTCString(), source: source, function: func, message: message, json: json,
      idMeta: this.setIdMeta()
    };
    if (this.logToConsole) {
      console.log(JSON.stringify(jsonMsg));
    }
    if (this.logToCloud) {
      this.sendLogLineToCloud(jsonMsg);
    }
  }

  setIdMeta(): any {
    let idMeta = {
      userId: this.userId,
      devicePlatform: this.device.platform,
      deviceUuid: this.device.uuid
    }
    if (!idMeta.userId && !idMeta.devicePlatform && !idMeta.deviceUuid) {
      return null;
    }
    return idMeta;
  }

  sendLogLineToCloud(jsonMsg: any) {
    const isLoggedIn = !!JSON.parse(localStorage.getItem('loggedIn'));
    if (isLoggedIn) {
      this.transmitLogLineToCloud([jsonMsg])
        .subscribe(status => {
          // success, nothing to do
        }, err => {
          this.addLogLineToCache(jsonMsg);
          this.manageLogLineCache(err);
        });
    } else {
      this.addLogLineToCache(jsonMsg);
      this.manageLogLineCache({status: 401});
    }
  }

  transmitLogLineToCloud(logLines: any): Observable<Observable<number>> {
    const body = logLines;
    let userId = this.userConstants.SYSTEM_USER_ID;
    const user_local: UserModel = JSON.parse(localStorage.getItem('user'));
    if (user_local) {
      userId = user_local.id;
    }
    return this.http.post(this.apiBaseUrl + '/utils/users/' + userId + '/log', body, {
      observe: 'response',
      responseType: 'json'
    }).pipe(
      map((res: HttpResponse<any>) => {
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        return observableThrowError(err);
      }),);
  }

  manageLogLineCache(err: any) {
    if (err.status !== 401) {
      const jsonMsg = {
        level: 'ERROR', when: new Date().toUTCString(), source: 'Logger', function: 'transmitLogLineToCloud', message: 'API error', json: err,
        idMeta: this.setIdMeta()
      };
      this.addLogLineToCache(jsonMsg);
    }
    const logLineCache: Array<string> = JSON.parse(localStorage.getItem('logLineCache'));
    if (logLineCache && logLineCache.length > environment.maxLogLineCache) {
      logLineCache.splice(environment.maxLogLineCache%3, environment.maxLogLineCache%10);
    }
  }

  addLogLineToCache(jsonMsg: any) {
    if (this.cacheBusy) {
      setTimeout(() => {
        this.addLogLineToCache(jsonMsg);
      }, 100);
    } else {
      this.cacheBusy = true;
      let logLineCache: Array<string> = JSON.parse(localStorage.getItem('logLineCache'));
      if (!logLineCache) {
        logLineCache = new Array<string>();
      }
      logLineCache.push(JSON.stringify({id: this.generateGuid(), event: jsonMsg}));
      localStorage.setItem('logLineCache', JSON.stringify(logLineCache));
      this.cacheBusy = false;
    }
  }

  doRetryTransmitToCloud() {
    this.cacheBusy = true;
    let logLines = new Array<string>();
    const logLineCache: Array<string> = JSON.parse(localStorage.getItem('logLineCache'));
    if (logLineCache && logLineCache.length > 0) {
      if (logLineCache.length > environment.flushLogLineCacheChunk) {
        logLines = logLineCache.slice(0, environment.flushLogLineCacheChunk);
      } else {
        logLines = logLines.concat(logLineCache);
      }
      const logLines_json = new Array<any>();
      for (const logLine of logLines) {
        logLines_json.push(JSON.parse(logLine).event);
      }
      this.transmitLogLineToCloud(logLines_json)
        .subscribe(status => {
          for (const logLine of logLines) {
            const idx = logLineCache.findIndex(item => {
              return item.includes(JSON.parse(logLine).id);
            });
            if (idx > -1) {
              logLineCache.splice(idx, 1);
            }
          }
          localStorage.setItem('logLineCache', JSON.stringify(logLineCache));
          this.doRetryTransmitToCloud();
        }, err => {
          this.manageLogLineCache(err);
          this.cacheBusy = false;
        });
    } else {
      this.cacheBusy = false;
    }
  }

  // utils
  generateGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
      return v.toString(16);
    });
  }
}
