import { Component, OnInit, AfterViewInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

// models
import { UserModel, UserTeamsModel } from './../../models/user.model';

// providers
import { UserService } from './../../providers/user.service';
import { Utils } from './../../providers/utils.service';
import { LocalData } from './../../providers/local-data.service';
import { Logger } from './../../providers/logger.service';

import { SiteParameters } from 'environments/site.default';

declare const $: any;

@Component({
  selector: 'app-team-new-cmp',
  templateUrl: 'team-new.component.html'
})

export class NewTeamComponent implements OnInit, AfterViewInit {
  public source = 'NewTeamComponent';
  public user: UserModel = new UserModel();
  public recipient: UserModel = new UserModel();
  public userTeams: UserTeamsModel[] = new Array<UserTeamsModel>();
  public walkthroughUrl = SiteParameters.URLS.WALKTHROUGH_URL;
  // remove when rendered only in a modal
  @Input() asModal = false;
  @Output() done = new EventEmitter();
  @Output() canceled = new EventEmitter();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private utils: Utils,
    private logger: Logger,
    private localData: LocalData
  ) { }

  ngOnInit() {
    this.utils.pageTitleSource.next('Add Recipient');
    this.user = this.localData.getUser();
    if (this.user.walkthrough < 6) {
      this.router.navigate([this.walkthroughUrl]);
    }
    this.userService.getUserTeams(this.user.id)
      .subscribe(userTeams_api => {
        this.userTeams = userTeams_api;
        if (this.user.invitedBy || userTeams_api.length > 0) {
          this.activatedRoute.queryParamMap.subscribe(params => {
            if (params.has('team')) {
              this.router.navigateByUrl('/team/' + userTeams_api[0].teamId);
            }
          });
        }
      });
  }

  ngAfterViewInit() {
    this.userService.getUserTeams(this.user.id)
      .subscribe(userTeams_api => {
        if (!this.user.invitedBy && userTeams_api.length < 1) {
          $('#walkModal2').modal('show');
        }
      });
  }

  closeWalkModal2() {
    setTimeout(() => {
      $('#walkModal2').modal('hide');
    }, 500);
  }
}
