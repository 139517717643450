<h1 mat-dialog-title>{{data.title}}</h1>
<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
    <mat-form-field>
      <mat-label>Role</mat-label>
      <mat-select formControlName="role">
        <mat-option
            *ngFor="let role of data.allowableRoles" [value]="role.code"
        >{{role.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Email</mat-label>
      <input type="email" matInput formControlName="email">
      <mat-error *ngIf="userForm.get('email').hasError('required')">Email is required</mat-error>
      <mat-error *ngIf="userForm.get('email').hasError('email')">Email is not valid</mat-error>
      <mat-error *ngIf="userForm.get('email').hasError('emailExists')">Email already exists</mat-error>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Display Name</mat-label>
      <input type="text" matInput formControlName="displayName">
      <mat-error *ngIf="userForm.get('displayName').hasError('required')">Display name is required</mat-error>
      <mat-error *ngIf="userForm.get('displayName').hasError('minlength')">Display name must have at least 2 characters</mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button color="primary" type="submit">Invite</button>
  </div>
</form>
