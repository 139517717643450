<mat-card>
  <form [formGroup]="userForm">
    <mat-card-title class="text-center" *ngIf="inStates('new')">Add a Team Member</mat-card-title>
    <mat-card-subtitle class="text-center" *ngIf="inStates('new')">
      You can add as many people as you like, for instance, family,
      friends and caregivers. They'll get an email to join.
    </mat-card-subtitle>
    <div class="text-center" *ngIf="canSkip">
      <mat-card-subtitle class="text-center" *ngIf="inStates('new')">
        You can skip this now and add them later from your dashboard.
      </mat-card-subtitle>
      <button mat-button color="secondary" type="button" (click)="onSkip()">Skip</button>
    </div>
    <div class="view-info-container" *ngIf="inStates('view', 'edit')">
      <div class="info-item-container">
        <div class="info-item">
          <div class="info-label">First Name</div>
          <div class="info-value">{{firstName}}</div>
        </div>
        <div class="info-item">
          <div class="info-label">Last Name</div>
          <div class="info-value">{{lastName}}</div>
        </div>
      </div>
      <div class="info-item-container">
        <div class="info-item">
          <div class="info-label">Email</div>
          <div class="info-value">{{email}}</div>
        </div>
        <div class="info-item">
          <div class="info-label">Display Name</div>
          <div class="info-value">{{displayName}}</div>
        </div>
      </div>
    </div>
    <div class="col-row-toggle" *ngIf="inStates('new')">
      <mat-form-field appearance="outline">
        <mat-label>Display Name</mat-label>
        <input type="text" matInput formControlName="displayName" [readonly]="inStates('view','edit')">
        <mat-hint>Usually first names or nicknames</mat-hint>
        <mat-error *ngIf="userForm.get('displayName').hasError('required')">Display name is required</mat-error>
        <mat-error *ngIf="userForm.get('displayName').hasError('minlength')">Display name must have at least 2 characters</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>Email</mat-label>
        <input type="email" matInput formControlName="email" [readonly]="inStates('view', 'edit')">
        <mat-error *ngIf="inStates('new') && userForm.get('email').hasError('required')">Email is required</mat-error>
        <mat-error *ngIf="inStates('new') && userForm.get('email').hasError('email')">Email is not valid</mat-error>
      </mat-form-field>
    </div>
    <div class="roles-row" *ngIf="isAdmin || inStates('new')">
      <mat-radio-group aria-label="Will they be an Administrator (full access) or a Contributor (restricted access)?" formControlName="role">
        <mat-hint *ngIf="inStates('edit','new')">Will they be an Administrator (full access) or a Contributor (restricted access)?</mat-hint>
        <mat-hint *ngIf="inStates('view')">Role</mat-hint>
        <br>
        <ul class="roles-list">
          <li>
            <mat-radio-button
              color="primary"
              [matTooltip]="administratorTooltip"
              [disabled]="isMemberCoach || (inStates('view') && isRoleNotSelected(teamConstants.ADMIN))"
              [value]="teamConstants.ADMIN">Administrator</mat-radio-button>
          </li>
          <li>
            <mat-radio-button
              color="primary"
              [matTooltip]="contributorTooltip"
              [disabled]="isMemberCoach || (inStates('view') && isRoleNotSelected(teamConstants.CONTRIBUTOR))"
              [value]="teamConstants.CONTRIBUTOR">Contributor</mat-radio-button>
          </li>
          <li>
            <mat-radio-button
              color="primary"
              *ngIf="inStates('view', 'edit') && isMemberCoach"
              [disabled]="inStates('view') && isRoleNotSelected(teamConstants.COACH)"
              [value]="teamConstants.COACH">Coach</mat-radio-button>
          </li>
          <li>
            <mat-error
              *ngIf="userForm.get('role').dirty && userForm.get('role').hasError('required')"
              style="font-size: 75%; font-weight: 400;"
              >Role is required</mat-error>
          </li>
        </ul>
      </mat-radio-group>
      <div class="pending-button-container">
        <button
          mat-button
          color="warning"
          *ngIf="isAdmin && inStates('view', 'edit') && pending"
          (click)="onResendAlertClicked()" >
          <mat-icon>warning</mat-icon>
          PENDING
        </button>
      </div>
    </div>
    <div class="permissions" formGroupName="permissions"
      *ngIf="inRoles(teamConstants.CONTRIBUTOR, teamConstants.COACH) && (isAdmin || inStates('new'))">
      <mat-hint class="text-center permissions-hint" *ngIf="inStates('edit','new')">
        What access would you like them to have? <br>
        You can change permissions at any time from your teams page.
      </mat-hint>
      <mat-hint class="text-center permissions-hint" *ngIf="inStates('view')">
        Permissions
      </mat-hint>

      <mat-grid-list cols="4" rowHeight="3:1">
        <mat-grid-tile ></mat-grid-tile>
        <mat-grid-tile >Full Access </mat-grid-tile>
        <mat-grid-tile >Read Only</mat-grid-tile>
        <mat-grid-tile >No Access</mat-grid-tile>

        <ng-container *ngFor="let permission of permissions; index as i">
          <mat-grid-tile class="permissions-label" [matTooltip]="i == 2 ? safeKeeperToolTip : null">{{ permission.label }}</mat-grid-tile>
          <mat-radio-group aria-label=""  [formControlName]="permission.key">
            <mat-grid-tile >
              <mat-radio-button
                color="primary"
                [disabled]="inStates('view') && isPermissionNotSelected(permission.key, teamConstants.TEAM_PERMISSION_EDIT_NUM)"
                [value]="teamConstants.TEAM_PERMISSION_EDIT_NUM"
                [matTooltip]="i == 2 ? safeKeeperToolTip : null"></mat-radio-button>
            </mat-grid-tile>
            <mat-grid-tile >
              <mat-radio-button
                color="primary"
                [disabled]="inStates('view') && isPermissionNotSelected(permission.key, teamConstants.TEAM_PERMISSION_READONLY_NUM)"
                [value]="teamConstants.TEAM_PERMISSION_READONLY_NUM"
                [matTooltip]="i == 2 ? safeKeeperToolTip : null"></mat-radio-button>
            </mat-grid-tile>
            <mat-grid-tile >
              <mat-radio-button
                color="primary"
                [disabled]="inStates('view') && isPermissionNotSelected(permission.key, teamConstants.TEAM_PERMISSION_NOACCESS_NUM)"
                [value]="teamConstants.TEAM_PERMISSION_NOACCESS_NUM"
                [matTooltip]="i == 2 ? safeKeeperToolTip : null"></mat-radio-button>
            </mat-grid-tile>
          </mat-radio-group>
        </ng-container>
      </mat-grid-list>
    </div>
    <mat-card-actions class="card-footer">
      <button mat-button color="secondary" type="button" (click)="OnCancelClicked()" *ngIf="cancelable">Cancel</button>
      <button mat-button color="secondary" type="button" (click)="OnCancelClicked()" *ngIf="!cancelable && inStates('view')">Close</button>
      <button mat-button color="primary" type="button" (click)="OnAddAnotherClicked()" *ngIf="inStates('new')">Add Another</button>
      <button mat-button color="primary" (click)="OnAllDoneClicked()" *ngIf="inStates('new')">All Done</button>
      <button mat-button color="secondary" (click)="OnDeleteClicked()" *ngIf="isAdmin && !isMemberOwner && inStates('edit')">Remove</button>
      <button mat-button color="warning" (click)="onResendAlertClicked()" *ngIf="isAdmin && inStates('view', 'edit') && pending">Resend Invitation</button>
      <button mat-button color="primary" (click)="OnSaveClicked()" *ngIf="inStates('edit')">Save</button>
    </mat-card-actions>
  </form>

  <div class="loading-layer" *ngIf="isLoading">
    <mat-spinner [diameter]="40"></mat-spinner>
  </div>
</mat-card>
