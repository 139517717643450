<div class="verification-modal-header">
  <h1 mat-dialog-title>Multi-factor Verification</h1>
  <button mat-icon-button title="Logout" (click)="logout()"><mat-icon>exit_to_app</mat-icon></button>
</div>
<div mat-dialog-content *ngIf="status !== 'loading'">
  <p *ngIf="status === 'existing-phone' || status === 'update-phone'">
    Due to recent security updates, you are required to verify your phone number to use SMS verification.
  </p>
  <br/>
  <p *ngIf="status === 'existing-phone'">
    Do you want to use your existing phone number <strong>{{data.phonePreview}}</strong>?
  </p>

  <div class="text-center" style="padding-top: 30px;" *ngIf="status === 'code-sent'">
    <p>Please enter the code sent to your phone <strong>{{phonePreview}}</strong>.</p>
    <code-input
        #codeInput
        [isCodeHidden]="false"
        [codeLength]="6"
        style="max-width: 300px; display: inline-flex;"
        (codeCompleted)="onCodeCompleted($event)">
    </code-input>
  </div>

  <div style="padding-top: 30px;">
    <p *ngIf="status === 'update-phone'">
      Please enter your phone number.
    </p>

    <form *ngIf="status === 'update-phone'" [formGroup]="phoneNumForm">
      <mat-form-field appearance="outline">
        <mat-label>Phone Number</mat-label>
          <ngx-mat-intl-tel-input
          [preferredCountries]="['us', 'ca']"
          [enablePlaceholder]="true"
          [enableSearch]="true"
          format="national"
          name="phoneNumber"
          formControlName="phoneNumber"
          #phoneNumber></ngx-mat-intl-tel-input>
          <mat-hint>e.g. {{phoneNumber.selectedCountry.placeHolder}}</mat-hint>
          <mat-error *ngIf="phoneNumForm.controls['phoneNumber']?.errors?.required">Required Field</mat-error>
          <mat-error *ngIf="phoneNumForm.controls['phoneNumber']?.errors?.validatePhoneNumber">Invalid Number</mat-error>
      </mat-form-field>
    </form>
  </div>

  <mat-error *ngIf="errMsg?.length">{{errMsg}}</mat-error>
</div>

<div mat-dialog-actions align="end" *ngIf="status !== 'loading'">
  <button mat-button color="secondary" (click)="onCancelClick()" *ngIf="showCancelButton">Cancel</button>
  <button mat-button color="secondary" (click)="onChangeNumClicked()" *ngIf="!data.phoneVerified && status !== 'update-phone'">Change phone number</button>
  <button mat-button color="primary" cdkFocusInitial *ngIf="status !== 'code-sent'" (click)="onSendCodeClick()">Send Code</button>
  <button mat-button color="primary" *ngIf="status === 'code-sent'" [disabled]="resendDisabled$ | async"  (click)="onResendCodeClick()">Resend Code {{counter$ | async}}</button>
</div>

<div mat-dialog-content *ngIf="status === 'loading'" class="spinner-container">
  <mat-spinner [diameter]="40"></mat-spinner>
</div>
