import { mergeMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';

// models
import { CoachSurveyModel } from 'models/coach.model';
import { timezones } from 'constants/timezones';

// providers
import { Utils } from 'providers/utils.service';
import { CoachService } from 'providers/coach.service';
import { Logger } from 'providers/logger.service';
import { TeamModel } from 'models/team.model';
import { UserService } from 'providers/user.service';
import { TeamService } from 'providers/team.service';

declare var $: any;

@Component({
  selector: 'app-survey',
  templateUrl: './survey.component.html',
  styleUrls: ['./survey.component.scss']
})
export class SurveyComponent implements OnInit {

  public survey: CoachSurveyModel = new CoachSurveyModel();
  public surveyExists = false;
  public formSent = false;
  private source = 'SurveyComponent';
  public teams: TeamModel[] = [];
  public USTimeZones = timezones;
  public disableButton = false;

  constructor(
    public utils: Utils,
    public coachService: CoachService,
    public userService: UserService,
    public teamService: TeamService,
    private logger: Logger,
  ) {}

  ngOnInit(): void {
    this.utils.pageTitleSource.next('Coaching');
    this.userService.getUserSelf()
      .pipe(
        mergeMap(userSelf => this.userService.getUserTeams(userSelf.id)),
        mergeMap(userTeams => this.teamService.getTeamsForUser(userTeams))
      )
      .subscribe(teams => this.teams = teams);
  }

  onSubmit(form): void {
    if (form.valid && !this.disableButton) {
      this.disableButton = true;
      this.coachService.sendSurvey(this.survey).subscribe(_ => {
        this.logger.logTrace(this.source, 'onSubmit', {}, 'coach-survey: Coach data saved');
        this.formSent = true;
        console.warn('submitted...');
      }, err => {
        this.handleApiError('onSubmit', err);
      });
    }
  }

  handleApiError(func: string, err: any) {
    if (err.status === 403) {
      this.showNotification('top', 'center', 'danger', true, null, null,
        'You do not have permissions to perform this action. Please contact your Team Administrator.');
    } else {
      this.showNotification('top', 'center', 'danger', true, null, null,
        'Server Error: ' + err.status + ' ' + err.statusText);
    }
  }

  showNotification(from: any, align: any, type: any, allow_dismiss: boolean, url: string, target: string, message: string) {
    let delay = 5000;
    let timer = 1000;
    if (!allow_dismiss) {
      delay = 0; timer = 0;
    }
    $.notify(
      { icon: 'notifications', message: message, url: url, target: target },
      { type: type, delay: delay, timer: timer, allow_dismiss: allow_dismiss, placement: { from: from, align: align } }
    );
  }

}
