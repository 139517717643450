import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CodeInputModule } from 'angular-code-input';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMatIntlTelInputModule } from "ngx-mat-intl-tel-input";

import { MaterialModule } from 'app/material.module';
import { VerificationDialogComponent } from './verification-dialog/verification-dialog.component';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    VerificationDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    CodeInputModule,
    NgxMaskModule,
    NgxMatIntlTelInputModule
  ]
})
export class VerificationModule { }
