import { GetFolderNotesModel } from './../models/getfoldernotes.model';
import { Injectable } from '@angular/core';
import {HttpClient, HttpResponse, HttpHeaders, HttpParams,} from '@angular/common/http';

// rxjs
import {of as observableOf, throwError as observableThrowError, Observable, Subject, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

// models
import { SafeKeeperItemModel, SafeKeeperAttachmentModel, SafeKeeperParticipant } from './../models/safekeeper.model';

// providers
import { environment } from './../environments/environment';
import { Utils } from './utils.service';
import { Logger } from './logger.service';

// REMOVE
import {
  SafeKeeperFolderModel,
  DeleteSafeKeeperFolderModel,
  SafeKeeperTabModel,
  SafeKeeperUserTabModel, UpdateTabPermissionModel
} from 'models/safekeeper-folder.model';
import {GetFoldersModel, GetUserTabsModel} from 'models/getfolders.model';
import {PaginatedSkNoteModelExtended, SkNoteModelExtended} from '../models/sk-note.model';


@Injectable()
export class SafeKeeperService {
  private source = 'SafeKeeperService';
  private apiBaseUrl = environment.apiBaseUrl;
  public safeKeeperFolderChanged = new Subject<SafeKeeperFolderModel>()

  constructor(
    private http: HttpClient,
    private utils: Utils,
    private logger: Logger
  ) { }

  createSafeKeeperItem(teamId: string, safekeeperItem: SafeKeeperItemModel): Observable<SafeKeeperItemModel> {
    const func = 'createSafeKeeperItem';
    const call = 'API POST /teams/:id/safekeeper';
    this.logger.logInfo(this.source, func, call);
    const body = safekeeperItem;
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/teams/' + teamId + '/safekeeper', body, options).pipe(
      map((res: HttpResponse<SafeKeeperItemModel>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText }, call);
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  getSafeKeeperItem(teamId: string, safekeeperItemId: string): Observable<SafeKeeperItemModel> {
    const func = 'getSafeKeeperItem';
    const call = 'API GET /teams/:id/safekeeper/:id';
    this.logger.logInfo(this.source, func, call);
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/teams/' + teamId + '/safekeeper/' + safekeeperItemId, options).pipe(
      map((res: HttpResponse<SafeKeeperItemModel>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, teamId, safekeeperItemId }, call);
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }), );
  }
  listTabAll = (teamId: string, count: number, start?: Date, search = ''): Observable<PaginatedSkNoteModelExtended> => {
    const call = 'API GET /teams/:id/all-tab?start=start&count=count';
    this.logger.logInfo(this.source, 'listTabAll', call);
    const startParam = start ? this.utils.setDateTimeQueryParam(start, 'MM/DD/YYYY HH:mm:ss') : null;
    const params = new HttpParams({
      fromObject: {
        start: startParam,
        count: count.toString(),
        search,
        source: 'web'
      }
    });
    const options = this.utils.getJsonHttpOptionsWithParams(null, params);
    return this.http.get(`${this.apiBaseUrl}/teams/${teamId}/all-tab`, options).pipe(
        map((res: HttpResponse<any>) => {
          this.logger.logDebug(this.source, 'listTabAll', { status: res.status, statusText: res.statusText, teamId }, call);
          return res.body;
        }),
        catchError((err: any) => {
          this.logger.logError(this.source, 'listTabAll', err, 'API Error');
          return throwError(err);
        }), );
  }

  updateSafeKeeperItem(teamId: string, safekeeperItem: SafeKeeperItemModel): Observable<Observable<number>> {
    const func = 'updateSafeKeeperItem';
    const call = 'API PUT /teams/:id/safekeeper/:id';
    this.logger.logInfo(this.source, func, call);
    const body = safekeeperItem;
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + '/teams/' + teamId + '/safekeeper/' + safekeeperItem.id, body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, teamId }, call);
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  deleteSafeKeeperItem(teamId: string, safekeeperItemId: string): Observable<Observable<number>> {
    const func = 'deleteSafeKeeperItem';
    const call = 'API DELETE /teams/:id/safekeeper/:id';
    this.logger.logInfo(this.source, func, call);
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(this.apiBaseUrl + '/teams/' + teamId + '/safekeeper/' + safekeeperItemId, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, teamId }, call);
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  getSafeKeeperItemAttachment(teamId: string, safekeeperItemId: string, safekeeperItemAttachment: SafeKeeperAttachmentModel): Observable<Blob> {
    const func = 'getSafeKeeperItemAttachment';
    const call = 'API GET /teams/:id/safekeeper/:id/attachments/:id';
    this.logger.logInfo(this.source, func, call);
    let options: {responseType: 'blob', observe: 'response'};
    options = {responseType: 'blob', observe: 'response'};
    return this.http.get(this.apiBaseUrl + '/teams/' + teamId + '/safekeeper/' + safekeeperItemId + '/attachments/' + safekeeperItemAttachment.id, options).pipe(
      map((res: HttpResponse<Blob>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText }, call);
        return new Blob([res.body], { type: safekeeperItemAttachment.mediaType});
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  deleteSafeKeeperItemAttachment(teamId: string, safekeeperItemId: string, safekeeperItemAttachment: SafeKeeperAttachmentModel): Observable<Observable<number>> {
    const func = 'deleteSafeKeeperItemAttachment';
    const call = 'API DELETE /teams/:id/safekeeper/:id/attachments/:id';
    this.logger.logInfo(this.source, func, call);
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(this.apiBaseUrl + '/teams/' + teamId + '/safekeeper/' + safekeeperItemId + '/attachments/' + safekeeperItemAttachment.id, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, teamId, safekeeperItemId }, call);
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  addSafeKeeperItemAttachment(teamId: string, safekeeperItemId: string, ownerId: string, attachementFile: any): Observable<SafeKeeperAttachmentModel> {
    const func = 'addSafeKeeperItemAttachment';
    const call = 'API POST /teams/:id/safekeeper/:id/attachments';
    const body = new FormData();
    body.append('ownerId', ownerId);
    body.append('file', attachementFile);
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/teams/' + teamId + '/safekeeper/' + safekeeperItemId + '/attachments', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, teamId, safekeeperItemId, ownerId }, call);
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'uploadAvatar', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  getSafeKeeperParticipants(teamId: string, safekeeperItemId: string): Observable<SafeKeeperParticipant[]> {
    const func = 'getSafeKeeperParticipants';
    const call = 'API GET /teams/:id/safekeeper/:id/participants';
    this.logger.logInfo(this.source, func, call);
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/teams/' + teamId + '/safekeeper/' + safekeeperItemId + '/participants', options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText }, call);
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  addUpdateSafeKeeperParticipant(teamId: string, safekeeperItemId: string, userId: string) {
    const func = 'addUpdateSafeKeeperParticipant';
    const call = 'API POST /teams/:id/safekeeper/:id/participants/:id';
    this.logger.logInfo(this.source, func, call);
    const options = this.utils.getJsonHttpOptions();

    return this.http.post(this.apiBaseUrl + '/teams/' + teamId + '/safekeeper/' + safekeeperItemId + '/participants/' + userId, {}, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText }, call);
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  removeSafeKeeperParticipant(teamId: string, safekeeperItemId: string, userId: string) {
    const func = 'removeSafeKeeperParticipant';
    const call = 'API DELETE /teams/:id/safekeeper/:id/participants/:id';
    this.logger.logInfo(this.source, func, call);
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(this.apiBaseUrl + '/teams/' + teamId + '/safekeeper/' + safekeeperItemId + '/participants/' + userId, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText }, call);
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }), );
  }

getFolders(teamId: string): Observable<SafeKeeperFolderModel[]> {
    const func = 'getFolderNotes';
    const url = '/teams/safekeeper/getfolders';
    const call = 'API POST ' + url;
    const body = new GetFoldersModel();
    body.limit = -1;
    body.offset = 0;
    body.teamid = teamId
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + url, body, options).pipe(
    map((res: HttpResponse<any>) => {
      this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText }, call);
      this.logger.logTrace(this.source, func, res, call);
      return res.body
    }),
    catchError((err: any) => {
      this.logger.logError(this.source, 'getFolders', err, 'API Error');
      return observableThrowError(err);
    }), );
}

  getTabs(teamId: string): Observable<SafeKeeperTabModel[]> {
    const func = 'getTabs';
    const url = '/teams/safekeeper/gettabs';
    const call = 'API POST ' + url;
    const body = new GetFoldersModel();
    body.limit = -1;
    body.offset = 0;
    body.teamid = teamId
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + url, body, options).pipe(
        map((res: HttpResponse<any>) => {
          this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText }, call);
          this.logger.logTrace(this.source, func, res, call);
          return res.body
        }),
        catchError((err: any) => {
          this.logger.logError(this.source, 'getFolders', err, 'API Error');
          return observableThrowError(err);
        }), );
  }

  GetTabUserPermissionsForTeam = (teamId: string, tId: any): Observable<SafeKeeperUserTabModel[]> => {
    const func = 'getUserTabsForTeam';
    const url = '/teams/safekeeper/GetTabUserPermissionsForTeam';
    const call = 'API POST ' + url;
    const body = new GetUserTabsModel();
    body.limit = -1;
    body.offset = 0;
    body.teamid = teamId;
    body.tabId = tId.tabId;
    body.orderBy = '';
    body.direction = '';
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + url, body, options).pipe(
        map((res: HttpResponse<any>) => {
          this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText }, call);
          this.logger.logTrace(this.source, func, res, call);
          return res.body
        }),
        catchError((err: any) => {
          this.logger.logError(this.source, 'getFolders', err, 'API Error');
          return observableThrowError(err);
        }), );
  }

  UpdateTabUserPermission = (model: SafeKeeperUserTabModel): Observable<number> => {
    const func = 'UpdateTabUserPermission';
    const url = '/teams/safekeeper/updatetabpermission';
    const call = 'API POST ' + url;
    const options = this.utils.getJsonHttpOptions();
    const body = new UpdateTabPermissionModel()
    body.tabId = model.tabId
    body.teamId = model.teamId
    body.userId = model.userId
    body.role = model.role
    return this.http.post(this.apiBaseUrl + url, body, options).pipe(
        map((res: HttpResponse<any>) => {
          this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText }, call);
          this.logger.logTrace(this.source, func, res, call);
          return res.body
        }),
        catchError((err: any) => {
          this.logger.logError(this.source, 'getFolders', err, 'API Error');
          return observableThrowError(err);
        }), );
  }
addEditFolder(folder: SafeKeeperFolderModel) {
  const func = 'addEditFolder';
  let url = '/teams/safekeeper/';
  url += folder.id != '' ? 'updatefolder' : 'createfolder'
  if (folder.id === '') {
    folder.id = folder.teamId
  }
  folder.isUserDefined = true;
  const call = 'API POST ' + url;
  const options = this.utils.getJsonHttpOptions();
  return this.http.post(this.apiBaseUrl + url, folder, options).pipe(
  map((res: HttpResponse<any>) => {
    this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText }, call);
    this.logger.logTrace(this.source, func, res, call);
  }),
  catchError((err: any) => {
    this.logger.logError(this.source, 'addEditFolder', err, 'API Error');
    return observableThrowError(err);
  }), );
}
  getFolderNotes(folderId: string, teamId: string, searchText: string): Observable<SafeKeeperItemModel[]> {
    const func = 'getFolderNotes';
    const url = '/teams/safekeeper/getfoldernotes';
    const call = 'API POST ' + url;
    const body = new GetFolderNotesModel();
    body.folderId = folderId;
    body.searchText = searchText;
    body.limit = -1;
    body.offset = 0;
    body.teamId = teamId;
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + url, body, options).pipe(
    map((res: HttpResponse<any>) => {
      this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText }, call);
      this.logger.logTrace(this.source, func, res, call);
      return res.body
    }),
    catchError((err: any) => {
      this.logger.logError(this.source, 'getFolderNotes', err, 'API Error');
      return observableThrowError(err);
    }), );
  }
  deleteFolder(folder: DeleteSafeKeeperFolderModel) {
  const func = 'deleteFolder';
  const url = '/teams/safekeeper/deletefolder/';
  const call = 'API POST ' + url;
  const options = this.utils.getJsonHttpOptions();
  return this.http.post(this.apiBaseUrl + url, folder, options).pipe(
  map((res: HttpResponse<any>) => {
    this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText }, call);
    this.logger.logTrace(this.source, func, res, call);
    return res.body;
  }),
  catchError((err: any) => {
    this.logger.logError(this.source, 'addEditFolder', err, 'API Error');
    return observableThrowError(err);
  }), );
  }

  createDefaultCards(id: string, endpoint = 'createDefaultCard'): Observable<boolean> {
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(environment.apiBaseUrl + '/teams/' + id + '/'+endpoint, id, options).pipe(
        map((res: HttpResponse<any>) => {
            return res.body;
        }),
        catchError((err: any) => {
            this.logger.logError('Default Cards', 'create', err, 'API Error');
            return observableThrowError(err);
        })
    );
  }

}
