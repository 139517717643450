export class UserSettings {
    public sendEmailNotificationsMarketing: boolean;

    public sendEmailNotificationsCalendar: boolean;

    public sendEmailNotificationsSafekeeper: boolean;

    public sendEmailNotificationsMedia: boolean;

    public sendEmailNotificationsMessages: boolean;

    public sendEmailNotificationsCoach: boolean;

    public sendPushNotificationsCalendar: boolean;

    public sendPushNotificationsSafekeeper: boolean;

    public sendPushNotificationsMedia: boolean;

    public sendPushNotificationsMessages: boolean;

    public sendPushNotificationsCoach: boolean;
}
