import { PaginatorModel } from './paginator.model';

// not implemented, not true to api

export class MessageModel {
  messageId: string;
  teamId: string;
  senderUserId: string;
  receiverUserId: string;
  recipientUserId: string;
  chatRoomId: string;
  body: string;
  attachments: Array<MessageAttachment>;
  isSystem = true;
  created: string;
  updated: Date;
  deleted: Date;
  // injected at UX
  senderName: string;
  senderAvatar: string;
  senderRole: number;
  receiverName: string;
  receiverAvatar: string;
  // specific to receiver user
  read: Date;
  delivered: Date;
  archived: Date;
  // injected at UI
  createdFromNow: string;
}

export class MessagesModel {
  new: Array<MessageModel> = [];
  read: Array<MessageModel> = [];
  archived: Array<MessageModel> = [];
  sent: Array<MessageModel> = [];
}

export class PaginatedMessagesModel {
  messages: MessageModel[];
  hasMore: boolean;
}

export class GetUserMessagesModel extends PaginatorModel {
  direction: 'asc'|'desc' = 'desc';
  orderBy = 'date';
  status: 'unread'|null;
  userId: string;
  senderId?: string;
  recipientId?: string;
  teamId?: string;
  isSystem?: boolean;
}

export class CoachMessage extends MessageModel {
  senderEmail: string;
  coachEmail: string;
  coachName: string;
  coachId: string;
  teamName: string;
  adminName: string;
  adminEmail: string;
  phone?: string;
  public client?: string;
  public originalInvitedByEmail?: string;
}

export class MessageAttachment {
  id: string;
  tempId: number;
  messageId: string;
  name: string;
  mediaType: string;
  created: Date;
  updated: Date;
  deleted: Date;
  uploaded: Date;
}
