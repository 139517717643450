import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NewTeamModalComponent } from 'app/team/new-team-modal/new-team-modal.component';

@Component({
  selector: 'app-forbid-create-team-component',
  templateUrl: './forbid-create-team-component.component.html',
  styleUrls: ['./forbid-create-team-component.component.scss']
})
export class ForbidCreateTeamComponentComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ForbidCreateTeamComponentComponent>,
  ) { }

  ngOnInit(): void {
  }

  handleClose(): void {
    this.dialogRef.close()
  }

}
