import { NotificationSettingsComponent } from './notificationsettings/notificationsettings.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MaterialModule } from 'app/material.module';
import { HeaderComponent } from './header/header.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { InviteRoleModalComponent } from './invite-role-modal/invite-role-modal.component';
import {DateMaskDirective} from './date-mask.directive';
import { AddTeamMemberCardComponent } from './add-team-member-card/add-team-member-card.component';
import { ConfirmationDialogExtendedComponent } from './confirmation-dialog-extended/confirmation-dialog-extended.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { PasswordValidatorDirective } from './password-validator.directive';
import { StretchMatErrorsDirective } from './stretch-mat-errors.directive';
import { SecondPasswordValidatorDirective } from './second-password-validator.directive';
import { MatchPasswordsDirective } from './user/match-passwords.directive';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    EditorModule,
  ],
    declarations: [
        NotificationSettingsComponent,
        HeaderComponent,
        ConfirmationDialogComponent,
        InviteRoleModalComponent,
        DateMaskDirective,
        AddTeamMemberCardComponent,
      ConfirmationDialogExtendedComponent,
      ContactUsComponent,
      PasswordValidatorDirective,
      StretchMatErrorsDirective,
      SecondPasswordValidatorDirective,
      MatchPasswordsDirective
    ],
    exports: [
        NotificationSettingsComponent,
        HeaderComponent,
        DateMaskDirective,
        AddTeamMemberCardComponent,
        PasswordValidatorDirective,
        StretchMatErrorsDirective,
        SecondPasswordValidatorDirective,
        MatchPasswordsDirective,
        EditorModule,
    ],
    providers: [
      { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
    ],

})


export class SharedModule { }
