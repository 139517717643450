<div class="container">
  <h1>Privacy Policy</h1>

  <p>
    Effective as of May 17, 2023, Bright Horizons has updated our Privacy Policy
    (“Policy”).
  </p>

  <h4>1. Introduction</h4>
  <p>
    This Policy details our commitment to protecting the privacy of individuals
    who visit our Website and mobile applications (“Users”), who register to use
    the products and services which we market for subscription, available at
    <a href="https://www.brighthorizons.com/"><u>www.BrightHorizons.com</u></a>
    (the “Service(s)”), or who attend or register to attend sponsored events or
    other events at which Bright Horizons participates (“Attendees”). For the
    purposes of this Policy, the term, “Websites”, shall refer collectively to
    <a href="https://www.brighthorizons.com/"><u>www.BrightHorizons.com</u></a>
    as well as the other websites that Bright Horizons operates and that link to
    this Policy.
  </p>

  <h4>2. Scope Of This Policy</h4>
  <p>
    In this Policy, personal information means information relating to an
    identified or identifiable natural person. An identifiable person is one who
    can be identified, directly or indirectly, in particular by reference to an
    identifier such as a name, an identification number, location data, and
    online identifier or to one or more factors specific to his/her physical,
    physiological, genetic, mental, economic, cultural or social identity. The
    use of information collected through our Service shall be limited to the
    purpose of providing the Service for which the Subscribers have engaged.
  </p>
  <p>
    Our Websites or mobile applications may contain links to other websites and
    the information practices and the content of such other websites are
    governed by the privacy statements of such other websites. We encourage you
    to review the privacy statements of any such other websites to understand
    their information practices.
  </p>
  <p>
    Subscribers to our Services are solely responsible for establishing policies
    for and ensuring compliance with all applicable laws and regulations, as
    well as any and all privacy policies, agreements or other obligations,
    relating to the collection of personal information in connection with the
    use of our Services by individuals (also referred to as “data subjects”)
    with whom our Subscribers interact.
  </p>
  <p>
    We may transfer personal information to companies that help us provide our
    Services. Transfers to subsequent third parties for these purposes are
    governed by the Service Agreements with our Subscribers.
  </p>

  <h4>3. Information That You Provide To Us</h4>
  <p>
    Account and Registration Information:<br />
    We ask for and may collect personal information about you such as your name,
    address, phone number, email address, physical address, date of birth and
    credit card information, as well as certain related information, when you
    register for an account to access or utilize one or more of our Services (an
    “Account”). We also ask for and collect personal information such as an
    email address and a name or alias from any individual that you authorize to
    log into and utilize our Services in connection with Your Account.
  </p>
  <p>
    If you sign-up for a free trial Account, you are not required to enter your
    credit card information unless and until you decide to continue with a paid
    subscription to our Services. A third-party intermediary is used to manage
    credit card processing. By contract, this intermediary is not permitted to
    store, retain, or use your billing information for any purpose except for
    credit card processing on our behalf.
  </p>
  <p>
    We refer to any information described above as “Account Information” for the
    purposes of this Policy. By voluntarily providing us with Account
    Information, you represent that you are the owner of such personal data or
    otherwise have the requisite consent to provide it to us.
  </p>
  <p>
    Other Submissions:<br />
    We ask for and may collect personal information from you when you submit web
    or mobile forms on our Websites or mobile applications or as you use
    interactive features of the Websites or mobile services, including,
    participation in surveys, contests, promotions, sweepstakes, requesting
    customer support, or otherwise communicating with us.
  </p>
  <p>
    Attendee Information:<br />
    We ask for and may collect personal information such as your name, address,
    phone number and email address when you register for or attend a sponsored
    event or other events at which any member of Bright Horizons participates.
  </p>
  <p>
    Mobile Application:<br />
    When you download and use our Services, we automatically collect information
    on the type of device you use, and operating system version.
  </p>

  <h4>
    4. Information That We Collect From You on our Websites or Mobile
    Applications
  </h4>
  <p>
    This Cookie Policy explains how Bright Horizons (“Company,” “we,” “us,” or
    “our”) uses cookies and similar technologies to recognize you when you visit
    our websites at
    <a href="https://www.brighthorizons.com/"><u>www.BrightHorizons.com</u></a>
    (“Websites”). It explains what these technologies are and why we use them,
    as well as your rights to control our use of them.
  </p>
  <p>
    In some cases we may use cookies to collect personal information, or that
    becomes personal information if we combine it with other information.
  </p>
  <p>
    We and our authorized partners may use cookies and other information
    gathering technologies for a variety of purposes. These technologies may
    provide us with personal information, information about devices and networks
    you utilize to access our Websites and mobile applications, and other
    information regarding your interactions with our Websites and mobile
    applications.
  </p>
  <p>
    We partner with third parties to either display advertising on the Websites
    or mobile applications or to manage our advertising on other sites. Our
    third party partners may also use technologies such as cookies to gather
    information about your activities on our Websites and mobile applications
    and other sites in order to suggest advertisements based upon your browsing
    activities and interests.
  </p>
  <p>
    Web beacons, tags and scripts may be used on our Websites and mobile
    applications or in email or other electronic communications we send to you.
    These assist us in delivering cookies, counting visits to our Websites and
    mobile applications, understanding usage and campaign effectiveness and
    determining whether an email has been opened and acted upon. We may receive
    reports based on the use of these technologies by our third-party service
    providers on an individual and aggregated basis.
  </p>
  <p>
    Analytics:<br />We collect analytics information when you use the Websites
    and mobile applications to help us improve them. We may also share anonymous
    data about your actions on our Websites and mobile applications with
    third-party service providers of analytics services.
  </p>
  <p>
    We use Local Storage Objects (“LSOs”) such as HTML5 to store content
    information and preferences. Various browsers may offer their own management
    tools for removing HTML5 LSOs. Third parties with whom we partner to provide
    certain features on our Websites and mobile applications or to display
    advertising based upon your Web and mobile browsing activity use LSOs such
    as HTML5 and Flash to collect and store information.
  </p>
  <p>
    Logs:<br />
    As is true with most websites and services delivered over the Internet, we
    gather certain information and store it in log files when you interact with
    our Websites, mobile applications and Services. This information may include
    internet protocol (IP) addresses as well as browser type, internet service
    provider, URLs of referring/exit pages, operating system, date/time stamp,
    information you search for, locale and language preferences, identification
    numbers associated with your devices, your mobile carrier, and system
    configuration information. Occasionally, we connect personal information to
    information gathered in our log files as necessary to improve our Websites
    and Services. In such a case, we would treat the combined information in
    accordance with this Policy.
  </p>
  <p>
    Analytics:<br />
    We collect analytics information when you use the Websites and mobile
    applications to help us improve them. We may also share anonymous data about
    your actions on our Websites and mobile applications with third-party
    service providers of analytics services.
  </p>
  <p>
    We also use mobile analytics software to allow us to better understand the
    functionality of our mobile application on your device. This software may
    record information such as how often you use the mobile application, the
    events that occur within the mobile application, aggregated usage,
    performance data, and where the mobile application was downloaded from. We
    do not link the information we store within the analytics software to any
    personally identifiable information you submit within the mobile
    application.
  </p>

  <h4>5. Information Collected From Other Sources</h4>
  <p>
    Social Media Widgets:<br />The Websites and mobile applications include
    social media features, such as the Facebook Like button, and widgets, such
    as the Share This button or interactive mini-programs that run on our
    Websites and mobile applications. These features may collect your Internet
    protocol address, which page you are visiting on the Websites and mobile
    applications and may set a cookie to enable the feature to function
    properly. Social media features and widgets are either hosted by a third
    party or hosted directly on the Websites. Your interactions with these
    features are governed by the privacy statement of the companies that provide
    them.
  </p>
  <p>
    Information From Third Party Services:<br />We may also obtain other
    information, including personal information, from third parties and combine
    that with information we collect through our Websites and mobile
    applications. For example, we may have access to certain information from a
    third party social media or authentication service if you log into our
    Services through such a service or otherwise provide us with access to
    information from the service. Any access that we may have to such
    information from a third party social media or authentication service is in
    accordance with the authorization procedures determined by that service. By
    authorizing us to connect with a third party service, you authorize us to
    access and store your name, email address(es), current city, profile picture
    URL, and other personal information that the third party service makes
    available to us, and to use and disclose it in accordance with this Policy.
    You should check your privacy settings on these third party services to
    understand and change the information sent to us through these services.
  </p>

  <h4>6. How We Use Information That We Collect</h4>
  <p>
    General Uses:<br />We may use the information we collect about you
    (including personal information, to the extent applicable) for a variety of
    purposes, including to (a) provide, operate, maintain, improve, and promote
    the Services; (b) enable you to access and use the Services; (c) process and
    complete transactions, and send you related information, including purchase
    confirmations and invoices; (d) send transactional messages, including
    responses to your comments, questions, and requests; provide customer
    service and support; and send you technical notices, updates, security
    alerts, and support and administrative messages; (e) send promotional
    communications, such as providing you with information about products and
    services, features, surveys, newsletters, offers, promotions, contests, and
    events; and provide other news or information about us and our partners. (f)
    process and deliver contest or sweepstakes entries and rewards; (g) monitor
    and analyze trends, usage, and activities in connection with the Websites
    and Services and for marketing or advertising purposes; (h) investigate and
    prevent fraudulent transactions, unauthorized access to the Services, and
    other illegal activities; (i) personalize the Websites and Services,
    including by providing features or advertisements that match your interests
    and preferences; and (j) for other purposes for which we obtain your
    consent.
  </p>
  <p>
    In some cases, we may also have a legal obligation to collect personal
    information from you, or may otherwise need the personal information to
    protect your vital interests or those of another person. Where we rely on
    your consent to process the personal information, you have the right to
    withdraw or decline your consent at any time. Please note that this does not
    affect the lawfulness of the processing based on consent before its
    withdrawal.
  </p>
  <p>
    If we ask you to provide personal information to comply with a legal
    requirement or to perform a contract with you, we will make this clear at
    the relevant time and advise you whether the provision of your personal
    information is mandatory or not (as well as of the possible consequences if
    you do not provide your personal information). Similarly, if we collect and
    use your personal information in reliance on our (or a third party’s)
    legitimate interests which are not already described in this Notice, we will
    make clear to you at the relevant time what those legitimate interests are.
  </p>
  <p>
    If you have any questions about or need further information concerning the
    legal basis on which we collect and use your personal information, please
    contact us at
    <a href="mailto:support@brighthorizons.com"><u>www.BrightHorizons.com</u></a
    >.
  </p>

  <h4>7. Sharing Of Information Collected</h4>
  <p>
    Third-Party Service Providers:<br />We share information, including personal
    information, with our third-party service providers that we use to provide
    hosting for and maintenance of our Websites and mobile applications,
    application development, backup, storage, payment processing, analytics and
    other services for us. These third-party service providers may have access
    to or process your personal information for the purpose of providing these
    services for us. By contract, we do not permit our third-party service
    providers to use the personal information that we share with them for their
    marketing purposes or for any other purpose than in connection with the
    services they provide to us.
  </p>
  <p>
    Compliance with Laws and Law Enforcement Requests; Protection of Our
    Rights:<br />In certain situations, we may be required to disclose personal
    information in response to lawful requests by public authorities, including
    to meet national security or law enforcement requirements. We may disclose
    personal information to respond to subpoenas, court orders, or legal
    process, or to establish or exercise our legal rights or defend against
    legal claims. We may also share such information if we believe it is
    necessary in order to investigate, prevent, or take action regarding illegal
    activities, suspected fraud, situations involving potential threats to the
    physical safety of any person, violations of our Service Agreement, or as
    otherwise required by law.
  </p>
  <p>
    Testimonials:<br />From time to time, we may post testimonials on the
    Websites and mobile applications that may contain personal information. We
    obtain your consent to post your name along with your testimonial.
  </p>
  <p>
    International Users:<br />If you are visiting our Websites from the EEA or
    other regions with laws governing data collection and use, please note that
    by providing this personal information, you acknowledge that your personal
    information may be transferred to the United States and other jurisdictions
    in which we operate. These countries may have data protection laws that are
    different to the laws of your country and, in some cases, may not be as
    protective.
  </p>
  <p>
    We use your personal information only as permitted by law. Applicable EEA
    and UK and Canada data protection law requires us to have a “legal basis”
    for each purpose for which we collect your personal information. Our legal
    basis for collecting and using the personal information described in this
    Privacy Statement will depend on the type of personal information and the
    specific context in which we collect it. However, we will normally process
    personal information from you when:
  </p>
  <ul>
    <li>We have your consent to do so;</li>
    <li>
      We have a contract with you and it is necessary to process your personal
      information to perform our contract with you, including to provide you
      with the benefits of the Bright Horizons, Inc. Platform and operate our
      business;
    </li>
    <li>
      The processing is in our legitimate business interests, such as operating
      our businesses, improving and developing the Bright Horizons, Inc.
      Platform, communicating with you, marketing our offerings and services and
      personalizing your experience, and to detect illegal activities; and/or
    </li>
    <li>
      To comply with legal requirements, including applicable laws and
      regulations.
    </li>
  </ul>

  <h4>8. How Long We Retain Your Personal Information:</h4>
  <p>
    We will retain your personal information for as long as is needed to fulfill
    the purposes outlined in this Privacy Policy, unless a longer retention
    period is required or permitted by law (such as tax, accounting or other
    legal requirements). When we have no ongoing legitimate business need to
    process your personal information, we will either delete or anonymize it,
    or, if this is not possible (for example, because your personal information
    has been stored in backup archives), then we will securely store your
    personal information and isolate it from any further processing until
    deletion is possible.
  </p>
  <p>
    For personal information that we process on behalf of our Subscribers, we
    will retain such personal information in accordance with the terms of our
    agreement with them, subject to applicable law.
  </p>

  <h4>9. Your Privacy Rights</h4>
  <p>
    Upon request we will provide you with information about whether we hold, or
    process on behalf of a third party, any of your personal information. To
    request this information please contact us at
    <a href="mailto:support@brighthorizons.com"><u>www.BrightHorizons.com</u></a
    >. Subscribers to our Services may update or change their Account
    Information by editing their profile or organization record or by contacting
    <a href="mailto:support@brighthorizons.com"
      ><u>www.BrightHorizons.com</u></a
    >
    for more detailed instructions. To make a request to have personal
    information maintained by us returned to you or removed, please email
    <a href="mailto:support@brighthorizons.com"><u>www.BrightHorizons.com</u></a
    >. Requests to access, change, or remove your information will be handled
    within thirty (30) days.
  </p>
  <p>
    An individual who seeks access to, or who seeks to correct or, amend
    inaccuracies in, or delete personal information stored or processed by us on
    behalf of a Subscriber should direct his/her query to the Subscriber (the
    data controller). Upon receipt of a request from one of our Subscribers for
    us to remove the data, we will respond to their request within thirty (30)
    days. We will retain personal information that we store and process on
    behalf of our Subscribers for as long as needed to provide the Services to
    our Subscribers. We will retain and use this personal information as
    necessary to comply with our legal obligations, resolve disputes, and
    enforce our agreements.
  </p>
  <p>
    The security of your personal information is important to us. We will use
    commercially reasonable methods to protect the personal information
    submitted to us, both during transmission and once it is received.
  </p>
  <p>
    If you are a Subscriber or otherwise provide us with personal information in
    connection with your use of our Websites or Services, we will delete this
    information upon your request within 90 days, provided that, notwithstanding
    such request, this information may be retained for as long as you maintain
    an account for our Services, or as needed to provide you with our Services,
    comply with our legal obligations, resolve disputes and enforce our
    agreements.
  </p>

  <h4>10. Privacy Rights Under Certain State Privacy Laws</h4>
  <p>
    If you are a resident of California, Colorado, Connecticut, Utah or
    Virginia, you may have certain privacy rights under the applicable privacy
    laws in your state (“State Privacy Laws”). This section describes those
    rights and how you can exercise them with Bright Horizons, Inc.
  </p>
  <ul>
    <li>Rights Requests</li>
    <li>Right to Know, Right to Deletion and Right to Correct</li>
  </ul>
  <p>
    You can request what personal information we have collected, used,
    disclosed, and sold.
  </p>
  <p>
    You can also request that we delete your personal information. We may not
    delete all of your personal information if one of the following exceptions
    applies:
  </p>
  <ol>
    <li>
      Transactional: to complete a transaction for which the personal
      information was collected, provide a good or service requested by you, or
      perform a contract we have with you;
    </li>
    <li>
      Security: to detect data security incidents; preserve the integrity or
      security of systems; or investigate, report, or prosecute those
      responsible for any such action;
    </li>
    <li>Error Correction: to debug or repair any errors;</li>
    <li>
      Legal: to protect against fraud, harassment, malicious or deceptive
      activities, or illegal activity or to comply with applicable law or a
      legal obligation, or exercise rights under the law, such as the right to
      free speech; to assist another party with any of its obligations under
      applicable privacy laws; or
    </li>
    <li>
      Internal Use: to use your personal information, internally, in a lawful
      manner that is compatible with the context in which you provided the
      information (i.e. to improve our services).
    </li>
  </ol>

  <h4>11. Google account and user data</h4>
  <p>Accessing user data:</p>
  <p>
    This package accesses Google resources from your local machine. Your machine
    communicates directly with the Google APIs.<br />
    The Google calendar package project never receives your data or the
    permission to access your data. The owners of the project can only see
    anonymous, aggregated information about usage of tokens obtained through its
    OAuth client, such as which APIs and endpoints are being used.
  </p>
  <p>
    Each package includes functions that you can execute in order to read or
    modify your own data. This can only happen after you provide a token, which
    requires that you authenticate yourself as a specific Google user and
    authorize these actions.
  </p>
  <p>
    This package can help you get a token by guiding you through the OAuth flow
    in the browser. There you must consent to allow the Google calendar package
    to operate on your behalf. The OAuth consent screen will describe the scope
    of what is being authorized, e.g., it will name the target API(s) and
    whether you are authorizing “read only” or “read and write” access.
  </p>
  <p>
    There are two ways to use this package without authorizing the Google
    calendar package: bring your own
    <a
      href="https://developers.google.com/identity/protocols/OAuth2ServiceAccount"
    >
      <u>service account token</u>
    </a>
    or configure the package to use an OAuth client of your choice.
  </p>
  <p>Scopes:</p>
  <p>
    At the moment the Google calendar package only provides read-only access to
    the API. Here is an overview of the scopes requested by the Google calendar
    package and their rationale:
  </p>
  <table width="100%" style="margin: 0 12px 12px">
    <thead>
      <tr>
        <th>Scope</th>
        <th>Purpose</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>calendar.readonly</td>
        <td>read-only access to Calendars</td>
      </tr>
      <tr>
        <td>calendar.events</td>
        <td>read-only access to Events</td>
      </tr>
      <tr>
        <td>calendar.events.readonly</td>
        <td>read-only access to Events</td>
      </tr>
      <tr>
        <td>calendar.settings.readonly</td>
        <td>read-only access to Settings</td>
      </tr>
    </tbody>
  </table>
  <p>
    Sharing user data: <br />
    The packages only communicate with Google APIs. No user data is shared with
    the owners of the Google calendar package, RStudio, or any other servers.
  </p>
  <p>
    Storing user data: <br />
    This package may store your credentials on your local machine, for later
    reuse by you. Use caution when using this package on a shared machine.
  </p>
  <p>
    Collection and Use:
    <br />
    We collect the following personal information from your Google Calendar,
    event name, event date and event time.<br />
    We add/import the following personal information from your Userlytics
    account to your Google Calendar account: Slot/session name, event date,
    event time, session links (to connect) and session status. <br />
    We use this information to:<br />
    Synchronize your Google Calendar data in your Userlytics Dashboard (inside
    the study builder) to see your availability when creating a new slot and to
    create bookings from the Userlytics calendar to your GCAL account so you are
    aware of upcoming sessions. We will only use this information for the
    specific reason for which it was provided to us.
  </p>
  <p>
    Disclosure to Third Parties: <br />
    We do not share or sell your personal information to third parties. We
    disclose information only in the following cases:
  </p>
  <ul>
    <li>
      As required by law, such as to comply with a subpoena or similar legal
      process Session Status
    </li>
    <li>
      When we believe in good faith that disclosure is necessary to protect our
      rights, protect your safety or the safety of others, to investigate fraud,
      or to respond to a government request if we are involved in a merger
    </li>
    <li>Acquisition, or sale of all or a portion of our assets</li>
  </ul>
  <p>
    Security:<br />
    The security of your Google Calendar personal information is important to
    us.<br />
    We take commercially reasonable measures and follow generally accepted
    standards to protect the information you provide us, both during
    transmission and once we receive it. For example, the information you
    provide is transmitted via encryption using secure socket layer technology
    (SSL) technologies.<br />
    No method of transmission over the Internet, or method of electronic
    storage, is 100% secure. Therefore, we cannot guarantee that malicious
    actors will not access the data, although we will do our best to prevent
    that from happening.
  </p>
  <p>
    Accessing Your Information:<br />
    To review, correct, or update your customers’ information, you can request
    any modification by emailing dpo@userlytics.com. We will retain the
    synced/imported information for as long as your account is active or as
    needed to provide you with our services.
  </p>
  <p>
    If you wish to request that we no longer use your Google Calendar
    information you can simply disable the app on your Userlytics Profile Page.
    If you have any concerns or further questions, please feel free to contact
    dpo@userlytics.com
  </p>

  <h4>12. Google Play</h4>
  <p>Information We Collect and How We Collect It:</p>
  <p>
    We may collect information from a target age group of 35-65 that identifies,
    relates to, describes, references, is capable of being associated with, or
    could reasonably be linked, directly or indirectly, with a particular
    consumer or device ("personal information"). Personal information does not
    include publicly available information from government records, deidentified
    or aggregated consumer information, or information excluded from the CPRA's
    scope.
  </p>
  <p>We collect this information:</p>
  <ul>
    <li>Directly from you when you provide it to us.</li>
    <li>
      Automatically as you navigate through the App. Information collected
      automatically may include usage details, IP addresses, and information
      collected through cookies, web beacons, and other tracking technologies.
    </li>
    <li>From third parties, for example, our business partners.</li>
  </ul>
  <p>Information You Provide to Us:</p>
  <ul>
    <li>Your name and email address.</li>
    <li>
      Usage data and diagnostic information when you choose to share it with us.
    </li>
  </ul>
  <p>Automatically Collected Information:</p>
  <ul>
    <li>
      Usage and performance data about your interaction with the App, including
      but not limited to app launch time, duration of use, features accessed,
      crashes, and exceptions.
    </li>
    <li>
      Device data such as device type, operating system version, and device
      settings.
    </li>
    <li>
      Our app collects and uses data but we do not share user data nor does our
      app contain or use ads.
    </li>
  </ul>
  <p>How We Use Your Information:</p>
  <p>
    We use information that we collect about you or that you provide to us,
    including any personal information, to:
  </p>
  <ul>
    <li>
      Provide you with the App and its contents, and any other information,
      products, or services that you request from us.
    </li>
    <li>Fulfill any other purpose for which you provide it.</li>
    <li>
      Carry out our obligations and enforce our rights arising from any
      contracts entered into between you and us, including for billing and
      collection.
    </li>
    <li>Notify you when App updates are available.</li>
    <li>
      To personalize your experience and to allow us to deliver the type of
      content and product offerings in which you are most interested.
    </li>
    <li>To improve our App and its contents to better serve you.</li>
    <li>To provide you with notices about your account and/or subscription.</li>
    <li>To respond to your customer service requests.</li>
    <li>To troubleshoot and diagnose problems with the App.</li>
    <li>To prevent fraud and protect the security of the App.</li>
    <li>
      To comply with legal obligations, resolve disputes, and enforce our
      agreements.
    </li>
  </ul>
  <p>Disclosure of Your Information:</p>
  <p>
    We may disclose aggregated and deidentified information about our users
    without restriction.
  </p>
  <p>
    We may disclose personal information that we collect or you provide as
    described in this privacy policy:
  </p>
  <ul>
    <li>To our subsidiaries and affiliates.</li>
    <li>
      To contractors, service providers, and other third parties we use to
      support our business and who are bound by contractual obligations to keep
      personal information confidential and use it only for the purposes for
      which we disclose it to them.
    </li>
    <li>
      To a buyer or other successor in the event of a merger, divestiture,
      restructuring, reorganization, dissolution, or other sale or transfer of
      some or all of our assets, whether as a going concern or as part of
      bankruptcy, liquidation, or similar proceeding, in which personal
      information held by us about our users is among the assets transferred.
    </li>
    <li>
      To fulfill the purpose for which you provide it. For example, if you give
      us an email address to use the "email a friend" feature of our App, we
      will transmit the contents of that email and your email address to the
      recipients.
    </li>
    <li>
      For any other purpose disclosed by us when you provide the information.
    </li>
    <li>With your consent.</li>
  </ul>

  <h4>13. Children’s Personal Information</h4>
  <p>
    We do not knowingly collect any personal information from children under the
    age of 13. If you are under the age of 13, please do not submit any personal
    information through our Websites or Services. We encourage parents and legal
    guardians to monitor their children’s Internet usage and to help enforce
    this Policy by instructing their children never to provide personal
    information through the Websites or Services without their permission. If
    you have reason to believe that a child under the age of 13 has provided
    personal information to us through the Websites or Services, please contact
    us at
    <a href="mailto:support@brighthorizons.com">
      <u>www.BrightHorizons.com</u>
    </a>
    and we will use commercially reasonable efforts to delete that information.
  </p>

  <h4>14. Business Transactions</h4>
  <p>
    We may assign or transfer this Policy, as well as your account and related
    information and data, including any personal information, to any person or
    entity that acquires all or substantially all of our business, stock or
    assets, or with whom we merge.
  </p>

  <h4>15. Changes To This Policy</h4>
  <p>
    If there are any material changes to this Policy, you will be notified by
    our posting of a notice on the Websites or mobile applications prior to the
    change becoming effective. We encourage you to periodically review this page
    for the latest information on our privacy practices. Your continued use of
    the Websites or the Services constitutes your agreement to be bound by such
    changes to this Policy. Your only remedy, if you do not accept the terms of
    this Policy, is to discontinue use of the Websites and the Services.
  </p>

  <h4>16. Contact Us</h4>
  <p>
    If you have questions regarding this Policy or about Bright Horizons’s
    privacy practices, please contact us by email at
    <a href="mailto:support@elivelihood.com"><u>support@elivelihood.com</u></a
    >, or at:
  </p>
  <p>
    eLivelihood Inc. <br />Attn: Legal Department <br />1941 Eliot St.
    <br />Denver, CO 80204
  </p>
</div>
