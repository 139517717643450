export class DeviceData {
  platform: string;
  platformVersion: string;
  manufacturer: string;
  model: string;
  type = 'web';
  device: string;
  uuid: string;
  serialNumber: string;
  cordovaVersion: string;
  pushNotificationToken: string;

  constructor(
    platform?: string,
    platformVersion?: string,
    manufacturer?: string,
    model?: string,
    type?: string,
    device?: string,
    uuid?: string,
    serialNumber?: string,
    cordovaVersion?: string,
    pushNotificationToken?: string
  ) {
    if (platform) {
      this.platform = platform;
    }
    if (platformVersion) {
      this.platformVersion = platformVersion;
    }
    if (manufacturer) {
      this.manufacturer = manufacturer;
    }
    if (model) {
      this.model = model;
    }
    if (type) {
      this.type = type;
    }
    if (device) {
      this.device = device;
    }
    if (uuid) {
      this.uuid = uuid;
    }
    if (serialNumber) {
      this.serialNumber = serialNumber;
    }
    if (cordovaVersion) {
      this.cordovaVersion = cordovaVersion;
    }
    if (pushNotificationToken) {
      this.pushNotificationToken = pushNotificationToken;
    }
  }
}
