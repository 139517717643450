import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ApiResponseModel } from 'models/api-response.model';
import { ClientModalForm, ClientModel, Extension } from 'models/client.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Logger } from './logger.service';
import { Utils } from './utils.service';
import { ISelectData } from '../models/select-model';
import { AvailablePoliciesModel } from '../models/available-policies.model';
import { TeamPolicyRequestModel } from '../models/team-policy-request.model';
import { TwoFactorsModel } from '../models/two-factors.model';
import { PasswordComplexityModel } from '../models/password-complexity.model';
import { PasswordRotationModel } from '../models/password-rotation.model';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {AbstractControl, AsyncValidatorFn, ValidationErrors} from '@angular/forms';
import {of} from 'rxjs/internal/observable/of';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  private source = 'ClientsService';
  private apiBaseUrl = environment.apiBaseUrl;

  constructor(private http: HttpClient, private utils: Utils, private logger: Logger) { }

  getClient = (id: string): Observable<ClientModalForm> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(`${this.apiBaseUrl}/clients/${id}`,options).pipe(
      map((res: HttpResponse<ClientModalForm>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'getClient', err, 'API Error');
        return throwError(err);
      }), );
  }

  getClients = () : Observable<ClientModel[]> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl+ '/clients',options).pipe(
      map((res: HttpResponse<ClientModel[]>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'getClients', err, 'API Error');
        return throwError(err);
      }), );
  }

  getClientsSelectData = () : Observable<ISelectData[]> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl+'/clients/get/select-model',options).pipe(
      map((res: HttpResponse<ISelectData[]>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin, sys-manager', 'getClientsSelectData', err, 'API Error');
        return throwError(err);
      }), );
  }

  createClient = (model: ClientModalForm): Observable<string> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/clients', model, options).pipe(
      map((res: HttpResponse<string>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'createClient', err, 'API Error');
        return throwError(err);
      }), );
  }

  updateClient = (model: ClientModalForm): Observable<boolean> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + `/clients/${model.id}`, model, options).pipe(
      map((res: HttpResponse<boolean>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'updateClient', err, 'API Error');
        return throwError(err);
      }), );
  }

  deleteClient = (id: string): Observable<ApiResponseModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(`${this.apiBaseUrl}/clients/${id}`,options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'getClients', err, 'API Error');
        return throwError(err);
      }), );
  }

  createExtension = (model: Extension) :Observable<ApiResponseModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(`${this.apiBaseUrl}/clients/extensions`, model, options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'getClients', err, 'API Error');
        return throwError(err);
      }), );
  }

updateExtension = (model: Extension) :Observable<ApiResponseModel> => {
  const options = this.utils.getJsonHttpOptions();
  return this.http.put(`${this.apiBaseUrl}/clients/extensions/${model.id}`, model, options).pipe(
    map((res: HttpResponse<ApiResponseModel>) => {
      return (res.body);
    }),
    catchError((err: any) => {
      this.logger.logError('admin', 'getClients', err, 'API Error');
      return throwError(err);
    }), );
}

  getExtensions = (clientId: string) : Observable<Extension[]> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(`${this.apiBaseUrl}/clients/${clientId}/extensions`, options).pipe(
      map((res: HttpResponse<Extension[]>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'getClients', err, 'API Error');
        return throwError(err);
      }), );
  }

  deleteExtension = (id: string): Observable<ApiResponseModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(`${this.apiBaseUrl}/clients/extensions/${id}`,options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'getClients', err, 'API Error');
        return throwError(err);
      }), );
  }

  createTwoFactor = (model: TwoFactorsModel): Observable<ApiResponseModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(`${this.apiBaseUrl}/authentication-policies/two-factor/create`,model,options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'createTwoFactor', err, 'API Error');
        return throwError(err);
      }), );
  }

  updateTwoFactor = (model: TwoFactorsModel): Observable<ApiResponseModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(`${this.apiBaseUrl}/authentication-policies/two-factor/update`,model,options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'updateTwoFactor', err, 'API Error');
        return throwError(err);
      }), );
  }

  deleteTwoFactor = (id: string) : Observable<ApiResponseModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(`${this.apiBaseUrl}/authentication-policies/two-factor/delete/${id}`,options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'deleteTwoFactor', err, 'API Error');
        return throwError(err);
      }), );
  }

  addTwoFactorToTeam = (model: TeamPolicyRequestModel): Observable<ApiResponseModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(`${this.apiBaseUrl}/authentication-policies/two-factor/add-to-team`,model,options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'addTwoFactorToTeam', err, 'API Error');
        return throwError(err);
      }), );
  }

  removeTwoFactorFromTeam = (model: TeamPolicyRequestModel): Observable<ApiResponseModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(`${this.apiBaseUrl}/authentication-policies/two-factor/remove-from-team`,model,options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'removeTwoFactorFromTeam', err, 'API Error');
        return throwError(err);
      }), );
  }

  createPasswordRotation = (model: PasswordRotationModel): Observable<ApiResponseModel> =>{
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(`${this.apiBaseUrl}/authentication-policies/password-rotation/create`,model,options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'createPasswordRotation', err, 'API Error');
        return throwError(err);
      }), );
  }

  updatePasswordRotation = (model: PasswordRotationModel): Observable<ApiResponseModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(`${this.apiBaseUrl}/authentication-policies/password-rotation/update`,model,options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'updatePasswordRotation', err, 'API Error');
        return throwError(err);
      }), );
  }

  deletePasswordRotation = (id: string) : Observable<ApiResponseModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(`${this.apiBaseUrl}/authentication-policies/password-rotation/delete/${id}`,options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'deletePasswordRotation', err, 'API Error');
        return throwError(err);
      }), );
  }

  addPasswordRotationToTeam = (model: TeamPolicyRequestModel): Observable<ApiResponseModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(`${this.apiBaseUrl}/authentication-policies/password-rotation/add-to-team`,model,options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'addPasswordRotationToTeam', err, 'API Error');
        return throwError(err);
      }), );
  }

  removePasswordRotationFromTeam = (model: TeamPolicyRequestModel): Observable<ApiResponseModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(`${this.apiBaseUrl}/authentication-policies/password-rotation/remove-from-team`,model,options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'removePasswordRotationFromTeam', err, 'API Error');
        return throwError(err);
      }), );
  }

  createPasswordComplexity = (model: PasswordComplexityModel): Observable<ApiResponseModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(`${this.apiBaseUrl}/authentication-policies/password-complexity/create`,model,options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'createPasswordComplexity', err, 'API Error');
        return throwError(err);
      }), );
  }

  updatePasswordComplexity = (model: PasswordComplexityModel): Observable<ApiResponseModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(`${this.apiBaseUrl}/authentication-policies/password-complexity/update`,model,options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'updatePasswordComplexity', err, 'API Error');
        return throwError(err);
      }), );
  }

  deletePasswordComplexity = (id: string) : Observable<ApiResponseModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(`${this.apiBaseUrl}/authentication-policies/password-complexity/delete/${id}`,options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'deletePasswordComplexity', err, 'API Error');
        return throwError(err);
      }), );
  }

  addPasswordComplexityToTeam = (model: TeamPolicyRequestModel): Observable<ApiResponseModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(`${this.apiBaseUrl}/authentication-policies/password-complexity/add-to-team`,model,options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'addPasswordComplexityToTeam', err, 'API Error');
        return throwError(err);
      }), );
  }

  removePasswordComplexityFromTeam = (model: TeamPolicyRequestModel): Observable<ApiResponseModel> =>{
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(`${this.apiBaseUrl}/authentication-policies/password-complexity/remove-from-team`,model,options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'removePasswordComplexityFromTeam', err, 'API Error');
        return throwError(err);
      }), );
  }

  getClientPolicies = (clientId: string):Observable<AvailablePoliciesModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(`${this.apiBaseUrl}/authentication-policies/get-client-policies/${clientId}`, options).pipe(
      map((res: HttpResponse<AvailablePoliciesModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'getClientPolicies', err, 'API Error');
        return throwError(err);
      }), );
  }

  getTeamPolicies = (teamId: string): Observable<AvailablePoliciesModel> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(`${this.apiBaseUrl}/authentication-policies/get-client-policies/${teamId}`, options).pipe(
      map((res: HttpResponse<AvailablePoliciesModel>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin', 'getTeamPolicies', err, 'API Error');
        return throwError(err);
      }), );
  }

  wpoIdExists = (wpoId: number): Observable<boolean> => {
    this.logger.logInfo(this.source, 'wpoIdExists', 'API GET /clients/{wpoId}/exists');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(`${this.apiBaseUrl}/clients/${wpoId}/exists`, options).pipe(
      map((res: HttpResponse<boolean>) => {
        this.logger.logDebug(this.source, 'wpoIdExists', { status: res.status, statusText: res.statusText }, 'API GET /clients/{wpoId}/exists');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'wpoIdExists', err, 'API GET /clients/{wpoId}/exists');
        return observableThrowError(err);
      }),);
  }

  wpoIdExistsValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.wpoIdExists(control.value).pipe(
        map(value => {
          return value ? null : { wpoIdExists: true };
        }),
        catchError(err => {
          return of(err.status === 404 ? null : { emailExists: true });
        })
      );
    };
  }
}
