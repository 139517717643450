
import { Component, Input } from '@angular/core';

// models
import { UserModel } from './../../models/user.model';

// providers
import { Utils } from './../../providers/utils.service';
import { LocalData } from './../../providers/local-data.service';
import { Logger } from './../../providers/logger.service';

declare const $: any;

@Component({
  selector: 'app-team-help-modal',
  templateUrl: 'help.modal.html'
})

export class TeamHelpModalComponent {
  private source = 'TeamHelpModalComponent';
  @Input() user: UserModel;
  @Input() parentSource: string;

  constructor(
    private utils: Utils,
    private localData: LocalData,
    private logger: Logger,
  ) { }

  // utils
  getHelp() {
    setTimeout(function () {
      window.open('https://support.elivelihood.com?source=' + this.parentSource, '_blank');
    }, 500);
  }

}
