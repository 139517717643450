import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

// rxjs
import {forkJoin as observableForkJoin, of as observableOf, throwError as observableThrowError,  BehaviorSubject ,  Observable } from 'rxjs';
import {mergeMap, catchError, map} from 'rxjs/operators';

// models
import { UserModel, UserTeamsModel } from '../models/user.model';
import {
  TeamConstants,
  TeamModel,
  TeamUserModel,
  TeamPermissionsModel,
  UserAndTeams,
  Teams,
  OwnershipTransfer
} from '../models/team.model';
import { CoachSurveyModel } from 'models/coach.model';

// providers
import { environment } from '../environments/environment';
import { UserService } from './user.service';
import { Utils } from './utils.service';
import { Logger } from './logger.service';
import { v4 as uuidv4 } from 'uuid';
import {CoachNoteModel} from '../models/coach-note.model';
import { TeamAvailablePoliciesModel } from '../models/team-available-policies.model';
import { TeamPolicyUpdateModel } from '../models/team-policy-update.model';
import { ReplaceRecipientEmailModel } from '../models/replace-recipient-email-model';
import { ApiResponseModel } from '../models/api-response.model';

@Injectable()
export class TeamService {
  private source = 'TeamService';
  private apiBaseUrl = environment.apiBaseUrl;
  public teamConstants: TeamConstants = new TeamConstants();

  // subscribed for updates via local data service
  public userTeamsSource: BehaviorSubject<TeamModel[]> = new BehaviorSubject(null);

  constructor(
    private http: HttpClient,
    private utils: Utils,
    private logger: Logger,
    private userService: UserService
  ) { }

  getTeam(teamId: string): Observable<TeamModel> {
    this.logger.logInfo(this.source, 'getTeam', 'API get /teams/:id');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/teams/' + teamId, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'getTeam', { status: res.status, statusText: res.statusText }, 'API get /teams/:id');
        this.logger.logTrace(this.source, 'getTeam', null, 'API get /teams/:id');
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getTeam', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  createTeam(team: TeamModel): Observable<TeamModel> {
    this.logger.logInfo(this.source, 'createTeam', 'API post /teams');
    const body = team;
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/teams', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'createTeam', { status: res.status, statusText: res.statusText }, 'API post /teams');
        this.logger.logTrace(this.source, 'createTeam', null, 'API post /teams');
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'createTeam', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  updateTeam(team: TeamModel): Observable<TeamModel> {
    this.logger.logInfo(this.source, 'updateTeam', 'API put /teams/:id');
    const body = team;
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + '/teams/' + team.id, body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'updateTeam', { status: res.status, statusText: res.statusText }, 'API put /teams/:id');
        this.logger.logTrace(this.source, 'updateTeam', null, 'API put /teams/:id');
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'updateTeam', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  uploadAvatar(teamId: string, fileToUpload: any): Observable<TeamModel> {
    this.logger.logInfo(this.source, 'uploadAvatar', 'API put /teams/:id/avatar');
    const body = new FormData();
    body.append('file', fileToUpload);
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + '/teams/' + teamId + '/avatar', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'uploadImage', { status: res.status, statusText: res.statusText }, 'API post /teams/:id/avatar');
        this.logger.logTrace(this.source, 'uploadImage', null, 'API post /teams/:id/avatar');
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'uploadAvatar', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  getTeamUsers(teamId: string): Observable<TeamUserModel[]> {
    this.logger.logInfo(this.source, 'getTeamUsers', 'API get /teams/:id/users');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/teams/' + teamId + '/users', options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'getTeamUsers', { status: res.status, statusText: res.statusText }, 'API get /teams/:id/users');
        this.logger.logTrace(this.source, 'getTeamUsers', null, 'API get /teams/:id/users');
        return this.utils.parsePermissions(this.utils.adjustTimesForArray(res.body));
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getTeamUsers', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  getTeamUsersForCreateNewMessage(teamId: string): Observable<TeamUserModel[]> {
    this.logger.logInfo(this.source, 'getTeamUsersForCreateNewMessage', 'API get /teams/:id/users-for-create-new-message');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/teams/' + teamId + '/users-for-create-new-message', options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'getTeamUsersForCreateNewMessage', { status: res.status, statusText: res.statusText }, 'API get /teams/:id/users-for-create-new-message');
        this.logger.logTrace(this.source, 'getTeamUsersForCreateNewMessage', null, 'API get /teams/:id/users-for-create-new-message');
        return this.utils.parsePermissions(this.utils.adjustTimesForArray(res.body));
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getTeamUsersForCreateNewMessage', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  updateTeamUser(teamId: string, teamRoleId: number, userId: string, permissions: TeamPermissionsModel): Observable<Observable<number>> {
    this.logger.logInfo(this.source, 'updateTeamUser', 'API put /teams/:id/users/:id');
    const body = {
      teamRoleId: teamRoleId,
      userId: userId,
      permissions: JSON.stringify(permissions)
    };
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + '/teams/' + teamId + '/users/' + userId, body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'updateTeamUser', { status: res.status, statusText: res.statusText }, 'API put /teams/:id/users/:id');
        this.logger.logTrace(this.source, 'updateTeamUser', null, 'API put /teams/:id/users/:id');
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'updateTeamUser', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  deleteTeamUser(teamId: string, userId: string, newUserId: string): Observable<Observable<number>> {
    this.logger.logInfo(this.source, 'deleteTeamUser', 'API delete /teams/:id/users/:id/:id');
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(this.apiBaseUrl + '/teams/' + teamId + '/users/' + userId + '/' + newUserId, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'deleteTeamUser', { status: res.status, statusText: res.statusText }, 'API delete /teams/:id/users/:id/:id');
        this.logger.logTrace(this.source, 'deleteTeamUser', null, 'API delete /teams/:id/users/:id/:id');
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'deleteTeamUser', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  getTeamCoach(teamId: string): Observable<UserModel> {
    return this.getTeamUsers(teamId).pipe(
      map(teamUsers => teamUsers.filter(user => user.teamRoleId === this.teamConstants.COACH)),
      mergeMap(teams_api => {
        return this.addUsersToTeamUsers(teams_api);
      }),
      map(teamUsers => teamUsers.map(teamUser => teamUser.user)),
      map(users => users[0])
    );
  }

  getTeamCoachSurvey(teamId: string): Observable<CoachSurveyModel> {
    this.logger.logInfo(this.source, 'getTeamCoachSurvey', 'API get /teams/:id/coach-survey');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/teams/' + teamId + '/coach-survey', options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'getTeamCoachSurvey', { status: res.status, statusText: res.statusText }, 'API get /teams/:id/coach-survey');
        this.logger.logTrace(this.source, 'getTeamCoachSurvey', null, 'API get /teams/:id/coach-survey');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getTeamCoachSurvey', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  transferTeamOwnerShip(teamId: string, ownershipTransfer: OwnershipTransfer): Observable<Observable<number>> {
    this.logger.logInfo(this.source, 'transferTeamOwnerShip', 'API post /teams/:id/ownership-transfers');
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/teams/' + teamId + '/ownership-transfers', ownershipTransfer, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'transferTeamOwnerShip', { status: res.status, statusText: res.statusText }, 'API post /teams/:id/ownership-transfers');
        this.logger.logTrace(this.source, 'transferTeamOwnerShip', null, 'API post /teams/:id/ownership-transfers');
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'transferTeamOwnerShip', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  getOwnershipTransfer(teamId: string): Observable<OwnershipTransfer> {
    this.logger.logInfo(this.source, 'getOwnershipTransfer', 'API get /teams/:id/ownership-transfers');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/teams/' + teamId + '/ownership-transfers', options).pipe(
      map((res: HttpResponse<OwnershipTransfer>) => {
        this.logger.logDebug(this.source, 'getOwnershipTransfer', { status: res.status, statusText: res.statusText }, 'API get /teams/:id/ownership-transfers');
        this.logger.logTrace(this.source, 'getOwnershipTransfer', null, 'API get /teams/:id/ownership-transfers');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getOwnershipTransfer', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  cancelOwnershipTransfer(teamId: string): Observable<any> {
    this.logger.logInfo(this.source, 'cancelOwnershipTransfer', 'API delete /teams/:id/ownership-transfers');
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(this.apiBaseUrl + '/teams/' + teamId + '/ownership-transfers', options).pipe(
      map((res: HttpResponse<OwnershipTransfer>) => {
        this.logger.logDebug(this.source, 'cancelOwnershipTransfer', { status: res.status, statusText: res.statusText }, 'API get /teams/:id/ownership-transfers');
        this.logger.logTrace(this.source, 'cancelOwnershipTransfer', null, 'API delete /teams/:id/ownership-transfers');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'cancelOwnershipTransfer', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  respondToOwnershipTransfer(teamId: string, status: number) {
    this.logger.logInfo(this.source, 'respondToOwnershipTransfer', 'API post /teams/:id/ownership-transfers/respond');
    const options = this.utils.getJsonHttpOptions();
    const body = status;
    return this.http.post(this.apiBaseUrl + '/teams/' + teamId + '/ownership-transfers/respond', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'respondToOwnershipTransfer', { status: res.status, statusText: res.statusText }, 'API post /teams/:id/ownership-transfers/respond');
        this.logger.logTrace(this.source, 'respondToOwnershipTransfer', null, 'API post /teams/:id/ownership-transfers/respond');
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'respondToOwnershipTransfer', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  // multiple
  getTeamsForUser(userTeams: Array<UserTeamsModel>): Observable<TeamModel[]> {
    if (userTeams.length < 1) {
      return observableOf(new Array<TeamModel>());
    } else {
      this.logger.logInfo(this.source, 'getTeamsForUser', 'multiple: get teams for userTeams');
      const getTeamsForUser = userTeams.map((userTeam: UserTeamsModel, idx: number) => {
        return this.getTeam(userTeam.teamId).pipe(
          map(team_api => {
            return team_api;
          }),
          catchError((err: any) => {
            this.logger.logError(this.source, 'getTeamsForUser', err,
              'multiple: getTeam for team.id = ${userTeam.teamId}');
            return observableOf(null);
          }), );
      });
      return observableForkJoin(getTeamsForUser);
    }
  }



  // expand
  sourceUserTeamData(user: UserModel, onlyCurrentTeam = false, currentTeamId = null): Observable<UserAndTeams> {
    this.logger.logInfo(this.source, 'sourceUserTeamData', 'expand: source: user teams to BehaviorSubject: userTeamsSource, userSource');
    return this.userService.getUserTeams(user.id).pipe(
      map(userTeams_api => {
        if (!onlyCurrentTeam) {
          return userTeams_api;
        }
        return userTeams_api.filter(t => currentTeamId === t.teamId);
      }),
      mergeMap(userTeams_api => {
        this.logger.logTrace(this.source, 'sourceUserTeamData', null, 'userService: getUserTeams');
        user.teams = userTeams_api;
        return this.getTeamsForUser(user.teams);
      }),
      mergeMap(teams_api => {
        this.logger.logTrace(this.source, 'sourceUserTeamData', null, 'teamService: getTeamsForUser');
        return this.addTeamUsersToTeams(teams_api);
      }),
      mergeMap(teams_api => {
        this.logger.logTrace(this.source, 'sourceUserTeamData', null, 'teamService: addTeamUsersToTeams');
        const getTeamUserForTeamUsers = teams_api.map((team: TeamModel, idx: number) => {
          return this.addUsersToTeamUsers(team.teamUsers).pipe(
            map(teamUsers_api => {
              this.logger.logTrace(this.source, 'sourceUserTeamData', null, 'teamService: addUsersToTeamUsers');
              teams_api[idx].teamUsers = teamUsers_api;
              return teams_api[idx];
            }),
            catchError((err: any) => {
              this.logger.logError(this.source, 'sourceUserTeamData', err,
                'teamService: addUsersToTeamUsers for team id = ${team.id}');
              return observableOf(null);
            }), );
        });
        if (getTeamUserForTeamUsers.length < 1) {
          return observableOf(new Array<TeamModel>());
        } else {
          return observableForkJoin(getTeamUserForTeamUsers);
        }
      }),
      mergeMap(teams_api => {
        this.logger.logTrace(this.source, 'sourceUserTeamData', teams_api, 'forkJoin: getTeamUserForTeamUsers');
        return this.utils.sortTeamsByRoleThenCreated(user.teams, teams_api as Array<TeamModel>);
      }),
      map((teams_api: Array<TeamModel>) => {
        this.logger.logTrace(this.source, 'sourceUserTeamData', null, 'userService: sortTeamsByRoleThenCreated');
        if (teams_api.length > 0) {
          this.userTeamsSource.next(teams_api);
        } else {
          this.userTeamsSource.next(null);
        }
        this.userService.userSource.next(user);
        const userAndTeams: UserAndTeams = new UserAndTeams(user, teams_api);
        return userAndTeams;
      }, err => {
        this.logger.logError(this.source, 'sourceUserTeamData', err,
          'mergeMap: sourceUserTeamData for user id = ${user.id}');
        observableThrowError(err);
      }), );
  }

  addTeamUsersToTeams(teams: Array<TeamModel>): Observable<TeamModel[]> {
    if (teams.length < 1) {
      return observableOf(new Array<TeamModel>());
    } else {
      this.logger.logInfo(this.source, 'addTeamUsersToTeams', 'expand: add teamUsers for teams');
      const getTeamUsersForTeams = teams.map((team: TeamModel, idx: number) => {
        return this.getTeamUsers(team.id).pipe(
          map(teamUsers_api => {
            teams[idx].teamUsers = teamUsers_api;
            return teams[idx];
          }),
          catchError((err: any) => {
            this.logger.logError(this.source, 'addTeamUsersToTeams', err,
              'expand: getTeamUsers for team.id = ${team.id}');
            return observableOf(null);
          }), );
      });
      return observableForkJoin(getTeamUsersForTeams);
    }
  }

  addUsersToTeamUsers(teamUsers: Array<TeamUserModel>): Observable<TeamUserModel[]> {
    if (teamUsers.length < 1) {
      return observableOf(new Array<TeamUserModel>());
    } else {
      this.logger.logInfo(this.source, 'addUsersToTeamUsers', 'expand: add user for teamUsers');
      const getUsersForTeamUsers = teamUsers.map((teamUser: TeamUserModel, idx: number) => {
        return this.userService.getUser(teamUser.userId).pipe(
          map(user_api => {
            teamUsers[idx].user = user_api;
            return teamUsers[idx];
          }),
          catchError((err: any) => {
            this.logger.logError(this.source, 'addUsersToTeamUsers', err,
              'expand: getUser for user id = ${teamUser.userId}');
            return observableOf(null);
          }), );
      });
      return observableForkJoin(getUsersForTeamUsers);
    }
  }

  // billing
  changeBillingPlan(userId: string, teamId: string, subscriptionType: number, tokenId?: string, tokenEmail?: string): Observable<Observable<number>> {
    this.logger.logInfo(this.source, 'changeBillingPlan', 'API put /teams/:id/subscribe');
    const body = {
      teamId: teamId, creatorUserId: userId, subscriptionType: subscriptionType, token: tokenId, email: tokenEmail, stripeIdempotencyKey: uuidv4()
    };
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + '/teams/' + teamId + '/subscribe', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'changeBillingPlan', { status: res.status, statusText: res.statusText }, 'API put /teams/:id/subscribe');
        this.logger.logTrace(this.source, 'changeBillingPlan', null, 'API put /teams/:id/subscribe');
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'changeBillingPlan', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  // admin
  listAllTeams(): Observable<any[]> {
    this.logger.logInfo(this.source, 'listAllTeams', 'API get /admin/teams');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/admin/teams', options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'listAllTeams', { status: res.status, statusText: res.statusText }, 'API get /admin/teams');
        this.logger.logTrace(this.source, 'listAllTeams', null, 'API get /admin/teams');
        return this.utils.adjustTimesForArray(res.body.items);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'listAllTeams', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  updateTeamStatus(team: TeamModel): Observable<Observable<number>> {
    this.logger.logInfo(this.source, 'updateTeamStatus', 'API put /admin/teams/:id/status');
    const body = team;
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + '/admin/teams/' + team.id + '/status', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'updateTeamStatus', { status: res.status, statusText: res.statusText }, 'API put /admin/teams/:id/status');
        this.logger.logTrace(this.source, 'updateTeamStatus', null, 'API put /admin/teams/:id/status');
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'updateTeamStatus', err, 'API Error');
        return observableThrowError(err);
      }), );
  }
// auth team policies
  getAvailableTeamPolicy = (teamId: string): Observable<TeamAvailablePoliciesModel> => {
    this.logger.logInfo(this.source, 'getAvailableTeamPolicy', 'API get /authentication-policies/get-team-policies/:teamId');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/authentication-policies/get-team-policies/' + teamId, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'getAvailableTeamPolicy', { status: res.status, statusText: res.statusText },
          'API get /authentication-policies/get-team-policies/:teamId');
        this.logger.logTrace(this.source, 'getAvailableTeamPolicy', null, 'API get /authentication-policies/get-team-policies/:teamId');
        return res.body
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getTeam', err, 'API Error');
        return observableThrowError(err);
      }), );
}

   updateTeamTwoFactors = (model: TeamPolicyUpdateModel): Observable<boolean> => {
     this.logger.logInfo(this.source, 'updateTeamTwoFactors', 'API put /two-factor/team/update');
     const options = this.utils.getJsonHttpOptions();
     return this.http.put(this.apiBaseUrl + '/authentication-policies/two-factor/team/update', model, options).pipe(
       map((res: HttpResponse<boolean>) => {
         this.logger.logDebug(this.source, 'updateTeamTwoFactors', { status: res.status, statusText: res.statusText }, 'API put /two-factor/team/update');
         this.logger.logTrace(this.source, 'updateTeamTwoFactors', null, 'API put /two-factor/team/update');
         return res.body
       }),
       catchError((err: any) => {
         this.logger.logError(this.source, 'updateTeamTwoFactors', err, 'API Error');
         return observableThrowError(err);
       }), );
   }

  updateTeamPasswordRotation = (model: TeamPolicyUpdateModel): Observable<boolean> => {
    this.logger.logInfo(this.source, 'updateTeamPasswordRotation', 'API put /password-rotation/team/update');
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + '/authentication-policies/password-rotation/team/update', model, options).pipe(
      map((res: HttpResponse<boolean>) => {
        this.logger.logDebug(this.source, 'updateTeamPasswordRotation', { status: res.status, statusText: res.statusText }, 'API put /password-rotation/team/update');
        this.logger.logTrace(this.source, 'updateTeamPasswordRotation', null, 'API put /password-rotation/team/update');
        return res.body
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'updateTeamPasswordRotation', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  updateReplaceRecipientEmail = (model: ReplaceRecipientEmailModel) => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/teams/recipient/replace-email', model, options).pipe(
      map((res: HttpResponse<ApiResponseModel>) => {
        this.logger.logDebug(this.source, 'updateReplaceRecipientEmail', { status: res.status, statusText: res.statusText }, 'API post /teams/recipient/replace-email');
        this.logger.logTrace(this.source, 'updateReplaceRecipientEmail', null, 'API post /teams/recipient/replace-email');
        return res.body
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'updateReplaceRecipientEmail', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  requestTeamDelete(teamId: string) {
    this.logger.logInfo(this.source, 'requestTeamDelete', 'API delete /teams/request-delete/:id');
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(this.apiBaseUrl + '/teams/request-delete/' + teamId, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'requestTeamDelete', { status: res.status, statusText: res.statusText }, 'API delete /teams/request-delete/:id');
        this.logger.logTrace(this.source, 'requestTeamDelete', null, 'API delete /teams/request-delete/:id');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'requestTeamDelete', err, 'API Error');
        return observableThrowError(err);
      })
    );
  }

  getPreDeleteReport(teamId: string): Observable<string> {
    this.logger.logInfo(this.source, 'getPreDeleteReport', 'API get /teams/get-pre-delete-report/:id');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/teams/get-pre-delete-report/' + teamId, options).pipe(
      map((res: HttpResponse<string>) => {
        this.logger.logDebug(this.source, 'getPreDeleteReport', { status: res.status, statusText: res.statusText }, 'API get /teams/get-pre-delete-report/:id');
        this.logger.logTrace(this.source, 'getPreDeleteReport', null, 'API delete /teams/get-pre-delete-report/:id');
        return res.body
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getPreDeleteReport', err, 'API Error');
        return observableThrowError(err);
      })
    );
  }

  deleteTeam(teamId: string): Observable<Boolean> {
    this.logger.logInfo(this.source, 'deleteTeam', 'API get /teams/delete-team/:id');
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(this.apiBaseUrl + '/teams/delete-team/' + teamId, options).pipe(
      map((res: HttpResponse<Boolean>) => {
        this.logger.logDebug(this.source, 'deleteTeam', { status: res.status, statusText: res.statusText }, 'API get /teams/delete-team/:id');
        this.logger.logTrace(this.source, 'deleteTeam', res, 'API delete /teams/delete-team/:id');
        return res.body
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'deleteTeam', err, 'API Error');
        return observableThrowError(err);
      })
    );
  }

  canCreateTeam() {
    this.logger.logInfo(this.source, 'canCreateTeam', 'API post /teams/can-create-team');
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/teams/can-create-team', options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'canCreateTeam', { status: res.status, statusText: res.statusText }, 'API post /teams/can-create-team');
        this.logger.logTrace(this.source, 'canCreateTeam', res, 'API post /teams/can-create-team');
        return res
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'canCreateTeam', err, 'API Error');
        return observableThrowError(err);
      })
    );
  }

   requestDeleteTeam(teamId: string) {
    this.logger.logInfo(this.source, 'requestDeleteTeam', 'API delete /teams/request-delete-team/:id');
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(this.apiBaseUrl + '/teams/request-delete-team/' + teamId, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'requestDeleteTeam', { status: res.status, statusText: res.statusText }, 'API delete /teams/request-delete-team/:id');
        this.logger.logTrace(this.source, 'requestDeleteTeam', null, 'API delete /teams/request-delete-team/:id');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'requestDeleteTeam', err, 'API Error');
        return observableThrowError(err);
      })
    );
  }

  tutorialClicked = (teamId: string) => {
    this.logger.logInfo(this.source, 'tutorial-click', 'API POST /teams/tutorial-click/:teamId');
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/teams/tutorial-click/' + teamId, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'tutorialClicked', { status: res.status, statusText: res.statusText }, `API POST /teams/tutorial-click/:${teamId}`);
        this.logger.logTrace(this.source, 'tutorialClicked', null, `API POST /teams/tutorial-click/:${teamId}`);
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'tutorialClicked', err, 'API Error');
        return observableThrowError(err);
      })
    );
  }
}
