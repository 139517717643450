import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-team-modal',
  templateUrl: './new-team-modal.component.html',
  styleUrls: ['./new-team-modal.component.scss']
})
export class NewTeamModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<NewTeamModalComponent>,
  ) { }

  ngOnInit(): void {
  }
}
