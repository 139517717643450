<mat-card class="notifications-settings-card">
    <mat-card-header>
        <mat-card-title>Notifications Settings</mat-card-title>
    </mat-card-header>
        <mat-card-content>
            <mat-tab-group>
                <mat-tab label="Push Notifications">
                    <div style="width: 100%;overflow: hidden">
                        <div class="row" style="margin-left: 0.5em;margin-top: 0.5em;">
                            <mat-slide-toggle [(ngModel)]="model.sendPushNotificationsCalendar" (change)="onNotificationChange('send_push_notifications_calendar', $event.checked)" name="sendPushNotificationsCalendar">Calendar</mat-slide-toggle>
                        </div>
                        <div class="row" style="margin-left: 0.5em;margin-top: 0.5em;">
                            <mat-slide-toggle [(ngModel)]="model.sendPushNotificationsSafekeeper" (change)="onNotificationChange('send_push_notifications_safekeeper', $event.checked)" name="sendPushNotificationsSafekeeper">SafeKeeper</mat-slide-toggle>
                        </div>
                        <div class="row" style="margin-left: 0.5em;margin-top: 0.5em;">
                            <mat-slide-toggle [(ngModel)]="model.sendPushNotificationsMedia" (change)="onNotificationChange('send_push_notifications_media', $event.checked)" name="sendPushNotificationsMedia">Media</mat-slide-toggle>
                        </div>
                        <div class="row" style="margin-left: 0.5em;margin-top: 0.5em;">
                            <mat-slide-toggle [(ngModel)]="model.sendPushNotificationsMessages" (change)="onNotificationChange('send_push_notifications_messages', $event.checked)" name="sendPushNotificationsMessages">Messages</mat-slide-toggle>
                        </div>
                      <div class="row" *ngIf="doesHaveUserRole | async" style="margin-left: 0.5em;margin-top: 0.5em;">
                        <mat-slide-toggle [(ngModel)]="model.sendPushNotificationsCoach" (change)="onNotificationChange('send_push_notifications_coach', $event.checked)" name="sendPushNotificationsCoach">Coach</mat-slide-toggle>
                      </div>
                    </div>
                </mat-tab>
                <mat-tab label="Email Notifications">
                    <div style="width: 100%;overflow: hidden">
                        <!-- <div class="row" style="margin-left: 0.5em;margin-top: 0.5em;">
                            <mat-slide-toggle [(ngModel)]="model.sendEmailNotificationsMarketing" (change)="onNotificationChange('send_email_notifications_marketing', $event.checked)" name="sendEmailNotificationsMarketing">Marketing</mat-slide-toggle>
                        </div> -->
                        <div class="row" style="margin-left: 0.5em;margin-top: 0.5em;">
                            <mat-slide-toggle [(ngModel)]="model.sendEmailNotificationsCalendar" (change)="onNotificationChange('send_email_notifications_calendar', $event.checked)" name="sendEmailNotificationsCalendar">Calendar</mat-slide-toggle>
                        </div>
                        <div class="row" style="margin-left: 0.5em;margin-top: 0.5em;">
                            <mat-slide-toggle [(ngModel)]="model.sendEmailNotificationsSafekeeper" (change)="onNotificationChange('send_email_notifications_safekeeper', $event.checked)" name="sendEmailNotificationsSafekeeper">SafeKeeper</mat-slide-toggle>
                        </div>
                        <div class="row" style="margin-left: 0.5em;margin-top: 0.5em;">
                            <mat-slide-toggle [(ngModel)]="model.sendEmailNotificationsMedia" (change)="onNotificationChange('send_email_notifications_media', $event.checked)" name="sendEmailNotificationsMedia">Media</mat-slide-toggle>
                        </div>
                        <div class="row" style="margin-left: 0.5em;margin-top: 0.5em;">
                            <mat-slide-toggle [(ngModel)]="model.sendEmailNotificationsMessages" (change)="onNotificationChange('send_email_notifications_messages', $event.checked)" name="sendEmailNotificationsMessages">Messages</mat-slide-toggle>
                        </div>
                        <div class="row" *ngIf="doesHaveUserRole | async" style="margin-left: 0.5em;margin-top: 0.5em;">
                          <mat-slide-toggle [(ngModel)]="model.sendEmailNotificationsCoach" (change)="onNotificationChange('send_email_notifications_coach', $event.checked)" name="sendEmailNotificationsCoach">Coach</mat-slide-toggle>
                        </div>
                    </div>
                </mat-tab>
              </mat-tab-group>
        </mat-card-content>
</mat-card>

