export function lowerCount(text: string): number {
  return (text?.match(/[a-z]/g) || []).length;
}

export function upperCount(text: string): number {
  return (text?.match(/[A-Z]/g) || []).length;
}

export function numberCount(text: string): number {
  return (text?.match(/[0-9]/g) || []).length;
}

export function specialCount(text: string): number {
  return (text?.match(/[^a-zA-Z0-9]/g) || []).length;
}

export function truncate(text: string, len: number): string {
  if (text !== undefined && text != null && text.length > len) {
    return text.substring(0, len) + ' ...';
  }
  return text;
}
