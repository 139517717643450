import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
import { Utils } from './utils.service';
import { Logger } from './logger.service';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {SettingModel} from '../models/setting.model';

@Injectable()
export class TermsAndConditionsService {
  private source = 'Terms And Conditions Service';
  private apiBaseUrl = environment.apiBaseUrl;

  constructor(
    private http: HttpClient,
    private utils: Utils,

    private logger: Logger
  ) {}

  getTOU(): Observable<SettingModel> {
    const func = 'get';
    const funcUrl = '/TermsAndConditions/GetTOU';
    const call = 'API GET' + funcUrl;
    const apiUrl = this.apiBaseUrl + funcUrl
    this.logger.logInfo(this.source, func, call);
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(apiUrl, options).pipe(
        map((res: any) => {
          this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText }, call);
          return res.body
        }),
        catchError((err: any) => {
          this.logger.logError(this.source, func, err, 'API Error');
          return Observable.throw(err);
        }))
      }

  update(data: SettingModel) {
    const func = 'post';
    const funcUrl = '/TermsAndConditions/Update';
    const call = 'API POST ' + funcUrl
    const apiUrl = this.apiBaseUrl + funcUrl
    this.logger.logInfo(this.source, func, call);
    const body = data;
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(apiUrl, body, options)
                     .pipe(
                      map((res: any) => {
                        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, id: data.id }, call);
                        return res.body
                       }),
                       catchError((err: any) => {
                        this.utils.showNotification('top', 'center', 'danger', true, null, null,
                        err);
                        this.logger.logError(this.source, func, err, 'API Error');
                        return Observable.throw(err);
                      }))
  }

  get(): Observable<string> {
    const func = 'get';
    const funcUrl = '/TermsAndConditions/Get';
    const call = 'API GET' + funcUrl;
    const apiUrl = this.apiBaseUrl + funcUrl
    this.logger.logInfo(this.source, func, call);
    return this.http.get(apiUrl)
        .pipe(
          map((res: any) => {
            this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText }, call);
            return res
          }),
          catchError((err: any) => {
            this.logger.logError(this.source, func, err, 'API Error');
            return Observable.throw(err);
          })
        )
  }
}
