import { Directive, Input } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS, ValidationErrors,
  Validator
} from '@angular/forms';


@Directive({
  selector: '[appMatchPasswords]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: MatchPasswordsDirective,
    multi: true
  }]
})
export class MatchPasswordsDirective implements Validator {

  @Input() password1: string;
  @Input() password2: string;

  constructor() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.get(this.password1)?.value === control.get(this.password2)?.value) {
      if (control.get(this.password2)?.errors) {
        delete control.get(this.password2).errors['valid'];
        control.get(this.password2).updateValueAndValidity({ onlySelf: true });
      }
      return null;
    } else {
      if (control.get(this.password2)?.dirty) {
        control.get(this.password2).setErrors({
          'valid': false
        });
      }
      return {
        'valid': false
      };
    }
  }

}
