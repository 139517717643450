import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import * as moment from 'moment';

// rxjs
import {Observable, of as observableOf, throwError as observableThrowError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

// models
import {
  CalendarConstants,
  CoachEvent,
  EventModel,
  EventNote,
  GetUserEventsModel,
  Participant
} from './../models/calendar.model';
import {IPaginatedDataModel} from 'models/paginated-data-model';

// providers
import {environment} from './../environments/environment';
import {Utils} from './utils.service';
import {Logger} from './logger.service';

@Injectable()
export class CalendarService {
  private source = 'CalendarService';
  private apiBaseUrl = environment.apiBaseUrl;
  private calConstants = new CalendarConstants();

  constructor(
    private http: HttpClient,
    private utils: Utils,
    private logger: Logger
  ) { }

  getUserEvents(userId: string, start: Date, end: Date, status?: string, role?: number, limit?: number, offset?: number): Observable<EventModel[]> {

    const startParam = moment(start).format('MM/DD/YYYY');
    const endParam = moment(end).format('MM/DD/YYYY');
    const call = 'API get /users/:id/events?start=' + startParam + '&end=' + endParam + status ? 'status=' + status : '';
    this.logger.logInfo(this.source, 'getUserEvents', call);
    let params = new HttpParams({
      fromObject:{
        start: startParam,
        end: endParam
      }
    });

    if (status) {
      params = params.set('status', status);
    }
    if (role) {
      params = params.set('role', role.toString());
    }
    if (limit) {
      params = params.set('limit', limit.toString());
    }
    if (offset === 0 || offset) {
      params = params.set('offset', offset.toString());
    }
    const options = this.utils.getJsonHttpOptionsWithParams(null, params);
    return this.http.get(this.apiBaseUrl + '/users/' + userId + '/events', options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'getUserEvents', { status: res.status, statusText: res.statusText, userId }, call);
        return this.processUserEvents(userId, this.utils.adjustTimesForArray(res.body.items));
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getUserEvents', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  getUserEventsV2(model: GetUserEventsModel): Observable<IPaginatedDataModel<EventModel>> {
    const call = 'API get /users/:id/events/v2';
    this.logger.logInfo(this.source, 'getUserEventsV2', call);
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/users/' + model.userId + '/events/v2', model, options).pipe(
      map((res: HttpResponse<IPaginatedDataModel<EventModel>>) => {
        this.logger.logDebug(this.source, 'getUserEventsV2', { status: res.status, statusText: res.statusText, userId: model.userId }, call);
        const data =  this.processUserEvents(model.userId, this.utils.adjustTimesForArray(res.body.data));
        return {
          count: res.body.count,
          data
        };
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getUserEventsV2', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  getCoachEvents(model: GetUserEventsModel): Observable<IPaginatedDataModel<CoachEvent>> {
    const call = 'API get /coach/events';
    this.logger.logInfo(this.source, 'getCoachEvents', call);
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(environment.apiBaseUrl + '/coach/events', model, options).pipe(
      map((res: HttpResponse<IPaginatedDataModel<CoachEvent>>) => {
        this.logger.logDebug(this.source, 'getCoachEvents', { status: res.status, statusText: res.statusText, userId: model.userId }, call);
        const data = <CoachEvent[]>this.processUserEvents(model.userId, this.utils.adjustTimesForArray(res.body.data));
        return {
          count: res.body.count,
          data
        };
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getCoachEvents', err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  getTeamEvents(userId: string, teamId: string, start: Date, end: Date, doNotConvertFromUtc?: boolean): Observable<EventModel[]> {
    const startParam = moment(start).format('MM/DD/YYYY');
    const endParam = moment(end).format('MM/DD/YYYY');
    const call = 'API get /teams/:id/events?start=' + startParam + '&end=' + endParam + status ? 'status=' + status : '';
    this.logger.logInfo(this.source, 'getTeamEvents', call);
    const params = new HttpParams({
      fromObject:{
        start: startParam,
        end: endParam
      }
    });
    const options = this.utils.getJsonHttpOptionsWithParams(null, params);
    return this.http.get(this.apiBaseUrl + '/teams/' + teamId + '/events', options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'getTeamEvents', { status: res.status, statusText: res.statusText, userId }, call);
        return this.processEventsColors(userId, this.utils.adjustTimesForArray(res.body.items, doNotConvertFromUtc));
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getTeamEvents', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  getEvent(teamId: string, eventId): Observable<EventModel> {
    const path = '/teams/' + teamId + '/events/' + eventId;
    const action = 'API get ' + path;
    this.logger.logInfo(this.source, 'getEvent', action);
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + path, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'getEvent', { status: res.status, statusText: res.statusText, teamId,eventId }, action);
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getEvent', err, action);
        return observableThrowError(err);
      }),);
  }

  createEvent(teamId: string, event: EventModel): Observable<EventModel> {
    const event_copy = Object.assign(new EventModel(), event); // to avoid changing start/end time in UI
    event_copy.startTime = moment.utc(event_copy.start).format('YYYY-MM-DD[T]HH:mm:ss');
    event_copy.endTime = moment.utc(event_copy.end).format('YYYY-MM-DD[T]HH:mm:ss');
    this.logger.logInfo(this.source, 'createEvent', 'API post /teams/:id/events');
    const body = event_copy;
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/teams/' + teamId + '/events', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'createEvent', { status: res.status, statusText: res.statusText, teamId }, 'API post /teams/:id/events');
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'createEvent', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  updateEvent(teamId: string, event: EventModel): Observable<EventModel> {
    const event_copy = Object.assign(new EventModel(), event); // to avoid changing start/end time in UI
    event_copy.startTime = moment.utc(event_copy.start).format('YYYY-MM-DD[T]HH:mm:ss');
    event_copy.endTime = moment.utc(event_copy.end).format('YYYY-MM-DD[T]HH:mm:ss');
    this.logger.logInfo(this.source, 'updateEvent', 'API put /teams/:id/events/:id');
    const body = event_copy;
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + '/teams/' + teamId + '/events/' + event.id, body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'updateEvent', { status: res.status, statusText: res.statusText, teamId }, 'API put /teams/:id/events/:id');
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'updateEvent', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  deleteEvent(teamId: string, eventId: string): Observable<Observable<number>> {
    this.logger.logInfo(this.source, 'deleteEvent', 'API delete /teams/:id/events/:id');
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(this.apiBaseUrl + '/teams/' + teamId + '/events/' + eventId, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'deleteEvent', { status: res.status, statusText: res.statusText, teamId, eventId }, 'API delete /teams/:id/events/:id');
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'deleteEvent', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  createRecurringEvents(teamId: string, eventId: string): Observable<Observable<number>> {
    const call = 'API post /teams/:id/events/:id/recurring';
    this.logger.logInfo(this.source, 'createRecurringEvents', call);
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/teams/' + teamId + '/events/' + eventId + '/recurring', null, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'createRecurringEvents', { status: res.status, statusText: res.statusText, teamId, eventId }, call);
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'createRecurringEvents', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  deleteRecurringEvents(teamId: string, parentId: string, start: Date): Observable<Observable<number>> {
    const startParam = moment.utc(start).format('YYYY-MM-DD[T]HH:mm:ss');
    const call = 'API delete /teams/:id/events/:id/recurring?start=' + startParam;
    this.logger.logInfo(this.source, 'deleteRecurringEvents', call);
    const params = new HttpParams({
      fromObject:{
        start: startParam,
      }
    });
    const options = this.utils.getJsonHttpOptionsWithParams(null, params);
    return this.http.delete(this.apiBaseUrl + '/teams/' + teamId + '/events/' + parentId + '/recurring', options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'deleteRecurringEvents', { status: res.status, statusText: res.statusText, teamId, parentId }, call);
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'deleteRecurringEvents', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  updateEventParticipant(teamId: string, eventId: string, participant: Participant): Observable<Participant> {
    this.logger.logInfo(this.source, 'updateEventParticipant', 'API put /teams/:id/events/:id/participants/:id');
    const body = participant;
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + '/teams/' + teamId + '/events/' + eventId + '/participants/' + participant.userId, body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'updateEventParticipant', { status: res.status, statusText: res.statusText, teamId, eventId }, 'API put /teams/:id/events/:id/participants/:id');
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'updateEventParticipant', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  deleteEventParticipant(teamId: string, eventId: string, userId: string): Observable<Observable<number>> {
    this.logger.logInfo(this.source, 'deleteEventParticipant', 'API delete /teams/:id/events/:id/participants/:id');
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(this.apiBaseUrl + '/teams/' + teamId + '/events/' + eventId + '/participants/' + userId, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'deleteEventParticipant', { status: res.status, statusText: res.statusText, teamId, eventId, userId }, 'API delete /teams/:id/events/:id/participants/:id');
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'deleteEventParticipant', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  createNote(teamId: string, eventId: string, note: EventNote): Observable<EventNote> {
    this.logger.logInfo(this.source, 'createNote', 'API post /teams/:id/events/:id/notes');
    const body = note;
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/teams/' + teamId + '/events/' + eventId + '/notes', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'createNote', { status: res.status, statusText: res.statusText, teamId, eventId }, 'API post /teams/:id/events/:id/notes');
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'createNote', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  updateNote(teamId: string, eventId: string, note: EventNote): Observable<Observable<number>> {
    this.logger.logInfo(this.source, 'updateNote', 'API put /teams/:id/events/:id/notes/:id');
    const body = note;
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + '/teams/' + teamId + '/events/' + eventId + '/notes/' + note.id, body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'updateNote', { status: res.status, statusText: res.statusText, teamId, eventId }, 'API put /teams/:id/events/:id/notes/:id');
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'updateNote', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  deleteNote(teamId: string, eventId: string, noteId: string): Observable<Observable<number>> {
    this.logger.logInfo(this.source, 'deleteNote', 'API delete /teams/:id/events/:id/notes/:id');
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(this.apiBaseUrl + '/teams/' + teamId + '/events/' + eventId + '/notes/' + noteId, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'deleteNote', { status: res.status, statusText: res.statusText, teamId, eventId, noteId }, 'API delete /teams/:id/events/:id/notes/:id');
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'deleteNote', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  getEventParticipantsNames(teamId: string, eventId): Observable<string[]> {
    const path = '/teams/' + teamId + '/events/' + eventId + '/participants-names';
    const action = 'API get ' + path;
    this.logger.logInfo(this.source, 'getEventParticipantsNames', action);
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + path, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'getEventParticipantsNames', { status: res.status, statusText: res.statusText, teamId, eventId }, action);
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getEventParticipantsNames', err, action);
        return observableThrowError(err);
      }),);
  }

  getUnreadEventsCount(userId: string, teamId?: string, eventType?: number, role?: number): Observable<number> {
    const path = '/users/' + userId + '/unread-events-count/';
    const action = 'API get ' + path;

    let params = new HttpParams();

    if (teamId) {
      params = params.set('teamId', teamId);
    }
    if (role) {
      params = params.set('role', role.toString());
    }
    if (eventType) {
      params = params.set('eventType', eventType.toString());
    }
    this.logger.logInfo(this.source, 'getUnreadEventsCount', action);
    const options = this.utils.getJsonHttpOptionsWithParams(null, params);
    return this.http.get(this.apiBaseUrl + path, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'getUnreadEventsCount', { status: res.status, statusText: res.statusText, userId, teamId }, action);
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getUnreadEventsCount', err, action);
        return observableThrowError(err);
      }),);
  }

  processUserEvents(userId, events: EventModel[]): EventModel[] {
    const processedEvents = new Array<EventModel>();
    for (const event of events) {
      const idx = processedEvents.findIndex(event_find => {
        return event_find.id === event.id;
      });
      if (idx > -1) {
        if (!processedEvents[idx].participants) {
          processedEvents[idx].participants = new Array<Participant>();
        }
        processedEvents[idx].participants.push(event.participant);
      } else {
        if (!event.participants) {
          event.participants = new Array<Participant>();
        }
        event.participants.push(event.participant);
        event.participant = null;
        processedEvents.push(event);
      }
    };
    const processedEventsWithFilteredParticipants = processedEvents.map(e => {
      e.participants = e.participants.filter(x => x);
      return e;
    })
    return this.processEventsColors(userId, processedEventsWithFilteredParticipants);
  }

  processEventsColors(userId: string, events: EventModel[]): EventModel[] {
    events.map(event => {
      event.className = 'cal-default';
      // responsible party color coding for types TASK and APPOINTMENT
      if (event.eventType !== this.calConstants.EVENT && event.participants && event.participants.length > 0) {
        const responsiblePartyParticipant = event.participants.find(participant => {
          return participant.role === this.calConstants.RESPONSIBLE_PARTY;
        });
        // note will only return a match on getUserEvents if user is responsible party
        if (responsiblePartyParticipant) {
          event.className = this.setColorForResponsibleParty(userId, responsiblePartyParticipant, event.className);
        } else {
          this.getEvent(event.teamId, event.id).subscribe(event_api => {
            const detailedEventResponsiblePartyParticipant = event_api.participants.find(participant => {
              return participant.role === this.calConstants.RESPONSIBLE_PARTY;
            });
            if(detailedEventResponsiblePartyParticipant) {
              event.className = this.setColorForResponsibleParty(userId, detailedEventResponsiblePartyParticipant, event.className);
            }
          }, err => {
            this.logger.logError(this.source, 'processEventsColors', {eventId: event.id, err: err}, 'API Error');
          });
        }
      }
    });
    return events;
  }

  setColorForResponsibleParty(userId: string, responsiblePartyParticipant: Participant, currentClassName: string) {
    if (responsiblePartyParticipant.userId === userId) {
      if (!responsiblePartyParticipant.accepted && !responsiblePartyParticipant.declined) {
        return this.calConstants.RESPONSIBLE_PARTY_USER_NOT_ACCEPTED;
      }
      if (responsiblePartyParticipant.accepted && responsiblePartyParticipant.declined) {
        if (moment(responsiblePartyParticipant.accepted).isAfter(responsiblePartyParticipant.declined)) {
          return this.calConstants.RESPONSIBLE_PARTY_USER_ACCEPTED;
        } else {
          return this.calConstants.RESPONSIBLE_PARTY_USER_DECLINED;
        }
      }
      if (responsiblePartyParticipant.accepted && !responsiblePartyParticipant.declined) {
        return this.calConstants.RESPONSIBLE_PARTY_USER_ACCEPTED;
      }
      if (responsiblePartyParticipant.declined && !responsiblePartyParticipant.accepted) {
        return this.calConstants.RESPONSIBLE_PARTY_USER_DECLINED;
      }
    } else {
      if (!responsiblePartyParticipant.accepted && !responsiblePartyParticipant.declined) {
        return this.calConstants.RESPONSIBLE_PARTY_NOT_ACCEPTED;
      }
      if (responsiblePartyParticipant.accepted && responsiblePartyParticipant.declined) {
        if (moment(responsiblePartyParticipant.accepted).isAfter(responsiblePartyParticipant.declined)) {
          return this.calConstants.RESPONSIBLE_PARTY_ACCEPTED;
        } else {
          return this.calConstants.RESPONSIBLE_PARTY_DECLINED;
        }
      }
      if (responsiblePartyParticipant.accepted && !responsiblePartyParticipant.declined) {
        return this.calConstants.RESPONSIBLE_PARTY_ACCEPTED;
      }
      if (responsiblePartyParticipant.declined && !responsiblePartyParticipant.accepted) {
        return this.calConstants.RESPONSIBLE_PARTY_DECLINED;
      }
    }
    return currentClassName;
  }
}

