export const environment = {
  production: false,
  apiBaseUrl: 'https://api.staging.bh.elivelihood.com/api',
  hubsBaseUrl: 'https://api.staging.bh.elivelihood.com/hubs',
  dashboardBaseUrl: 'https://dashboard.staging.bh.elivelihood.com',
  landingPageUrl: 'https://landing.staging.bh.elivelihood.com',
  surveyBaseUrl: 'https://brighthorizonscx.az1.qualtrics.com/jfe/form/SV_0634YoY6tfADqaG',
  serviceConvertFromUtc: true,
  logToConsole: false,
  logToCloud: false,
  defaultLogLevels: { fatal: true, error: true, warn: true, info: true, debug: false, trace: false },
  maxLogLineCache: 1000,
  flushLogLineCacheChunk: 10,
  trustAllHosts: false,
  applicationVersion: '1.3.9',
  stripePublishableKey: 'pk_test_uBMuMDtVwYu5sOhufOOIMb98',
  FIRE_BASE_WebPush_Certs: 'BDsjVY91ECY7jG7VvdsgLRVpr9ETORoj8NTHtOdNAsBh4vV8ZVI_8ggWQxrVBUbISarLc0m_OYuCDoo_ZAWnYaw',
  firebaseConfig : {
    apiKey: 'AIzaSyA4-DKrYbIlOWV581ox5dp-4ElEkGhMgeY',
    authDomain: 'elivelihood-com.firebaseapp.com',
    databaseURL: 'https://elivelihood-com.firebaseio.com',
    projectId: 'elivelihood-com',
    storageBucket: 'elivelihood-com.appspot.com',
    messagingSenderId: '1053939993104',
    appId: '1:1053939993104:web:a2a3c1b0f36461c6dcd895',
    measurementId: ''
  },
  recaptchaConfig: {
    siteKey: '6Lf4wXodAAAAACen9yNKy48NZyWq0dvj0UmKWxyH',
  },
  timeoutInSeconds: 900,
  showMedicalConcierge: true,
};
