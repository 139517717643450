import { Component, OnInit } from '@angular/core';
import { Utils } from "./../../../providers/utils.service";

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {

  constructor(public utils: Utils) { 
    
  }

  ngOnInit(): void {
    this.utils.pageTitleSource.next("Coaching");
  }

}
