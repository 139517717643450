import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Value } from 'ngx-avatar/lib/sources/value';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent {
  constructor(
    public dialogRef: MatDialogRef<ContactUsComponent>,
  ) { }
}
