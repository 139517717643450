<div mat-dialog-content>
    <div class="row">
        <div class="col-md-12 col-sm-12">
            You are not eligible to create a new team on the Bright Horizon’s Employee Benefits Platform. To create a
            new team please visit <a href="http://www.elivelihood.com/" target="_blank">Home</a> to create a new profile
            and create a new team or contact eLivelihood at 303-663-0306 or email us at <a
                href="mailto:support@elivelihood.com">support@elivelihood.com</a>
        </div>
    </div> 
</div>

<div mat-dialog-actions align="end" style="margin-top:30px">
    <button mat-button  class="mat-focus-indicator mat-button mat-button-base mat-secondary" color="secondary" (click)="handleClose()">Close</button>
</div>