import * as moment from 'moment';
import { PaginatorModel } from './paginator.model';

export class CalendarModel {
  new: Array<EventModel> = new Array<EventModel>();
  upcoming: Array<EventModel> = new Array<EventModel>();
  past: Array<EventModel> = new Array<EventModel>();
  sent: Array<EventModel> = new Array<EventModel>();
}

export class EventModel {
  // api model
  id: string;
  teamId: string;
  parentId: string;
  eventId: string;
  ownerId: string;
  title: string; // 50 characters
  summary: string; // 500 characters
  location: string; // 2000 characters
  eventType: number;
  tags: Array<string>;
  allDay: boolean;
  start: Date;
  end: Date;
  recurring: EventRecurring;
  created: Date;
  updated: Date;
  deleted: Date;
  // api model expanded
  ownerName: string;
  responsiblePartyId: string;
  responsiblePartyName: string;
  notes: Array<EventNote>;
  participants: Array<Participant>;
  // injected at UX
  teamName: string;
  recipientId: string;
  recipientName: string;
  resourceId: string; // team or responsible party id to link to scheduler resources
  className: string; // used to distinguish events by color based on tag
  editable: boolean; // can be dragged or resized
  startEditable: boolean; // can be moved by time
  durationEditable: boolean; // can be lengthe ned or shortened by time
  resourceEditable: boolean; // can be dragged to different resource in scheduler
  startTime: string; // for time picker, note that startTime on API is start in model = see calendar.service
  endTime: string; // for time picker, note that endTime on API is end in model = see calendar.service
  // TODO remove from service on user events
  participant: Participant;
}

export class CoachEvent extends EventModel {
  coachName: string;
  coachEmail: string;
  adminEmail: string;
  adminName: string;
  senderEmail: string;
  public client?: string;
  public originalInvitedByEmail?: string;
}

export class GetUserEventsModel extends PaginatorModel {
  orderBy = 'date';
  status: 'unread'|null;
  userId: string;
  role?: number;
  teamId?: string;
  eventType?: number;
  includeParticipants?: boolean;
  start: string;
  end: string;

  public setStart(v: Date) {
    const startFormatted = moment(v).format('MM/DD/YYYY');
    this.start = startFormatted;
  }

  public setEnd(v: Date) {
    const endFormatted = moment(v).format('MM/DD/YYYY');
    this.end = endFormatted;
  }
}

export class EventNote {
  id: string;
  ownerId: string;
  descriptionHtml: string;
  timeSpent: number;
  created: Date;
  updated: Date;
  deleted: Date;
  // injected at UX
  timeSpentDisplay: string;
  timeSpentDisplayHint: string;
  editMode: boolean;
  durationPickerValue: string;
}

export class TimeSpent {
  userId: string;
  timeSpentTotal: number;
}

export class StartEnd {
  start: Date;
  end: Date;
}

export class Participant {
  userId: string;
  eventId: string;
  role: number;
  read: Date;
  delivered: Date;
  archived: Date;
  accepted: Date;
  declined: Date;
  deleted: Date;
  // injected at UX
  userDisplayName: string;
  userEmail: string;

  constructor(userId: string, role: number) {
    this.userId = userId;
    this.role = role;
  }
}

export class ParticipantStatus {
  icon: string;
  iconLabel: string;
  class: string;
  text: string;
  when = 'Date/Time Unknown';

  // for status icons
  UNKNOWN = { icon: 'error', class: 'btn-default', iconLabel: 'Status Unknown', text: 'Status Unknown'  };
  UNDELIVERED = { icon: 'warning', class: 'btn-default', iconLabel: 'Undelivered', text: 'Undelivered' };
  DELIVERED = { icon: 'mail', class: 'btn-warning', iconLabel: 'Unread', text: 'Unread, Delivered' };
  READ = { icon: 'drafts', class: 'btn-success', iconLabel: 'Read', text: 'Read' };
  READ_RESPONSIBLE_PARTY = { icon: 'drafts', class: 'btn-warning', iconLabel: 'Read', text: 'Read' };
  ACCEPTED = { icon: 'check_box', class: 'btn-success', iconLabel: 'Accepted', text: 'Accepted' }; // responsible party only
  DECLINED = { icon: 'close', class: 'btn-danger', iconLabel: 'Declined', text: 'Declined' }; // responsible party only
  ARCHIVED = { icon: 'archive', class: 'btn-default', iconLabel: 'Archived', text: 'Archived' };
  calConstants: CalendarConstants;

  constructor(participant: Participant) {
    this.calConstants = new CalendarConstants();
    let status = this.UNKNOWN;
    if (participant) {
      status = this.UNDELIVERED;
      this.when = 'Not yet delivered.';
      if (participant.delivered) {
        this.when = this.formatDate(participant.delivered, 'MM/DD/YYYY h:mm A');
        status = this.DELIVERED;
      }
      if (participant.read) {
        this.when = this.formatDate(participant.read, 'MM/DD/YYYY h:mm A');
        status = this.READ;
        if (participant.role === this.calConstants.RESPONSIBLE_PARTY) {
          status = this.READ_RESPONSIBLE_PARTY;
        }
      }
      if (participant.accepted) {
        this.when = this.formatDate(participant.accepted, 'MM/DD/YYYY h:mm A');
        status = this.ACCEPTED;
      }
      if (participant.declined) {
        this.when = this.formatDate(participant.declined, 'MM/DD/YYYY h:mm A');
        status = this.DECLINED;
      }
      if (participant.accepted && participant.declined) {
        if (moment(participant.accepted).isAfter(participant.declined)) {
          this.when = this.formatDate(participant.accepted, 'MM/DD/YYYY h:mm A');
          status = this.ACCEPTED;
        } else {
          this.when = this.formatDate(participant.declined, 'MM/DD/YYYY h:mm A');
          status = this.DECLINED;
        }
      }
      if (participant.archived) {
        this.when = this.formatDate(participant.archived, 'MM/DD/YYYY h:mm A');
        status = this.ARCHIVED;
      }
    }
    this.icon = status.icon; this.class = status.class; this.iconLabel = status.iconLabel; this.text = status.text;
  }

  formatDate(date: Date, format: string) {
    return moment(date).format(format);
  }
}

export class EventPermission {
  editEvent: boolean;
  addNotes: boolean;
  constructor() {
    this.editEvent = false;
    this.addNotes = false;
  }
}

export class EventRecurring {
  everyNumber: number;
  everyOften: number;
  everyOftenDay: EventEveryOftenDay;
  everyOftenMonth: number;
  endsWhen: number;
  endsWhenDate: Date;
  endsWhenNumber: number;
}

export class EventEveryOftenDay {
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
}

export class CalendarConstants {
  // event participant role
  PARTICIPANT = 0;
  OWNER = 1;
  RESPONSIBLE_PARTY = 2;

  // responsible party acceptance class for color coding
  RESPONSIBLE_PARTY_USER_ACCEPTED = 'rpu-a';
  RESPONSIBLE_PARTY_USER_NOT_ACCEPTED = 'rpu-na';
  RESPONSIBLE_PARTY_USER_DECLINED = 'rpu-d';
  RESPONSIBLE_PARTY_ACCEPTED = 'rp-a';
  RESPONSIBLE_PARTY_NOT_ACCEPTED = 'rp-na';
  RESPONSIBLE_PARTY_DECLINED = 'rp-d';

  // responsbileparty status
  RESPONSIBLE_PARTY_STATUS_NONE = {icon: 'check_box_outline_blank', class: 'btn-status-icon-default'};
  RESPONSIBLE_PARTY_STATUS_ACCEPTED = {icon: 'check_box', class: 'btn-status-icon-accepted'};
  RESPONSIBLE_PARTY_STATUS_DECLINED = {icon: 'close', class: 'btn-status-icon-declined'};

  // default new event start time and duration in minutes
  NEW_EVENT_START_TIME = 8;
  NEW_EVENT_DURATION = 60;

  // event types
  EVENT = 0;
  TASK = 1;
  APPOINTMENT = 2;
  EVENT_TYPES = [
    { name: 'Event', id: 0 },
    { name: 'Task', id: 1 },
    { name: 'Appointment', id: 2 }
  ];
}
