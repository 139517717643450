import { UserService } from '../../../providers/user.service';
import { Component, Input, OnInit } from "@angular/core";
import { UserSettings } from "models/user-settings.model";
import { SiteParameters } from './../../../environments/site.default';
import {Observable} from 'rxjs/Observable';
import {map} from 'rxjs/operators';
import {UserRoles} from '../../../models/user.model';

@Component({
    selector: 'app-user-notificationsettings',
    templateUrl: 'notificationsettings.component.html',
    // styleUrls: [`notificationsettings.component${SiteParameters.SITE_PREFIX}.scss`]
    styleUrls: [`notificationsettings.component${SiteParameters.SITE_PREFIX}.scss`]
  })
export class NotificationSettingsComponent implements OnInit{
    @Input() userId: string;

    public model = new UserSettings();
    constructor(private userService: UserService){

    }
   ngOnInit(): void {
       this.userService.getUserSettings(this.userId).subscribe(resp => {
           this.model = resp;
       })
   }

   onNotificationChange(notificationName: string, value: boolean){
       this.userService.updateNotificationSetting(notificationName,value,this.userId).subscribe(resp =>{
       })
   }

   get doesHaveUserRole(): Observable<boolean> {
      return this.userService.getUserSelfIfNotExists()
        .pipe(
          map(u => u.role === UserRoles.USER),
        );
   }
}
