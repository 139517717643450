<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <app-user-profile-cmp
          [user]="recipient"
          userTypeDisplay="Recipient"
          dobRequired="true"
          action="createTeam"
          (canceled)="canceled.emit($event)"
          [showCancelButton]="asModal"
          (done)="done.emit($event)"></app-user-profile-cmp>
      </div>
    </div>
  </div>
</div>

<app-team-help-modal [user]="user" [parentSource]="source"></app-team-help-modal>
