import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NewTeamModalComponent } from 'app/team/new-team-modal/new-team-modal.component';
import { environment } from 'environments/environment';
import { TeamModel } from 'models/team.model';
import { UserConstants } from 'models/user.model';
import { TeamService } from 'providers/team.service';
import { Utils } from 'providers/utils.service';
import { bh_card_modal_width, bh_card_modal_width_small } from 'variables/dialog';
import { ForbidCreateTeamComponentComponent } from './forbid-create-team-component/forbid-create-team-component.component';

@Component({
  selector: 'app-teams-list',
  templateUrl: './teams-list.component.html',
  styleUrls: ['./teams-list.component.scss']
})
export class TeamsListComponent {
  @Input() teams: TeamModel[];
  @Input() hideNewTeamButton = false;

  public userConstants = new UserConstants();

  constructor(
    public dialog: MatDialog,
    private utils: Utils,
    public teamService: TeamService
  ) { }

  avatar(team: TeamModel) {
    if (!team) {
      return;
    }
    const defaultAvatarImg = environment.dashboardBaseUrl + this.userConstants.DEFAULT_AVATAR;
    const recipient = this.utils.getTeamRecipient(team.teamUsers);

    return recipient?.user?.avatarUrl === defaultAvatarImg ? undefined : recipient?.user?.avatarUrl; 
  }

  fullName(team: TeamModel) {
    const {teamUsers, name} = team;
    const recipient = this.utils.getTeamRecipient(teamUsers).user;
    const {firstName, lastName} = recipient;
    if (firstName || lastName) {
      return `${firstName} ${lastName}`.trim();
    }
    return name ?? "-";
  }

  onNewTeamClicked() {
    this.teamService.canCreateTeam().subscribe(res=>{
      if(res){
        this.dialog.open(NewTeamModalComponent, {
          maxWidth: bh_card_modal_width
        });
      }
      else{
        // show the dialog to notify the user cannot create the team.
        this.dialog.open(ForbidCreateTeamComponentComponent, {maxWidth:bh_card_modal_width_small})
      }
    })
    
  }
}
