<div class="wrapper">
    <div class="sidebar sidebar-bh" data-active-color="brighthorizons" data-background-color="brighthorizons">
        <app-sidebar-cmp></app-sidebar-cmp>
        <div class="sidebar-background sidebar-background-bh" style="background-color: #1A475F"></div>
    </div>
    <div class="main-panel">
        <app-navbar-cmp></app-navbar-cmp>
        <router-outlet></router-outlet>
        <div *ngIf="!isMap()">
            <app-footer-cmp></app-footer-cmp>
        </div>
    </div>
</div>
