import { PaginatorModel } from './paginator.model';
import {IPaginatedDataModel} from './paginated-data-model';

export class CoachSurveyModel {
  id: string;
  coachId: string;
  teamId: string;
  status: number;
  teamName: string;
  senderName: string;
  senderEmail: string;
  timeZone: string;
  userId?: string;
  public inHomeAssessment: Boolean = false;
  public findingAFacility: Boolean = false;
  public findingInHomeServices: Boolean = false;
  public legalAdvice: Boolean = false;
  public financialEducation: Boolean = false;
  public planningForTheFuture: Boolean = false;
  public needAssistanceNow: Boolean = false;
  public personalMessage: string;
  public created: Date;
  public alreadyHasCoach?: Boolean;
  public client?: string;
  public originalInvitedByEmail?: string;
}

export type PaginatedCoachRequests = IPaginatedDataModel<CoachSurveyModel> ;

export class GetRequestsModel extends PaginatorModel {
  direction: 'asc'|'desc' = 'desc';
  orderBy = 'date';
  status: 'unresolved'|'accepted'|'rejected'|null;
  coachId: string;
}

export class GetCoachesModel extends PaginatorModel {
  filter: string;
}

export class GetUsersModel extends PaginatorModel {
  filter: string;
}

export class CoachSurveyStatus {
  static UNRESOLVED = 0;
  static ACCEPTED = 1;
  static REJECTED = 2;
}
