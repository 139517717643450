import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {AvatarModule} from 'ngx-avatar';

// components
import { SidebarComponent } from './sidebar.component';
import { TeamsListComponent } from './teams-list/teams-list.component';
import { ForbidCreateTeamComponentComponent } from './teams-list/forbid-create-team-component/forbid-create-team-component.component';

@NgModule({
  imports: [RouterModule, CommonModule, AvatarModule],
  declarations: [SidebarComponent, TeamsListComponent, ForbidCreateTeamComponentComponent],
  exports: [SidebarComponent]
})

export class SidebarModule { }
