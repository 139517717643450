import { TeamModel, TeamPermissionsModel, TeamUserModel } from 'models/team.model';
import { UserConstants, UserModel } from 'models/user.model';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SiteParameters } from 'environments/site.default';
import { UserService } from 'providers/user.service';
import { TeamService } from 'providers/team.service';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { TeamConstants } from 'models/team.model';
import { MessageService } from 'providers/message.service';
import { Logger } from 'providers/logger.service';
import { forkJoin, Observable, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { RemoveTeamMemberModalComponent } from 'app/team/team-members-module/remove-team-member-modal/remove-team-member-modal.component';

export type CardState = 'new'|'view'|'edit';

@Component({
  selector: 'app-add-team-member-card',
  templateUrl: './add-team-member-card.component.html',
  styleUrls: [
    `./add-team-member-card.component${SiteParameters.SITE_PREFIX}.scss`,
    './add-team-member-card.component.shared.scss'
  ]
})
export class AddTeamMemberCardComponent implements OnChanges {
  private source = "AddTeamMemberCardComponent";
  public administratorTooltip = "Administrators will have access to all information and have the ability to make any changes.";
  public contributorTooltip = "Contributors will have limited access for changes and Permissions can be set so only access to specific features will be shown.";
  public safeKeeperToolTip = "ALERT: you must also give permission for access to each tab on the safekeeper page for this user to access the information";
  public userForm: FormGroup;
  public disableForm = false;
  public userConstants = new UserConstants();
  public teamConstants = new TeamConstants();
  public authorized_user: UserModel;
  public contributor: UserModel;
  @Input() state: CardState = 'new';
  @Input() team = new TeamModel();
  @Input() existingMember = new TeamUserModel();
  @Input() isAdmin = false;
  public addedUsers: UserModel[] = [];
  public isLoading = false;
  @Input() canSkip = false;
  @Input() cancelable = false;
  @Output() skip = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Output() done = new EventEmitter();
 @Input() user = new UserModel();
 @Input() fromCoach = false
  public permissions = [
    {key:'calendar', label:'Calendar'},
    {key:'media', label:'Picture & Video Sharing'},
    {key:'safekeeper', label:'SafeKeeper'},
    {key:'messages', label:'Messages'},
  ];

  constructor(
    private fb: FormBuilder,
    private userService: UserService,
    private teamService: TeamService,
    private messageService: MessageService,
    private logger: Logger,
    private snackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnChanges(): void {
    this.isLoading = true;
    this.resetForm();
    if (this.inStates('edit', 'view')) {
      this.addFirstLastNameToForm();
      this.populateFormFromMember();
    }

    if(this.fromCoach){
      this.authorized_user = this.user
        this.isLoading = false;
    }
    else {
    // get authorized_user
    this.userService.getUserSelf()
      .subscribe(selfUser => {
          this.authorized_user = selfUser;
        this.isLoading = false;
      }, err => {
        this.handleApiError('ngOnInit', err);
        this.isLoading = false;
      });
    }
  }

  resetForm() {
    const defaultPermissions = this.teamConstants.CONTRIBUTOR_PERMISSIONS;
    this.userForm = this.fb.group({
      displayName: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email]],
      role: [null, Validators.required],
      permissions: this.fb.group({
        calendar: [defaultPermissions.calendar],
        media: [defaultPermissions.media],
        safekeeper: [defaultPermissions.safekeeper],
        messages: [defaultPermissions.messages],
      })
    });
  }

  addFirstLastNameToForm() {
    let firstName: AbstractControl;
    let lastName: AbstractControl;
    if (this.inStates('view', 'edit')) {
      firstName = this.fb.control('');
      lastName = this.fb.control('');
    } else {
      return;
    }
    this.userForm.addControl('firstName', firstName);
    this.userForm.addControl('lastName', lastName);
  }

  populateFormFromMember() {
    const member = this.existingMember;
    this.userForm.get('displayName').setValue(member.user.displayName)
    this.userForm.get('email').setValue(member.user.email)
    this.userForm.get('firstName').setValue(member.user.firstName)
    this.userForm.get('lastName').setValue(member.user.lastName)
    this.userForm.get('role').setValue(member.teamRoleId)
    this.userForm.get('permissions').get('calendar').setValue(member.permissions.calendar)
    this.userForm.get('permissions').get('media').setValue(member.permissions.media)
    this.userForm.get('permissions').get('safekeeper').setValue(member.permissions.safekeeper)
    this.userForm.get('permissions').get('messages').setValue(member.permissions.messages)
  }

  OnAddAnotherClicked() {
    this.userForm.markAllAsTouched()
    this.userForm.get("role").markAsDirty();
    if(this.userForm.valid) {
      this.isLoading = true;
      this.inviteTeamMember(false,true)
    }
  }

  OnAllDoneClicked() {
    this.userForm.get("role").markAsDirty();
    if (this.userForm.pristine) {
      this.onSkip();
    } else if(this.userForm.valid) {
      this.isLoading = true;
      this.inviteTeamMember(true,false)
    }
  }

  OnSaveClicked() {
    if (this.userForm.valid) {
      this.updateTeamUser();
    }
  }

  onSkip() {
    this.skip.emit();
  }

  OnCancelClicked() {
    if (this.addedUsers.length) {
      this.done.emit();
      return;
    }
    this.cancel.emit();
  }

  onResendAlertClicked() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        message: `${this.existingMember.user.displayName} has not yet signed in. Would you like to resend the email invitation?`
      }
    });
    forkJoin([
      dialogRef.afterClosed(),
      this.userService.getUserSelfIfNotExists()
    ])
      .pipe(
        mergeMap(([confirmed, userSelf]) => {
          if (confirmed) {
            const creatorId = userSelf.id;
            return this.userService.sendEmail(creatorId, 'Invited', creatorId, this.existingMember.user.id, this.team.name)
          }
          throw "not-confirmed"
        }),)
      .subscribe(status => {
        this.showSnackBar('The email invitation to join this team was resent to ' + this.existingMember.user.displayName + '.');
      }, err => {
        this.handleApiError('onResendAlertClicked', err);
      });
  }

  OnDeleteClicked() {
    const dialog = this.dialog.open(RemoveTeamMemberModalComponent, {
      width: '555px',
      data: {
        team: this.team,
        member: this.existingMember
      }
    });
    dialog.afterClosed()
      .subscribe(status => {
        if (status) {
          this.done.emit(true);
        }
      });
  }

  updateTeamUser() {
    this.disableForm = true;
    this.isLoading = true;
    this.teamService.updateTeamUser(this.team.id, this.selectedRole, this.existingMember.user.id, this.setupPermissionsObject())
      .subscribe(_ => {
        this.isLoading = false;
        this.showSnackBar('Team member profile has been updated.');
        this.disableForm = false;
        this.done.emit();
      },
      err => {
        this.isLoading = false;
        this.handleApiError('updateUser', err);
        this.disableForm = false;
      });
  }

  inviteTeamMember = (emit: boolean,resetForm: boolean) => {
    this.isLoading = true;
    const team = this.team;
    this.contributor = new UserModel();
    this.contributor.email = this.userForm.get('email').value;
    this.contributor.displayName = this.userForm.get('displayName').value;
    if (this.authorized_user.email === this.contributor.email) {
      this.showSnackBar('You cannot add a new team member using your own email address.');
      this.isLoading = false;
        return
    }
    if (this.isUserByEmailOnThisTeam(this.contributor, team)) {
      this.showSnackBar('You cannot add a new team member (' + this.contributor.email + ') for a user that is already on this team (' + this.team.name + ').');
      this.isLoading = false;
      return
    }

    this.userService.isUserRecipient(this.contributor.email).subscribe((res) =>{
      if(res){
        this.showSnackBar('You cannot add an existing recipient.')
        this.isLoading = false;
        return
      }
      else{
        this.userService.getUserIdByEmail(this.contributor.email)
          .pipe(
            mergeMap((id)=> {
              const _user = <UserModel>{
                email: this.contributor.email,
                id
              }
              return this.addUserToTeam(_user, team);
            }),
            catchError((_) => {
              return this.createUser(team);
            }),).subscribe((res) => {
          this.isLoading = false;

          if (emit)
            this.done.emit();

          if(resetForm)
            this.resetForm();
        })
      }
    })
  }

  addUserToTeam(user: UserModel, team: TeamModel): Observable<boolean> {
    return this.teamService.updateTeamUser(team.id, this.selectedRole, user.id, this.setupPermissionsObject())
    .pipe(
      mergeMap(_ => {
        const creatorId = this.authorized_user.id;
        return this.userService.sendEmail(creatorId, 'AddedToTeam', creatorId, user.id, team.name);
      }),
      mergeMap(_ => {
        return this.teamService.sourceUserTeamData(this.authorized_user);
      }),
      map(_ => {
        this.addedUsers.push(user);
        var body = 'You have been added by ' + this.authorized_user.displayName + ' as ' + this.selectedRoleName + ' on team ' + team.name;
        this.messageService.sendMessage(team.id, this.authorized_user.id, user.id, body);
        this.showSnackBar(this.contributor.displayName + ' has been invited to join this team.')
        return true;
      }),);
  }

  createUser(team: TeamModel): Observable<boolean> {
    this.contributor.invitedBy = this.authorized_user.email;
    let user: UserModel;
    return this.userService.createUser(this.contributor).pipe(
      mergeMap(user_api => {
        user = user_api;
        return this.teamService.updateTeamUser(team.id, this.selectedRole, user_api.id, this.setupPermissionsObject());
      }),
      mergeMap(status => {
        user.state = this.userConstants.INVITED;
        return this.userService.updateUser(user);
      }),
      mergeMap(_ => {
        const creatorId = this.authorized_user.id;
        return this.userService.sendEmail(creatorId, 'Invited', creatorId, user.id, team.name);
      }),
      mergeMap(_ => {
        return this.teamService.sourceUserTeamData(this.authorized_user);
      }),
      map(_ => {
        this.addedUsers.push(user);
        // send system message
        var body = 'You have been added by ' + this.authorized_user.displayName + ' as ' + this.selectedRoleName + ' on team ' + team.name;
        this.messageService.sendMessage(team.id, this.authorized_user.id, user.id, body);
        this.logger.logInfo(this.source, 'createUser', 'User Id: ' + user.id  + ' invited to Team Id: ' + team.id +  ' by User Id: ' + this.authorized_user.id);
        this.showSnackBar(this.contributor.displayName + ' has been invited to join this team.')
        return true;
      }),);
  }

  inStates(...states: CardState[]) {
    return states.includes(this.state);
  }

  inRoles(...states: number[]) {
    return states.includes(this.selectedRole);
  }

  isRoleNotSelected(role) {
    return this.selectedRole !== role;
  }

  isPermissionNotSelected(permissionName, level) {
    return this.userForm.get('permissions').get(permissionName).value !== level;
  }

  setupPermissionsObject(): TeamPermissionsModel {
    if (this.selectedRole === this.teamConstants.ADMIN) {
      return this.teamConstants.ADMIN_PERMISSIONS;
    }

    const teamPermissions = new TeamPermissionsModel();
    teamPermissions.calendar = this.userForm.get('permissions').get('calendar').value;
    teamPermissions.media = this.userForm.get('permissions').get('media').value;
    teamPermissions.safekeeper = this.userForm.get('permissions').get('safekeeper').value;
    teamPermissions.messages = this.userForm.get('permissions').get('messages').value;
    teamPermissions.health = this.teamConstants.CONTRIBUTOR_PERMISSIONS.health;

    return teamPermissions;
  }

  get selectedRoleName(): string {
    switch (this.selectedRole) {
      case this.teamConstants.ADMIN:
        return 'Administrator';
      case this.teamConstants.CONTRIBUTOR:
        return 'Contributor';
      default:
        return 'Contributor';
    }
  }

  get selectedRole() {
    return this.userForm.get('role').value;
  }

  get pending() {
    const user = this.existingMember?.user;
    return !user?.lastLogin || moment(user.lastLogin).valueOf() < 1
  }

  get isMemberCoach() {
    return this.existingMember?.teamRoleId === this.teamConstants.COACH;
  }

  get isMemberOwner() {
    return this.existingMember?.isOwner;
  }


  get firstName() {
    return this.existingMember?.user?.firstName?.length ? this.existingMember.user.firstName : '-';
  }

  get lastName() {
    return this.existingMember?.user?.lastName?.length ? this.existingMember.user.lastName : '-';
  }

  get displayName() {
    return this.existingMember?.user?.displayName?.length ? this.existingMember.user.displayName : '-';
  }

  get email() {
    return this.existingMember?.user?.email?.length ? this.existingMember.user.email : '-';
  }

  // utils
  isUserByEmailOnThisTeam(user: UserModel, team: TeamModel): boolean {
      if(!team.teamUsers)
          return false;

    const isExistingUser = team.teamUsers.find((tU) => tU.user.email === user.email) ||
      this.addedUsers.find(u => u.email === user.email);

    if (isExistingUser) {
      return true;
    } else {
      return false;
    }
  }

  showSnackBar(message) {
    this.snackBar.open(message, 'close', {
      duration: 4000,
    });
  }

  handleApiError(func, err) {
    // TODO implement ship logs
  }
}
