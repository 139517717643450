import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';

// rxjs
import { Observable, throwError } from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import { environment } from '../environments/environment';
import { Logger } from './logger.service';
import { Utils } from './utils.service';
import { LocalData } from './local-data.service';
import { DailyAssessmentModel } from 'models/daily-assessment.model';

@Injectable()
export class DailyAssessmentService {

    constructor(
      private http: HttpClient,
      private localData: LocalData,
      private logger: Logger,
      private utils: Utils) { }

    listLatestAssessments(teamId: string, limit: number, offset: number, search = ''): Observable<DailyAssessmentModel[]> {
        const params = new HttpParams({
          fromObject: {
            search
          }
        });
        const options = this.utils.getJsonHttpOptionsWithParams(null, params);
        return this.http.get(environment.apiBaseUrl + '/assessments/' + this.localData.getCurrentTeamId() + '/' + limit + '/' + offset, options).pipe(
            map((res: HttpResponse<DailyAssessmentModel[]>) => {
                return res.body;
            }),
            catchError((err: any) => {
                this.logger.logError('assessments', 'listLatestAssessments', err, 'API Error');
                return throwError(err);
            })
        );
    }

    create(assessment: DailyAssessmentModel): Observable<DailyAssessmentModel> {
        const options = this.utils.getJsonHttpOptions();
        return this.http.post(environment.apiBaseUrl + '/assessments/' + this.localData.getCurrentTeamId(), assessment, options).pipe(
            map((res: HttpResponse<any>) => {
                return res.body;
            }),
            catchError((err: any) => {
                this.logger.logError('assessments', 'create', err, 'API Error');
                return throwError(err);
            })
        );
    }

    update(assessment: DailyAssessmentModel): Observable<DailyAssessmentModel> {
        const options = this.utils.getJsonHttpOptions();
        return this.http.put(environment.apiBaseUrl + '/assessments/' + this.localData.getCurrentTeamId(), assessment, options).pipe(
            map((res: HttpResponse<any>) => {
                return res.body;
            }),
            catchError((err: any) => {
                this.logger.logError('assessments', 'create', err, 'API Error');
                return throwError(err);
            })
        );
    }

    get(id: string): Observable<DailyAssessmentModel> {
        const options = this.utils.getJsonHttpOptions();
        return this.http.get(environment.apiBaseUrl + '/assessments/' + this.localData.getCurrentTeamId() + '/' + id, options).pipe(
            map((res: HttpResponse<any>) => {
                return res.body;
            }),
            catchError((err: any) => {
                this.logger.logError('assessments', 'get:' + id, err, 'API Error');
                return throwError(err);
            })
        );
    }

    delete(id: string): Observable<Number> {
        const options = this.utils.getJsonHttpOptions();
        return this.http.delete(environment.apiBaseUrl + '/assessments/' + this.localData.getCurrentTeamId() + '/' + id, options).pipe(
            map((res: HttpResponse<any>) => {
                return res.body;
            }),
            catchError((err: any) => {
                this.logger.logError('assessments', 'get:' + id, err, 'API Error');
                return throwError(err);
            })
        );
    }
}
