import { Injectable } from '@angular/core';
import { HttpHeaders, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import 'moment-timezone';

// rxjs
import {of as observableOf,  Observable ,  BehaviorSubject } from 'rxjs';

// models
import { UserModel, UserTeamsModel, UserConstants } from '../models/user.model';
import { TeamModel, TeamUserModel, TeamConstants } from '../models/team.model';
import { EventModel, EventNote, Participant } from '../models/calendar.model';
import { Media, MediaItem, MediaConstants } from '../models/media.model';

// providers
import { environment } from './../environments/environment';
import { LocalData } from './local-data.service';
import { SiteParameters } from 'environments/site.default';

declare const $: any;

@Injectable()
export class Utils {
  // private source = 'Utils';
  private serviceConvertFromUtc: boolean = environment.serviceConvertFromUtc;
  private userConstants: UserConstants = new UserConstants();
  private teamConstants: TeamConstants = new TeamConstants();
  private mediaConstants: MediaConstants = new MediaConstants();

  // web only, subscribed for updates via admin layout
  public pageTitleSource: BehaviorSubject<string> = new BehaviorSubject(null);
  public doShowTutorialGetStarted: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    private localData: LocalData
  ) { }

  // test user
  isTestUser(email: string): boolean {
    if (
      email.includes('@elivel')
      || ( email.includes('+') && email.includes('@gmail.com') )
    ) {
      return true;
    }
    return false;
  }

  // api
  getJsonHttpOptions(headers?: HttpHeaders):{headers: HttpHeaders, observe: 'response', responseType: 'json'}{
    return {
      headers,
      observe: 'response',
      responseType: 'json'
    }
  }

  getCSVHttpOptions(headers: HttpHeaders):{headers: HttpHeaders, observe: 'response', responseType: 'blob'}{
    return {
      headers,
      observe: 'response',
      responseType: 'blob'
    }
  }

  getJsonHttpOptionsWithParams(headers?: HttpHeaders, params?: HttpParams) {
    let options: {
      headers:HttpHeaders,
      observe: 'response',
      responseType: 'json',
      params?: HttpParams
    };
    options = this.getJsonHttpOptions(headers);
    options = {...options, params}
    return options;
  }

  setCSVHeaders(): HttpHeaders{
    return new HttpHeaders({
      'Accept': 'text/csv; charset=UTF-8'
    });
  }

  // TODO: move to API
  secureResponse(item: any) {
    if (item.passwordHash) {
      item.passwordHash = null;
    }
    return item;
  }

  // time zone conversion for services, and event startTime remapping
  adjustTimesForArray(items: any, doNotConvertFromUtc?: boolean): any {
    if (this.serviceConvertFromUtc && !doNotConvertFromUtc) {
      items = this.convertFromUtcForArray(items);
    }
    items = this.remapEventTimesForArray(items);
    return items;
  }

  adjustTimes(item: any, doNotConvertFromUtc?: boolean): any {
    if (this.serviceConvertFromUtc && !doNotConvertFromUtc) {
      item = this.convertFromUtc(item);
    }
    item = this.remapEventTimes(item);
    return item;
  }

  convertFromUtcForArray(items: any): any {
    for (let item of items) {
      item = this.convertFromUtc(item);
    }
    return items;
  }

  setDateTimeQueryParam(dateTime: Date, format: string): string {
    let result = moment(dateTime).format(format);
    if (this.serviceConvertFromUtc) {
      result = moment(dateTime).utc().format(format);
    }
    return result;
  }

  convertFromUtc(item: any): any {
    item = this.convertCudFromUtc(item);
    // events
    if (item.startTime) {
      item.startTime = moment(moment.utc(item.startTime).format()).format();
    }
    if (item.endTime) {
      item.endTime = moment(moment.utc(item.endTime).format()).format();
    }
    // event notes
    if (item.notes) {
      for (let note of item.notes) {
        note = this.convertCudFromUtc(note);
      }
    }
    // event participant status
    if (item.participants) {
      for (let participant of item.participants) {
        participant = this.convertDradFromUtc(participant);
      }
    }
    return item;
  }

  convertCudFromUtc(item: any): any {
    if (item.created) {
      item.created = moment(moment.utc(item.created).format()).format();
    }
    if (item.updated) {
      item.updated = moment(moment.utc(item.updated).format()).format();
    }
    if (item.deleted) {
      item.deleted = moment(moment.utc(item.deleted).format()).format();
    }
    return item;
  }

  convertDradFromUtc(item: any): any {
    if (item.delivered) {
      item.delivered = moment(moment.utc(item.delivered).format()).format();
    }
    if (item.read) {
      item.read = moment(moment.utc(item.read).format()).format();
    }
    if (item.accepted) {
      item.accepted = moment(moment.utc(item.accepted).format()).format();
    }
    if (item.declined) {
      item.declined = moment(moment.utc(item.declined).format()).format();
    }
    if (item.archived) {
      item.archived = moment(moment.utc(item.archived).format()).format();
    }
    if (item.deleted) {
      item.deleted = moment(moment.utc(item.deleted).format()).format();
    }
    return item;
  }

  remapEventTimesForArray(items: any): any {
    for (let item of items) {
      item = this.remapEventTimes(item);
    }
    return items;
  }

  remapEventTimes(item: any): any {
    if (item.startTime) {
      item.start = item.startTime;
      item.startTime = moment(item.start).format('h:mm A');
    }
    if (item.endTime) {
      item.end = item.endTime;
      item.endTime = moment(item.end).format('h:mm A');
    }
    return item;
  }

  // device
  getDevicePlatform() {
    const device = this.localData.getDevice();
    if (!device) {
     return null;
    }
    if (!device.platform) {
      return null;
    }
    return device.platform;
  }

  getDeviceUuid() {
    const device = this.localData.getDevice();
    if (!device) {
     return null;
    }
    if (!device.uuid) {
      return null;
    }
    return device.uuid;
  }

  // user
  getUserId() {
    const user = this.localData.getUser();
    if (!user) {
      return null;
    }
    if (!user.id) {
      return null;
    }
    return user.id;
  }

  getUserEmailorUsername(user: UserModel): string {
    if (user.recipientNoEmail) {
      return user.username;
    } else {
      return user.email;
    }
  }

  // teams
  parsePermissions(items) {
    for (const item of items) {
      if (item.permissions) {
        item.permissions = JSON.parse(item.permissions);
      }
    }
    return items;
  }

  userIsTeamAdmin(userId: string, teamUsers: Array<TeamUserModel>): boolean {
    return this.getUserTeamUser(userId, teamUsers).teamRoleId === this.teamConstants.ADMIN;
  }

  getUserTeamUser(userId: string, teamUsers: Array<TeamUserModel>): TeamUserModel {
    let userTeamUser = teamUsers.find(teamUser => {
      return teamUser.userId === userId;
    });
    if (!userTeamUser) {
      // user no longer on team
      userTeamUser = new TeamUserModel();
      userTeamUser.teamRoleId = -1;
      userTeamUser.userId = userId;
      userTeamUser.user = new UserModel();
      userTeamUser.user.displayName = 'Former Team Member';
      userTeamUser.user.email = '';
      userTeamUser.user.avatarUrl = this.userConstants.DEFAULT_AVATAR;
    }
    return userTeamUser;
  }

  getTeamRecipient(teamUsers: Array<TeamUserModel>): TeamUserModel {
    return teamUsers.find(teamUser => {
      return teamUser.teamRoleId === this.teamConstants.RECIPIENT;
    });
  }

  sortTeamsByRoleThenCreated(userTeams: Array<UserTeamsModel>, teams: Array<TeamModel>): Observable<TeamModel[]> {
    if (userTeams.length < 1 || teams.length < 1) {
      return observableOf(new Array<TeamModel>());
    }
    let sortedTeams = new Array<TeamModel>();

    const adminTeams = new Array<TeamModel>();
    const adminUserTeams = userTeams.filter(userTeam => {
      if (userTeam.teamRoleId === this.teamConstants.ADMIN) {
        return userTeam;
      }
    });
    for (const userTeam of adminUserTeams) {
      adminTeams.push(teams.find(function (team) {
        return team.id.toLocaleLowerCase() === userTeam.teamId.toLocaleLowerCase();
      }));
    }
    sortedTeams = sortedTeams.concat(adminTeams.sort(function (a, b) {
      return new Date(a.created).getTime() - new Date(b.created).getTime();
    }));

    const recipientTeams = new Array<TeamModel>();
    const recipientUserTeams = userTeams.filter(userTeam => {
      if (userTeam.teamRoleId === this.teamConstants.RECIPIENT) {
        return userTeam;
      }
    });
    for (const userTeam of recipientUserTeams) {
      recipientTeams.push(teams.find(function (team) {
        return team.id.toLocaleLowerCase() === userTeam.teamId.toLocaleLowerCase();
      }));
    }
    sortedTeams = sortedTeams.concat(recipientTeams.sort(function (a, b) {
      return new Date(a.created).getTime() - new Date(b.created).getTime();
    }));

    const contributorTeams = new Array<TeamModel>();
    const contributorUserTeams = userTeams.filter(userTeam => {
      if (userTeam.teamRoleId === this.teamConstants.CONTRIBUTOR) {
        return userTeam;
      }
    });
    for (const userTeam of contributorUserTeams) {
      contributorTeams.push(teams.find(function (team) {
        return team.id.toLocaleLowerCase() === userTeam.teamId.toLocaleLowerCase();
      }));
    }
    sortedTeams = sortedTeams.concat(contributorTeams.sort(function (a, b) {
      return new Date(a.created).getTime() - new Date(b.created).getTime();
    }));

    const coachTeams = new Array<TeamModel>();
    const coachUserTeams = userTeams.filter(userTeam => {
      if (userTeam.teamRoleId === this.teamConstants.COACH) {
        return userTeam;
      }
    });
    for (const userTeam of coachUserTeams) {
      coachTeams.push(teams.find(function (team) {
        return team.id.toLocaleLowerCase() === userTeam.teamId.toLocaleLowerCase();
      }));
    }
    sortedTeams = sortedTeams.concat(coachTeams.sort(function (a, b) {
      return new Date(a.created).getTime() - new Date(b.created).getTime();
    }));

    return observableOf(sortedTeams);
  }

  // messages
  transformMessages(items: any) {
    for (const item of items) {
      if (item.senderUserId === this.userConstants.SYSTEM_USER_ID) {
        item.senderName = SiteParameters.MESSAGE_SYSTEM.NAME;
        item.senderAvatar = SiteParameters.MESSAGE_SYSTEM.AVATAR;
      }
      if (item.created) {
        item.createdFromNow = moment(item.created).fromNow()
      }
    }
    return items;
  }

  // calendar
  getUserParticipants(event: EventModel, userId: string, role?: number, status?: string): Participant[] {
    let participants = event.participants.filter(participant => {
      return participant.userId === userId;
    });
    if (role) {
      participants = participants.filter(participant => {
        return participant.role === role;
      });
    }
    if (status) {
      participants = participants.filter(participant => {
        switch (status) {
          case 'undelivered': {
            if (!participant.delivered) {
              return true;
            }
            break;
          }
          case 'delivered': {
            if (participant.delivered) {
              return true;
            }
            break;
          }
          case 'unread': {
            if (!participant.read) {
              return true;
            }
            break;
          }
          case 'read': {
            if (participant.read) {
              return true;
            }
            break;
          }
          case 'unarchived': {
            if (!participant.archived) {
              return true;
            }
            break;
          }
          case 'archived': {
            if (participant.archived) {
              return true;
            }
            break;
          }
          case 'unaccepted': {
            if (!participant.accepted) {
              return true;
            }
            break;
          }
          case 'accepted': {
            if (participant.accepted) {
              return true;
            }
            break;
          }
          case 'undeclined': {
            if (!participant.declined) {
              return true;
            }
            break;
          }
          case 'declined': {
            if (participant.declined) {
              return true;
            }
            break;
          }
          case 'undeleted': {
            if (!participant.deleted) {
              return true;
            }
            break;
          }
          case 'deleted': {
            if (participant.deleted) {
              return true;
            }
            break;
          }
          default: {
            return false;
          }
        }
      });
    }
    return participants;
  }

  // time spent
  getTimeSpentDisplay(timeSpent: number) {
    if (timeSpent === 0) {
      return '0:00';
    }
    let hours = 0;
    let min = 0;
    if (timeSpent > 59) {
      hours = Number.parseInt((timeSpent / 60).toString());
    }
    if (timeSpent - (hours * 60) > 0) {
      min = (timeSpent - (hours * 60));
    }
    return min < 10 ? hours + ':0' + min : hours + ':' + min;
  }

  // new function specific for lib used
  getTimeSpentDisplayDP(timeSpent: number) {
    if (timeSpent === 0) {
      return 'PT0H0M';
    }
    let hours = 0;
    let min = 0;
    if (timeSpent > 59) {
      hours = Number.parseInt((timeSpent / 60).toString());
    }
    if (timeSpent - (hours * 60) > 0) {
      min = (timeSpent - (hours * 60));
    }
    return `PT${hours}H${min}M`;
  }

  getTimeSpentDisplayHint(timeSpent: number) {
    let hours = 0;
    let min = 0;
    if (timeSpent > 59) {
      hours = Number.parseInt((timeSpent / 60).toString());
    }
    if (timeSpent - (hours * 60) > 0) {
      min = (timeSpent - (hours * 60));
    }
    let timeSpentDisplayHint = '';
    if (hours > 0) {
      timeSpentDisplayHint = timeSpentDisplayHint + hours + (hours > 1 ? ' hrs' : ' hour');
    }
    if (min > 0) {
      timeSpentDisplayHint = timeSpentDisplayHint + ' ' + min + (min > 1 ? ' minutes' : ' minute');
    }
    return timeSpentDisplayHint;
  }

  // calendar notes
  getNoteCreatedByDisplay(note: EventNote, teamUsers: Array<TeamUserModel>) {
    const owner = this.getUserTeamUser(note.ownerId, teamUsers).user;
    return owner.displayName + ', ' + this.formatDate(note.created, 'MM/DD/YY h:mm A');
  }

  // media
  setupMediaData(media: Media, teamUsers: TeamUserModel[]): Media {
    for (let mediaItem of media.items) {
      mediaItem =  this.setupMediaItemData(mediaItem, teamUsers);
    }
    return media;
  }

  setupMediaItemData(mediaItem: MediaItem, teamUsers: TeamUserModel[]): MediaItem {
    if (mediaItem.type === this.mediaConstants.VIDEO && !mediaItem.thumbnailUrl) {
      mediaItem.thumbnailUrl = environment.dashboardBaseUrl + this.mediaConstants.DEFAULT_VIDEO_THUMBNAIL;
    }
    mediaItem.ownerName = this.getUserTeamUser(mediaItem.ownerId, teamUsers).user.displayName;
    // empty strings to simplify search
    if (!mediaItem.title) {
      mediaItem.title = '';
    }
    if (!mediaItem.summary) {
      mediaItem.summary = '';
    }
    return mediaItem;
  }

  // safekeeper
  getFileTypeImgUrl(mediaType: string): string {
    switch (mediaType) {
      case 'application/pdf': {
        return '/assets/img/pdf.png';
      }
      default: {
        if (mediaType.includes('office') && mediaType.includes('word')) {
          return '/assets/img/word.png';
        }
        if (mediaType.startsWith('image')) {
          return '/assets/img/image.png';
        }
        return '';
      }
    }
  }

  getCurrentTimeZone(): string {
    return moment.tz.guess();
  }

  downloadBlob(file: Blob, name: string) {
    const url = window.URL.createObjectURL(file);
    const link = document.createElement("a");
    link.download = name;
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // local utils
  formatDate(date: Date, format: string) {
    return moment(date).format(format);
  }

  trim(str, len) {
    let result = str.substring(0, len);
    if (str.length > len) {
      result += ' ...';
    }
    return result;
  }

  getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  showNotification(from: any, align: any, type: any, allow_dismiss: boolean, url: string, target: string, message: string) {
    let delay = 5000;
    let timer = 1000;
    if (!allow_dismiss) {
      delay = 0; timer = 0;
    }
    $.notify(
      {icon: 'notifications', message: message, url: url, target: target},
      {type: type, delay: delay, timer: timer, allow_dismiss: allow_dismiss, placement: { from: from, align: align }}
    );
  }

  utcToLocal = (date: Date): Date => moment.utc(date).local().toDate()
}
