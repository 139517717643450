import { UserService } from './../../providers/user.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Logger } from './../../providers/logger.service';
import { HttpErrorResponse } from '@angular/common/http';

import { SiteParameters } from './../../environments/site.default';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: [`./confirmation.component${SiteParameters.SITE_PREFIX}.scss`]
})
export class ConfirmationComponent implements OnInit {

    success = false;
    failure = false;
    token: string;
    private source = 'ConfirmationComponent';

    constructor(
        private route: ActivatedRoute,
        private userService: UserService,
        private logger: Logger
    ) {
    }

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.token = params['token'];
            this.emailConfirmation();
        });
    }

    emailConfirmation() {
        const result = this.userService.emailConfirmation(this.token);
        result.subscribe({
            next: data => {
                if (data.valid) {
                    this.success = true;
                } else {
                    this.failure = true;
                }
            },
            error: err => {
                this.logger.logError(this.source, 'emailConfirmation', err, 'API Error');
                this.failure = true;
            }
        });
    }
}
