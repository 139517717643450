import { Component } from '@angular/core';
import {Router} from '@angular/router';
import { AuthService } from 'providers/auth.service';

@Component({
  selector: 'app-system-manager-layout',
  templateUrl: './system-manager-layout.component.html',
  styleUrls: ['./system-manager-layout.component.scss']
})
export class SystemManagerLayoutComponent {

  constructor(
    private router: Router,
    private authService: AuthService,
  ) { }

  logout() {
    this.authService.signOut()
      .subscribe(() => {
        this.router.navigate(['login']);
      });
  }
}
