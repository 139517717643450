export class SafeKeeperFolderModel {
    public id: string;
    public teamId: string;
    public name: string;
    public isUserDefined?: boolean;
}

export class DeleteSafeKeeperFolderModel extends SafeKeeperFolderModel {
    public keepNotes: boolean;
}

export class SafeKeeperTabModel extends SafeKeeperFolderModel {
    public tabType: string;
    public cardComponent: string;
    public role: number;
}

export class UpdateTabPermissionModel {
    public userId: string;
    public teamId: string;
    public tabId: string;
    public role: number;
}

export class SafeKeeperUserTabModel extends UpdateTabPermissionModel {
    public name: string;
    public user: string;
    public tabType: string;
}

