import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from 'providers/auth.service';
import { Router } from '@angular/router';
import { LocalData } from '../../providers/local-data.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = new BehaviorSubject(false);
  private currentUrl = '/';

  constructor(private authService: AuthService, private router: Router, private localData: LocalData) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this.localData.setLastHttpRequestDate()
    if (!req.headers.has('withCredentials')) {
      req = req.clone({
        withCredentials: true,
      });
    }
    return next.handle(req).pipe(
      catchError((err) => {
        if (
          err instanceof HttpErrorResponse &&
          err.status === 401 &&
          !this.elNoRefresh(req)
        ) {
          return this.handle401Error(req, next);
        }
        return throwError(err);
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    if (!this.isRefreshing.value) {
      this.isRefreshing.next(true);
      this.currentUrl = this.router.url;

      return this.authService.refreshToken().pipe(
        switchMap(() => {
          this.isRefreshing.next(false);
          return next.handle(request);
        }),
        catchError((err) => {
          this.isRefreshing.next(false);
          if (err.status === 401) {
            this.logoutWithReturnUrl();
          }
          return throwError(err);
        })
      );
    }

    return this.isRefreshing.pipe(
      filter((refreshing) => !refreshing),
      take(1),
      switchMap((_) => next.handle(request))
    );
  }

  private logoutWithReturnUrl() {
    this.authService.signOut().subscribe(() => {
      if (this.currentUrl.includes('login')) {
        this.router.navigate([this.currentUrl]);
      } else {
        this.router.navigate(['/login'], {
          queryParams: { return_url: this.currentUrl },
        });
      }
    });
  }

  private elNoRefresh(req: HttpRequest<unknown>): boolean {
    return (
      req.headers.has('elNoRefresh') && req.headers.get('elNoRefresh') === 'yes'
    );
  }
}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
];
