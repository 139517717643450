import {Component, Inject, Input, OnInit} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

// rxjs
import { mergeMap } from 'rxjs/operators';

// models
import {UserModel, UserRoles} from 'models/user.model';

// providers
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from 'providers/user.service';
import {Observable} from 'rxjs/Observable';

export interface RoleMapItem {
  code: number;
  name: string;
}

export interface DialogData {
  title: string;
  allowableRoles: RoleMapItem[];
  inviteFn: (user: UserModel) => Observable<UserModel>;
  defaultRole?: number;
}

@Component({
  selector: 'app-invite-role-modal',
  templateUrl: './invite-role-modal.component.html',
  styleUrls: ['./invite-role-modal.component.scss']
})
export class InviteRoleModalComponent implements OnInit {
  public userForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<InviteRoleModalComponent, UserModel>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public fb: FormBuilder,
    public userService: UserService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.userForm = this.fb.group({
      displayName: ['', [Validators.required, Validators.minLength(2)]],
      email: ['', [Validators.required, Validators.email], [this.userService.emailExistsValidator()]],
      role: [this.data.defaultRole, [Validators.required]]
    });
  }

  onSubmit() {
    if (this.userForm.valid) {
      const email = this.userForm.get('email').value;
      const displayName = this.userForm.get('displayName').value;
      const role = this.userForm.get('role').value;
      const roleName = this.data.allowableRoles
        .find(r => r.code === role)?.name;
      this.userService.getUserSelf()
        .pipe(
          mergeMap(userSelf => {
            const user = <UserModel>{
              email,
              displayName,
              role,
              invitedBy: userSelf.email
            }
            return this.data.inviteFn(user)
          })
        )
        .subscribe((user) => {
            this.showSnackBar(`${roleName} successfully invited`);
            this.dialogRef.close(user);
          },
          (err) => {
            this.showSnackBar(`Error inviting ${roleName}`);
          });
    }
  }

  showSnackBar(message) {
    this.snackBar.open(message, 'close', {
      duration: 2000,
    });
  }
}
