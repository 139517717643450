import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatNativeDateModule  } from '@angular/material/core';
import { VerificationModule } from './verification/verification.module';

// 3rd party
import { DeviceDetectorModule } from 'ngx-device-detector';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';

// components
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './shared/footer/footer.module';
import { NavbarModule} from './shared/navbar/navbar.module';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';

// providers
import { AuthGuard } from 'guards/auth.guard';
import { AuthService } from './../providers/auth.service';
import { UserService } from './../providers/user.service';
import { TeamService } from './../providers/team.service';
import { CalendarService } from './../providers/calendar.service';
import { MessageService } from './../providers/message.service';
import { SafeKeeperService } from './../providers/safekeeper.service';
import { MediaService } from './../providers/media.service';
import { DailyAssessmentService } from './../providers/daily-assessment.service';
import { Utils } from './../providers/utils.service';
import { LocalData } from './../providers/local-data.service';
import { Logger } from './../providers/logger.service';
import {TermsAndConditionsService} from './../providers/tac.service'
import { environment } from '../environments/environment';

// routes
import { AppRoutes } from './app.routing';
import { PrivacyComponent } from './privacy/privacy.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { CoachService } from 'providers/coach.service';
import { UserLayoutComponent } from './layouts/user-layout/user-layout.component';
import { CoachLayoutComponent } from './layouts/coach-layout/coach-layout.component';
import { SystemManagerLayoutComponent } from './layouts/system-manager-layout/system-manager-layout.component';
import { ClientManagerLayoutComponent } from './layouts/client-manager-layout/client-manager-layout.component';
import {NgxMaskModule} from 'ngx-mask';
import { HubsService } from 'providers/hubs.service';
import { ClientsService } from 'providers/clients.service';
import { authInterceptorProviders } from './interceptors/auth.interceptor';
import { MaterialModule } from './material.module';
import { SharedModule } from './shared/shared.module';
import { UpdatePasswordRotationComponent } from './login/update-password-rotation/update-password-rotation.component';
import { NgIdleModule } from '@ng-idle/core';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ActivitiesService } from 'providers/activities.service';

@NgModule({
  imports: [
    CommonModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    BrowserAnimationsModule,
    FormsModule,
    RouterModule.forRoot(AppRoutes, { relativeLinkResolution: 'legacy' }),
    HttpClientModule,
    MaterialModule,
    MatNativeDateModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    DeviceDetectorModule.forRoot(),
    NgxMaskModule.forRoot(),
    VerificationModule,
    SharedModule,
    ReactiveFormsModule,
    NgIdleModule.forRoot(),
    NgIdleKeepaliveModule.forRoot()
  ],
    declarations: [
        AppComponent,
        LoginComponent,
        AdminLayoutComponent,
        AuthLayoutComponent,
        PrivacyComponent,
        ConfirmationComponent,
        UserLayoutComponent,
        CoachLayoutComponent,
        SystemManagerLayoutComponent,
      ClientManagerLayoutComponent,
      UpdatePasswordRotationComponent
   ],
    providers: [
      AuthGuard, AuthService,
      UserService, TeamService, CalendarService, MessageService, MediaService, SafeKeeperService, DailyAssessmentService,
      Utils, LocalData, Logger, TermsAndConditionsService, CoachService, HubsService, ClientsService, ActivitiesService,
      ...authInterceptorProviders,
      {
        provide: RECAPTCHA_SETTINGS,
        useValue: { siteKey: `${environment.recaptchaConfig.siteKey}` } as RecaptchaSettings,
      },
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule {
  constructor() { }
}
