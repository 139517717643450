<div class="main-content loading" *ngIf="isLoading">
  <div class="container-fluid">
      <h3>Loading...</h3>
  </div>
</div>
<div class="main-content" *ngIf="!isLoading && user">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-10 col-md-offset-1">
        <div class="row">
          <div class="col-xs-12 mb-3">
            <p class="h4 text-center px-1">Your Care Coach is ready to advise you through each phase of your caregiving journey.</p>
          </div>
          <div class="col-xs-12">
            <div class="flex">
              <img class="img-circle" [src]="user.avatarUrl">
              <div class="px-1">
                <h3 class="h3">{{user.firstName}} {{user.lastName}}</h3>
                <p class="coach-info">
                  {{user.bio}}
                </p>
                <div class="coach-action">
                  <button class="btn btn-primary mr-3" (click)="goToMessages()">Go to Messages</button>
                  <button class="btn btn-accent mr-3" (click)="goToMessages(true)">New Message</button>
                  <button class="btn btn-secondary" (click)="callCoach()">{{user.cellPhone}}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="main-content" *ngIf="!isLoading && !user">
  <div class="row coaching-message">
    <div class="col-xs-12">
      <img src="assets/img/care-coach.jpg">
      <h2>Your coach will contact you soon.</h2>
      <button mat-button color="primary" (click)="onGoHomeClicked()">Go Home</button>
    </div>
  </div>
</div>