import { Injectable } from '@angular/core';
import * as signalR from "@aspnet/signalr";
import { environment } from 'environments/environment';
import { LocalData } from './local-data.service';

@Injectable({
  providedIn: 'root'
})
export class HubsService {

  constructor(private localData: LocalData) { }

  public startConnection(hubName: string, onStart: (conn: signalR.HubConnection) => void): void {
    const startConnectionRecursive = () => {
      const hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${environment.hubsBaseUrl}/${hubName}`)
        .configureLogging(signalR.LogLevel.None)
        .build();
      hubConnection.onclose((error) => {
        if (error) {
          setTimeout(startConnectionRecursive, 1000);
        }
      })
      hubConnection
        .start()
        .then(() => {
          onStart(hubConnection);
        })
        .catch(err => {
          console.log('Error while starting connection: ' + err)
          setTimeout(startConnectionRecursive, 2000);
        });
    }
    startConnectionRecursive();
  }
}
