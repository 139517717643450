import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserConstants, UserModel } from '../../../models/user.model';
import { UserService } from '../../../providers/user.service';
import { Utils } from '../../../providers/utils.service';
import { environment } from '../../../environments/environment';
import { LocalData } from 'providers/local-data.service';

@Component({
  selector: 'app-coach-info',
  templateUrl: './coach-info.component.html',
  styleUrls: ['./coach-info.component.scss']
})
export class CoachInfoComponent implements OnInit {
  defaultAvatarImg: string;
  public userConstants: UserConstants = new UserConstants();
  public user: UserModel;
  private sub: Subscription;
  public isLoading = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private utils: Utils,
    private router: Router,
    private localData: LocalData
  ) { }

  ngOnInit(): void {
    this.utils.pageTitleSource.next('Coach Info');
    this.defaultAvatarImg = environment.dashboardBaseUrl + this.userConstants.DEFAULT_AVATAR;

    this.isLoading = true;
    this.sub = this.activatedRoute.paramMap.subscribe(params => {
      if(params.get('id') === 'pending') {
        this.isLoading = false;
        return;
      }
      this.userService.getUser(params.get('id')).subscribe(user => {
        if (!user.avatarUrl) {
          user.avatarUrl = this.defaultAvatarImg;
        }
        this.user = user;
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
      });
    });
  }

  goToMessages(newMessage: boolean = false) {
    this.router.navigateByUrl('/chat').then(() => {
      if(newMessage) {
        $('#new-chat-room-modal').modal('show');
      }
    });
  }

  callCoach() {
    window.open(`tel:${this.user.cellPhone}`, '_self');
  }

  onGoHomeClicked() {
    if (this.localData.getCurrentTeamId()) {
      this.router.navigate(['/team', this.localData.getCurrentTeamId()])
      return;
    }
    this.router.navigateByUrl('/');
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
