import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'environments/environment';

// rxjs
import { Observable, Subject, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";

// models
import { ChatRoom } from "models/chat-room.model";
import { MessageAttachment, MessageModel } from "./../models/message.model";

// providers
import { Utils } from './utils.service';
import { Logger } from './logger.service';
import * as moment from "moment";
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';

@Injectable({
  providedIn: "root",
})
export class ChatService {
  private source = "ChatService";
  private apiBaseUrl = environment.apiBaseUrl;
  public selectChatRoomSource = new Subject<{id: string, from: 'inbox' | 'archived'}>();
  public newMessageSentSource = new Subject<MessageModel>();
  public newChatRoomCreatedSource = new Subject<ChatRoom>();
  public activeTabSource = new Subject<string>();
  public deleteChatRoomSource = new Subject<string>();

  constructor(
    private http: HttpClient,
    private logger: Logger,
    private utils: Utils) { }

  // TODO: Make start optional
  getChatRooms(userId: string, teamId: string | null, start: Date, count: number, status: string): Observable<ChatRoom[]> {
    this.logger.logInfo(this.source, 'getChatRooms', 'API get /users/:id/chatrooms?start=start&count=count&status=' + status);
    const startParam = this.utils.setDateTimeQueryParam(start, 'MM/DD/YYYY HH:mm:ss');
    const params = new HttpParams({
      fromObject: {
        start: startParam,
        count: String(count),
        status,
        teamId
      }
    })
    const options = this.utils.getJsonHttpOptionsWithParams(null, params);
    return this.http.get(this.apiBaseUrl + '/users/' + userId + '/chatrooms', options).pipe(
      map((res: HttpResponse<ChatRoom[]>) => {
        this.logger.logDebug(this.source, 'getChatRooms', { status: res.status, statusText: res.statusText, userId, teamId }, 'API get /users/:id/chatrooms?&status=' + status);
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getChatRooms', err, 'API Error');
        return throwError(err);
      }),);
  }

  getChatRoom(chatRoomId: string): Observable<ChatRoom> {
    this.logger.logInfo(this.source, 'getChatRoom', 'API get /chatrooms/:id' + status);
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/chatrooms/' + chatRoomId, options).pipe(
      map((res: HttpResponse<ChatRoom>) => {
        this.logger.logDebug(this.source, 'getChatRoom', { status: res.status, statusText: res.statusText, chatRoomId }, 'API get /chatrooms/:id');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'getChatRoom', err, 'API Error');
        return throwError(err);
      }),);
  }

  createChatRoom(chatRoom: ChatRoom): Observable<ChatRoom> {
    this.logger.logInfo(this.source, 'createChatRoom', 'API post /chatrooms');
    const body = chatRoom
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/chatrooms/', body, options).pipe(
      map((res: HttpResponse<ChatRoom>) => {
        this.logger.logDebug(this.source, 'createChatRoom', { status: res.status, statusText: res.statusText, chatRoomId: chatRoom.id }, 'API post /chatrooms');
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'createChatRoom', err, 'API Error');
        return throwError(err);
      }),);
  }

  getMessages(chatRoomId: string, userId: string, start: Date | null, count: number, status: string): Observable<MessageModel[]> {
    const func = 'getMessages';
    const call = 'API get /chatrooms/:id/messages' + status;
    if (!status) {
      this.logger.logInfo(this.source, func, call);
    }
    let params = new HttpParams({
      fromObject: {
        count: String(count),
        status,
        user: userId,
      }
    });

    if (start) {
      const startParam = this.utils.setDateTimeQueryParam(start, 'MM/DD/YYYY HH:mm:ss');
      params = params.set('start', startParam);
    }
    const options = this.utils.getJsonHttpOptionsWithParams(null, params);
    return this.http.get(this.apiBaseUrl + '/chatrooms/' + chatRoomId + '/messages', options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText }, call);
        return this.utils.transformMessages(this.utils.adjustTimesForArray(res.body));
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return throwError(err);
      }),);
  }

  createMessage(chatRoomId: string, message: MessageModel): Observable<MessageModel> {
    const func = 'createMessage';
    const call = 'API post /chatrooms/:id/messages';
    this.logger.logInfo(this.source, func, call);
    message.chatRoomId = chatRoomId;
    const body = message;
    message.isSystem = false;
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/chatrooms/' + chatRoomId + '/messages', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, chatRoomId }, call);

        const message: MessageModel = this.utils.adjustTimes(res.body);
        message.messageId = res.body.id;
        if (message.created) {
          message.createdFromNow = moment(message.created).fromNow()
        }
        return message;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return throwError(err);
      }),);
  }

  selectChatRoom(chatRoom: ChatRoom, from: 'inbox' | 'archived'): void {
    this.selectChatRoomSource.next({
      id: chatRoom.id,
      from: from
    });
  }

  archiveChatRoom(chatRoomId: string, archive: boolean = true) {
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(`${this.apiBaseUrl}/chatrooms/${chatRoomId}/archive?archived=${archive}`, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'archiveChatRoom', { status: res.status, statusText: res.statusText, chatRoomId }, `API get /chatrooms/${chatRoomId}/archive?archived=${archive}`);
        return res.body;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  deleteChatRoom(chatRoomId: string) {
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(`${this.apiBaseUrl}/chatrooms/${chatRoomId}/hide`, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'deleteChatRoom', { status: res.status, statusText: res.statusText, chatRoomId }, `API get /chatrooms/${chatRoomId}/hide`);
        return res.body;
      }),
      catchError((err: any) => {
        return throwError(err);
      })
    );
  }

  uploadAttachment(messageId: string, attachmentFile: File, index: number) {
    const call = 'API POST /chatrooms/:id/attachment';
    this.logger.logInfo(this.source, 'uploadAttachment', call);
    const body = new FormData();
    body.append('file', attachmentFile);
    const options = this.utils.getJsonHttpOptions()
    return this.http.post(`${this.apiBaseUrl}/chatrooms/${messageId}/attachment?tempId=${index}`, body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'uploadAttachment', { status: res.status, statusText: res.statusText, messageId }, call);
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'uploadAttachment', err, 'API Error');
        return throwError(err);
      }),);
  }

  getMessageAttachmentFile(attachment: MessageAttachment): Observable<Blob> {
    const func = 'getMessageAttachmentFile';
    const call = 'API GET /chatrooms/attachments/:id/download';
    this.logger.logInfo(this.source, func, call);
    let options : { responseType: 'blob', observe: 'response'};
    options = {responseType: 'blob', observe: 'response'};
    return this.http.get(`${this.apiBaseUrl}/chatrooms/attachments/${attachment.id}/download`, options).pipe(
      map((res: HttpResponse<Blob>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText }, call);
        return new Blob([res.body], { type: attachment.mediaType});
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return throwError(err);
      }),);
  }

  getUnreadCount(): Observable<any> {
    const func = 'getUserUnreadMessagesCounter';
    const call = 'API get chatrooms/countUnread';
    this.logger.logInfo(this.source, func, call);
    let apiUrl = this.apiBaseUrl + '/chatrooms/countUnread';
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(apiUrl, options).pipe(
      map((res: HttpResponse<any>) => {
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return throwError(err);
      })
    );
  }

  CanEditAnyMessages = (): Observable<boolean> => {
    const func = 'CanEditAnyMessages'
    const call = 'API get /chatrooms/can-edit-any-messages/';
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(`${this.apiBaseUrl}/chatrooms/can-edit-any-messages/`,options)
      .pipe(
        map((res: HttpResponse<any>) => {
          this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText }, call);
          return res.body
        }),
        catchError((err: any) => {
          this.logger.logError(this.source, func, err, 'API Error');
          return observableThrowError(err);
        }),);
  }

  canViewMessages = (teamId: string, userId: string): Observable<boolean> => {
    const func = 'canViewMessages'
    const call = 'API get /chatrooms/team/:teamId/can-view-messages/:userId';
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(`${this.apiBaseUrl}/chatrooms/team/${teamId}/can-view-messages/${userId}`,options)
      .pipe(
        map((res: HttpResponse<any>) => {
          this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, teamId, userId }, call);
          return res.body
        }),
        catchError((err: any) => {
          this.logger.logError(this.source, func, err, 'API Error');
          return observableThrowError(err);
        }),);
  }

  canEditMessages(teamId: string) {
    this.logger.logInfo(
      this.source,
      'canEditMessages',
      'API get /team/:id/can-edit-messages'
    );
    const options = this.utils.getJsonHttpOptions();
    return this.http
      .get(
        `${this.apiBaseUrl}/chatrooms/team/${teamId}/can-edit-messages`,
        options
      )
      .pipe(
        map((res: HttpResponse<any>) => {
          this.logger.logDebug(
            this.source,
            'canEditMessages',
            { status: res.status, statusText: res.statusText, teamId },
            'API get /team/:id/can-edit-messages'
          );
          return res.body;
        }),
        catchError((err: any) => {
          this.logger.logError(
            this.source,
            'canEditMessages',
            err,
            'API Error'
          );
          return observableThrowError(err);
        })
      );
  }
}
