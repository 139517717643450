<div class="wrapper wrapper-full-page">
  <nav class="navbar navbar-fixed-top navbar-bh navbar-bh-login">
    <div class="container">
      <div class="bh-logo">
        <a [href]="landingPageUrl">
          <img src="assets/img/logo_BH-horizontal.png" alt="Logo">
        </a>
        <div class="service-labels">
          <span class="service-label">
            Senior Services & Support
          </span>
          <span class="service-label">
            Coaching & Planning through Elder Care
          </span>
        </div>
      </div>
    </div>
  </nav>
  <div class="full-page login-page" filter-color="brighthorizons">
    <div class="content">
      <div class="container">

        <div [ngSwitch]="display">

          <ng-template [ngSwitchCase]="'changepass'">
            <div class="row">
              <div class="col-sm-6 col-sm-offset-3">
                <form name="form-changepass" (ngSubmit)="f2.form.valid && changePassword()" #f2="ngForm" novalidate
                  appMatchPasswords [password1]='"password"' [password2]='"password2"'>
                  <div class="card card-login">
                    <div class="card-header text-center" data-background-color="brighthorizons">
                      <h4 class="card-title">Change Password</h4>
                    </div>
                    <div class="card-content">
                      <div class="input-group">
                        <span class="input-group-addon">
                          <i class="material-icons">lock_outline</i>
                        </span>
                        <mat-form-field hideRequiredMarker="true" appStretchMatErrors>
                          <mat-placeholder>Password</mat-placeholder>
                          <input matInput [type]="showPassword ? 'text' : 'password'" name="password"
                            [(ngModel)]="model.password1" #password="ngModel" appPasswordValidator>
                          <mat-icon style='cursor: pointer' matSuffix
                            (click)="showPassword = !showPassword">{{showPassword ? 'visibility_off' :
                            'visibility'}}</mat-icon>
                          <mat-error #matError *ngFor="let error of password?.errors | keyvalue">
                            {{ error.value['msg'] }}
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div class="input-group">
                        <span class="input-group-addon">
                          <i class="material-icons">lock_outline</i>
                        </span>
                        <mat-form-field hideRequiredMarker="true">
                          <mat-placeholder>Again please...</mat-placeholder>
                          <input matInput [type]="showPassword ? 'text' : 'password'" name="password2"
                            [(ngModel)]="model.password2" #password2="ngModel">
                          <mat-icon style='cursor: pointer' matSuffix
                            (click)="showPassword = !showPassword">{{showPassword ? 'visibility_off' :
                            'visibility'}}</mat-icon>
                          <mat-error>Those passwords didn't match. Try again</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="footer text-center">
                      <span *ngIf="formChangePasswordErrMsg" class="text-danger">{{ formChangePasswordErrMsg }}
                        <br>
                      </span>

                      <button mat-button color="primary" [disabled]="disableForm || !f2.valid">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </ng-template>

          <ng-template [ngSwitchCase]="'register'">
            <div class="row">
              <div class="col-sm-6 col-sm-offset-3">
                <form name="form-register" (ngSubmit)="f3.form.valid && register()" #f3="ngForm" novalidate>
                  <div class="card card-login">
                    <div class="card-header text-center" data-background-color="brighthorizons">
                      <h4 class="card-title">Sign Up</h4>
                    </div>
                    <div class="card-content">
                      <div class="input-group">
                        <span class="input-group-addon">
                          <i class="material-icons">email</i>
                        </span>
                        <div class="form-group label-floating"
                          [ngClass]="{ 'has-error': f3.submitted && !email.valid }">
                          <label class="control-label">Email Address</label>
                          <input matInput type="email" class="form-control" name="email" [(ngModel)]="model.email"
                            #email="ngModel" required maxlength="100" email>
                        </div>
                      </div>
                      <div class="input-group">
                        <span class="input-group-addon">
                          <i class="material-icons">person</i>
                        </span>
                        <div class="form-group label-floating"
                          [ngClass]="{ 'has-error': f3.submitted && !displayName.valid }">
                          <label class="control-label">Full Name</label>
                          <input matInput type="text" class="form-control" name="displayName"
                            [(ngModel)]="model.displayName" #displayName="ngModel" required maxlength="250">
                        </div>
                      </div>
                      <div class="input-group">
                        <span class="input-group-addon">
                          <i class="material-icons">lock_outline</i>
                        </span>
                        <div class="form-group label-floating"
                          [ngClass]="{ 'has-error': f3.submitted && !password.valid }">
                          <label class="control-label">Create A New Password</label>
                          <input matInput type="password" class="form-control" name="password"
                            [(ngModel)]="model.password" #password="ngModel" required minlength="8" maxlength="100">
                        </div>
                      </div>
                      <div class="input-group">
                        <span class="input-group-addon">
                          <i class="material-icons">star_border</i>
                        </span>
                        <div class="form-group label-floating"
                          [ngClass]="{ 'has-error': f3.submitted && !promoCode.valid }">
                          <label class="control-label">Promotion Code (Optional)</label>
                          <input matInput type="text" class="form-control" name="promoCode"
                            [(ngModel)]="model.promoCode" #promoCode="ngModel" maxlength="100">
                        </div>
                      </div>
                    </div>
                    <div class="footer text-center">
                      <span *ngIf="formErrMsg" class="text-danger">{{ formErrMsg }}
                        <br>
                      </span>
                      <button mat-button color="primary" [disabled]="disableForm">Submit</button>
                      <br>
                      <span class="text-forgotpass" (click)="backToLogin()">Go back to login.</span>
                      <br>
                      <span class="need-help-text" (click)="showContactInfo()">Need Help?</span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </ng-template>

          <ng-template [ngSwitchCase]="'forgotpass'">
            <div class="row">
              <div class="col-sm-6 col-sm-offset-3">
                <form name="form-forgotpass" (ngSubmit)="f4.form.valid && forgotPassword()" #f4="ngForm" novalidate>
                  <div class="card card-login">
                    <div class="card-header text-center" data-background-color="brighthorizons">
                      <h4 class="card-title">Reset Password</h4>
                    </div>
                    <div class="card-content">
                      <div class="input-group">
                        <span class="input-group-addon">
                          <i class="material-icons">email</i>
                        </span>
                        <div class="form-group label-floating"
                          [ngClass]="{ 'has-error': f4.submitted && !email.valid }">
                          <label class="control-label">Email Address</label>
                          <input matInput type="email" class="form-control" name="email" [(ngModel)]="model.email"
                            #email="ngModel" required maxlength="100" email>
                        </div>
                      </div>
                    </div>
                    <div class="footer footer-flex text-center">
                      <re-captcha [(ngModel)]="model.recaptchaToken" name="recaptcha" #recaptcha></re-captcha>
                      <span *ngIf="formErrMsg" class="text-danger">{{ formErrMsg }}
                        <br>
                      </span>
                      <button mat-button color="primary" [disabled]="disableForm">Submit</button>
                      <span style="color: #16233a;">Once you click SUBMIT we will email you a password
                        reset link.</span>
                      <span class="text-forgotpass" (click)="backToLogin()">Go back to login.</span>
                      <span class="need-help-text" (click)="showContactInfo()">Need Help?</span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </ng-template>

          <ng-template [ngSwitchCase]="'AcceptTOS'">

            <div class="row">
              <div class="col-md-8 col-md-offset-2">
                <div class="card walkthrough-card">
                  <div class="card-content">
                    <h4 class="card-title bh-txt-primary">Terms & Conditions</h4>
                    <div class="instruction">
                      <div class="row">
                        <div class="col-sm-12 text-left">
                          <div class="col-sm-12 text-left bh-txt-primary">
                            <mat-checkbox [checked]="acceptTermsOfUse" (change)="toggleTermsOfUse()"></mat-checkbox>
                            I accept the
                            <a target="_blank" [routerLink]="['/terms']"><span class="bh-terms-txt">eLivelihood Terms of
                                Use.</span></a>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-3">
                          <button mat-button type="button" color="primary" shape="round" [disabled]="!acceptTermsOfUse"
                            (click)="TosAccepted()" class="pull-right bh-accept-terms">
                            Continue <i class="material-icons">navigate_next </i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

          <ng-template [ngSwitchCase]="'invitedRecaptcha'">
            <re-captcha [(ngModel)]="model.recaptchaToken" name="recaptcha"
              (resolved)="handleInvitedRecaptchaResolved()" #recaptcha></re-captcha>
          </ng-template>
          <ng-template ngSwitchDefault>
            <div class="row">
              <div class="col-sm-6 col-sm-offset-3">
                <div class="card card-login">
                  <form name="form-login" (ngSubmit)="f1.form.valid && login()" #f1="ngForm" novalidate>
                    <div class="card-header text-center" data-background-color="brighthorizons">
                      <h4 class="card-title">Welcome</h4>
                    </div>
                    <div class="card-content">
                      <div class="input-group">
                        <span class="input-group-addon">
                          <i class="material-icons">email</i>
                        </span>
                        <div class="form-group label-floating"
                          [ngClass]="{ 'has-error': f1.submitted && !email.valid }">
                          <label class="control-label">Email Address</label>
                          <input matInput type="email" class="form-control" name="email" [(ngModel)]="model.email"
                            #email="ngModel" required maxlength="100" email>
                        </div>
                      </div>
                      <div class="input-group">
                        <span class="input-group-addon">
                          <i class="material-icons">lock_outline</i>
                        </span>
                        <div class="form-group label-floating"
                          [ngClass]="{ 'has-error': f1.submitted && !password.valid }">
                          <label class="control-label">Password</label>
                          <input matInput type="password" class="form-control" name="password"
                            [(ngModel)]="model.password" #password="ngModel" required minlength="8" maxlength="100">
                        </div>
                      </div>
                    </div>
                    <div class="footer footer-flex text-center">
                      <re-captcha [(ngModel)]="model.recaptchaToken" name="recaptcha" #recaptcha></re-captcha>
                      <span *ngIf="formErrMsg" class="text-danger">{{ formErrMsg }}
                        <br>
                      </span>
                      <span *ngIf="showLocked" class="text-danger" style="padding-left: 16px; padding-right: 16px; max-width: 500px;">
                        You have entered an incorrect username or password multiple times and your session has been
                        locked
                        for security reasons. Please try again in 30 minutes. If you feel this message is in error,
                        please email us at: <a href='mailto:support@elivelihood.com'
                          title='mailto:support@elivelihood.com'>support&#64;elivelihood.com</a>
                      </span>
                      <button mat-button color="primary" [disabled]="disableForm">Sign In</button>
                      <span class="text-forgotpass" (click)="showForgotPassword()">I forgot my password.</span>
                    </div>

                  </form>
                  <div class="footer text-center">
                    <p style="color: #16233a; margin-top: 20px;">Or sign up now for a new<br>eLivelihood account.</p>
                    <button mat-button color="secondary" (click)="goToRegister()">Sign Up</button>
                    <br>
                    <span class="need-help-text" (click)="showContactInfo()">Need Help?</span>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>

        </div>
      </div>
    </div>
    <section class="hills">
      <svg viewBox="0 0 1600 194" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 8v182h1151C843.9 97.4 445.5 32.3 0 8z" fill="#F0BD1B" />
        <path d="M1600 3v191H0v-4C411.5 72.6 969.2 4.7 1600 3z" fill="#1A475F" />
      </svg>
    </section>
    <footer class="footer page-footer">
      <div class="container">
        <div class="copyright">
          <div>
            <a href="https://www.elivelihood.com" target="_blank">eLivelihood Inc. {{year}}</a>
          </div>
          <div class="brands">
            <div class="brand">
              <img src="assets/img/iso-certified.png" />
            </div>
            <div class="brand">
              <img src="assets/img/soc-new-logo-blue.png" />
            </div>
            <div class="brand">
              <img src="assets/img/hipaa-compliant-white.png" />
            </div>
            <div class="brand">
              <img src="assets/img/gdpr-certified-new.png" />
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</div>
