import { ISelectData } from '../models/select-model';

export const PoliciesState: ISelectData[] = [
  {
    value: 3,
    label: "Enforced"
  },
  {
    value: 2,
    label: "Optional"
  },
  {
    value: 1,
    label: "Disabled"
  }
]

export const UserPoliciesState: ISelectData[] = [
  {
    value: 2,
    label: "Enabled"
  },
  {
    value: 1,
    label: "Disabled"
  }
]
