import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TeamConstants, TeamModel, TeamUserModel } from 'models/team.model';
import { TeamService } from 'providers/team.service';

export interface DialogData {
  team: TeamModel;
  member: TeamUserModel;
}

@Component({
  selector: 'app-remove-team-member-modal',
  templateUrl: './remove-team-member-modal.component.html',
  styleUrls: ['./remove-team-member-modal.component.scss']
})
export class RemoveTeamMemberModalComponent implements OnInit {
  public teamConstants = new TeamConstants();
  public form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<RemoveTeamMemberModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private teamService: TeamService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      altUserId: ['', Validators.required]
    });
  }

  get otherMembers() {
    return this.data.team.teamUsers.filter(tU => {
      return tU.teamRoleId !== this.teamConstants.RECIPIENT &&
        tU.userId !== this.data.member.userId; 
    })
  }

  onRemove() {
    if (this.form.valid) {
      const altUserId = this.form.get('altUserId').value;
      this.teamService.deleteTeamUser(this.data.team.id, this.data.member.userId, altUserId)
        .subscribe(_ => {
          const displayName = this.data.member.user.displayName;
          this.showSnackBar(`${displayName} has been removed from this team.`)
          this.dialogRef.close(true);
        },
        err => {
          this.showSnackBar('Something went wrong');
        });
    }
  }

  showSnackBar(message) {
    this.snackBar.open(message, 'close', {
      duration: 4000,
    });
  }
}
