import { SafeKeeperService } from 'providers/safekeeper.service';

import {mergeMap} from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, OnChanges, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';

// models
import { UserModel, UserConstants, UserRoles } from '../../../models/user.model';
import { TeamModel, TeamUserModel, TeamConstants, TeamPermissionsModel } from '../../../models/team.model';
import { languages } from 'constants/languages';

// providers
import { UserService } from '../../../providers/user.service';
import { TeamService } from '../../../providers/team.service';
import { DailyAssessmentService  } from '../../../providers/daily-assessment.service';
import { MessageService } from '../../../providers/message.service';
import { environment } from '../../../environments/environment';
import { Utils } from '../../../providers/utils.service';
import { LocalData } from '../../../providers/local-data.service';
import { Logger } from '../../../providers/logger.service';

import { SiteParameters } from '../../../environments/site.default';
import { allowedCountries } from 'constants/countries';
import { PoliciesState, UserPoliciesState } from '../../../constants/authentication-policy-state';
import { UserPoliciesModel } from '../../../models/user-policies.model';
import { MatDialog } from '@angular/material/dialog';
import { RecipientRemoveEmailComponent } from './recipient-remove-email/recipient-remove-email.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
declare const $: any;

const MY_DATE_FORMAT = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
}

@Component({
  selector: 'app-user-profile-cmp',
  templateUrl: 'profile.component.html',
  styleUrls: [`profile.component.scss`],
  providers:[
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMAT }
  ]
})

export class UserProfileComponent implements OnChanges, OnDestroy {
  private source = 'UserProfileComponent';
  public userConstants: UserConstants = new UserConstants();
  public teamConstants: TeamConstants = new TeamConstants();
  public authorized_user: UserModel;
  @Input() user: UserModel;
  @Input() userTypeDisplay: string;
  @Input() dobRequired: any;
  @Input() action: string;
  @Input() team: TeamModel;
  @Input() readOnly: boolean;
  @Input() canEdit: boolean;
  @Input() showCancelButton = false;
  @Output() updateStatus = new EventEmitter();
  @Output() done = new EventEmitter();
  @Output() canceled = new EventEmitter();
  @Output() editRecipient = new EventEmitter();
  private user_orig: UserModel;
  public dobStartDate = new Date('1970-01-31');
  public userIsTeamAdmin = false;
  public dobHidden = true;
  public disableForm = false;
  public formErrMsg: string = null;
  public defaultAvatarImg: string;
  public showAdditionalProfileData = true;
  public pending = false;
  public selectedResendUser: UserModel = new UserModel();
  public showAcceptTermsOfUse = false;
  public acceptTermsOfUse = true;

  public phoneType = "cell";
  public teamPrimaryLanguage: string;

  public calendarPerm: string;
  public mediaPerm: string;
  public safekeeperPerm: string;
  public messagesPerm: string;
  public feature_ACTIVE_SUB_TYPE_CARE_MGMT = false;
  public languages = languages;
  public countries = allowedCountries;
  @ViewChild('formUser') userForm: NgForm;
  public isRecipientNoEmailInvalidUsername = false;
  states = UserPoliciesState
  policiesState = PoliciesState
  userRoles = UserRoles
  public userPolicies: UserPoliciesModel
  public Recipient: boolean = true;
  public maxDate = new Date();
  public minDate = new Date('1900-1-1');
  private currentUser: UserModel;

  constructor(
    private router: Router,
    private utils: Utils,
    private userService: UserService,
    private teamService: TeamService,
    private messageService: MessageService,
    private dailyAssessmentService: DailyAssessmentService,
    private localData: LocalData,
    private logger: Logger,
    public dialog: MatDialog,
    private safeKeeperService: SafeKeeperService
  ) { }

  ngOnChanges() {
    this.teamPrimaryLanguage = this.team?.primaryLanguage;
    this.authorized_user = this.localData.getUser();
    this.defaultAvatarImg = environment.dashboardBaseUrl + this.userConstants.DEFAULT_AVATAR;
    if (this.user.dob && this.user.dob.toString() === '0001-01-01T00:00:00') {
      this.user.dob = null;
      // terms of use
      if (!this.user.tosAccepted || this.user.tosAccepted.toString() === '0001-01-01T00:00:00') {
        this.acceptTermsOfUse = false;
      }
    }
    if (!this.user.avatarUrl) {
      this.user.avatarUrl = this.defaultAvatarImg;
    }
    if (!this.user.firstName && !this.user.lastName && this.user.displayName) {
      const displayParts = this.user.displayName.split(" ")
      this.user.firstName = displayParts[0];
      this.user.lastName = displayParts.length > 0 ? displayParts[displayParts.length - 1] : null;
    }

    if (this.user.cellPhone) {
      this.phoneType = "cell";
    } else if (this.user.homePhone) {
      this.phoneType = "home";
    } else if (this.user.workPhone) {
      this.phoneType = "work";
    }

    setTimeout(() => {
      this.isRecipientNoEmailInvalidUsername = this.user.recipientNoEmail && this.userForm.form.get('username').invalid;
    });

    this.pending = false;
    if (!this.user.lastLogin || moment(this.user.lastLogin).valueOf() < 1) {
      this.pending = true;
    }
    this.user_orig = Object.assign(new UserModel(), this.user);
    if (this.action.startsWith('view')) {
      $('.mat-select-arrow-wrapper').css('visibility', 'hidden');
    }
    if (this.authorized_user && this.team && this.team.teamUsers) {
      this.userIsTeamAdmin = this.getUserTeamUser(this.authorized_user, this.team).teamRoleId === this.teamConstants.ADMIN;
    }
    if (this.authorized_user.id === this.user.id || (this.userIsTeamAdmin && this.userTypeDisplay === 'Recipient')) {
      this.dobHidden = false;
    }
    if (this.action === 'createTeam') {
      this.dobHidden = false;
    }
    if (this.authorized_user.id === this.user.id) {
      this.showAcceptTermsOfUse = true;
    } else {
      this.showAcceptTermsOfUse = false;
    }
    this.setupPermissions();
    this.setShowAdditionalProfileData();
    this.getUserPolicies()
    this.userService.checkRecipient(this.team?.id,this.user?.id).subscribe((r) => {
      this.Recipient = r
    })
    this.userService.getUserSelf().subscribe((usr) => {
      this.currentUser = usr;
    })
  }

  getUserPolicies = () => {
    if(this.user.role === UserRoles.USER){
      this.userService.getUserPolicies().subscribe((m) => {
        this.userPolicies = m
      })
    }
  }

  handleUserTwoFactorsStateChanged = (e: any) => {
    this.userService.updateUserTwoFactor(e.value).subscribe(()=>{})
  }

  handleUserPasswordRotationStateChanged = (e: any) =>{
    this.userService.updateUserPasswordRotation(e.value).subscribe(()=>{})
  }

  requiredProfileDataReadOnly() {
    if (this.action === 'updateTeamUser') {
      return !(!this.user.lastLogin || moment(this.user.lastLogin).valueOf() < 1);
    }
    if (this.action === 'updateTeam') {
      return !(!this.user.lastLogin || moment(this.user.lastLogin).valueOf() < 1 || this.isRecipientNoEmailInvalidUsername);
    }
    return false;
  }

  setShowAdditionalProfileData() {
    if (this.action === 'createUser' || this.action === 'updateTeamUser') {
      this.showAdditionalProfileData = false;
    }
    if (this.action === 'updateTeam') {
      this.showAdditionalProfileData = false;
      if (this.user.state < this.userConstants.ACTIVE) {
        this.showAdditionalProfileData = true;
      } else {
        this.userService.getUserTeams(this.user.id)
          .subscribe(userTeams_api => {
            if (userTeams_api && userTeams_api.length < 2) {
              this.showAdditionalProfileData = true;
            }
          }, err => {
            this.handleApiError('ngOnInit', err);
          });
      }
    }
  }

  toggleRecipientNoEmail() {
    this.user.recipientNoEmail = !this.user.recipientNoEmail;
    if (this.user.recipientNoEmail) {
      this.user.email = null;
    } else {
      this.user.username = null;
    }
  }

  toggleTermsOfUse() {
    this.acceptTermsOfUse = !this.acceptTermsOfUse;
    if (this.acceptTermsOfUse) {
      this.user.tosAccepted = moment().utc().toDate();
    }
  }

  submitForm(form: NgForm) {
    if (!form.valid) {
      this.showNotification('top', 'center', 'danger', true, null, null,
        'Please correct all form errors in red below.');
      return false;
    }
    if (this.action === 'createTeam' && this.user.dob && moment().diff(this.authorized_user.dob, 'years') < 18) {
      this.showNotification('top', 'center', 'danger', true, null, null,
        'You must be at least 18 years of age to create a team.');
      return false;
    }
    if (this.showAdditionalProfileData && (!this.acceptTermsOfUse)) {
      this.showNotification('top', 'center', 'danger', true, null, null,
        'You must accept the Terms of Use.');
      return false;
    }
    if(this.currentUser.id === this.user.id){
      if (!this.user.cellPhone && !this.user.homePhone && !this.user.workPhone) {
        this.showNotification('top', 'center', 'danger', true, null, null,
          'At least one phone is required');
        return false;
      }
    }
    // validate email or username
    if (!this.user.recipientNoEmail) {
      let msg = 'Disallowed email address @domain.';
      const idx = this.user.email.indexOf('@');
      this.userService.isValidDomain(this.user.email.substring(idx + 1)).pipe(
        mergeMap(status => {
          return this.userService.emailExists(this.user.email);
        }))
        .subscribe(status => {
          if (this.user_orig.email === this.user.email) {
            this.doAction(form); // success
          } else {
            if (this.action === 'createUser') {
              this.doAction(form); // ok to add existing user to team
            } else {
              msg = 'An account already exists using this email: ' + this.user.email + '.';
              this.showNotification('top', 'center', 'danger', true, null, null, msg);
              return false;
            }
          }
        }, err => {
          if (err.status === 401) {
            this.showNotification('top', 'center', 'danger', true, null, null, msg);
            return false;
          } else {
            if (err.status === 404) {
              this.doAction(form); // success
            } else {
              this.handleApiError('emailExists', err);
              return false;
            }
          }
        });
    } else {
      this.userService.userNameExists(this.user.username)
        .subscribe(status => {
          if (this.user_orig.username === this.user.username) {
            this.doAction(form); // success
          } else {
            const msg = 'An account already exists using this username: ' + this.user.username + '.';
            this.showNotification('top', 'center', 'danger', true, null, null, msg);
            return false;
          }
        }, err => {
          if (err.status === 404) {
            this.doAction(form); // success
          } else {
            this.handleApiError('userNameExists', err);
            return false;
          }
        });
    }
  }

  doAction(form: NgForm) {
    switch (this.action) {
      case 'createUser': {
        if (this.localData.getUser().email === this.user.email) {
          this.showNotification('top', 'center', 'danger', true, null, null,
            'You cannot add a new recipient using your own email address.');
          return false;
        }
        if (this.isUserByEmailOnThisTeam(this.user, this.team)) {
          this.showNotification('top', 'center', 'danger', true, null, null,
            'You cannot add a new recipient (' + this.user.email + ') for a user that is already on this team (' + this.team.name + ').');
          return false;
        }
        this.userService.getUserByEmail(this.user.email)
          .subscribe(user_api => {
            this.addUserToTeam(form, user_api);
          }, err => {
            if (err.status === 404) {
              this.createUser(form);
            } else {
              this.handleApiError('createUser', err);
            }
          });
        break;
      }
      case 'updateUser': {
        this.updateUser(form);
        break;
      }
      case 'updateTeamUser': {
        this.updateTeamUser(form);
        break;
      }
      case 'updateTeam': {
        this.updateTeam(form);
        break;
      }
      case 'createTeam': {
        if (!this.user.recipientNoEmail && this.localData.getUser().email === this.user.email) {
          this.showNotification('top', 'center', 'danger', true, null, null,
            'You cannot create a recipient as yourself (' + this.user.email + ') as the recipient.');
          return false;
        }
        if (!this.user.recipientNoEmail && this.isUserByEmailOnAnyTeam(this.user)) {
          this.showNotification('top', 'center', 'danger', true, null, null,
            'You cannot create a recipient that is already on one of your teams.');
          return false;
        }
        if (!this.user.recipientNoEmail) {
          this.userService.getUserByEmail(this.user.email).subscribe(user_api => {
            this.createTeamForExistingUser(form, user_api);
          }, err => {
            if (err.status === 404) {
              this.createTeam(form);
            } else {
              this.handleApiError('createTeam', err);
            }
          });
        } else {
          this.createTeam(form);
        }
        break;
      }
      default: {
        return false;
      }
    }
  }

  updateUser(form: NgForm) {
    this.disableForm = true;
    $('#progressIndicator').modal('show');
    this.logger.logInfo(this.source, 'updateUser', 'userService: updateUser');
    if (this.user.id === this.localData.getUser().id) {
      if (this.user.walkthrough < 2) {
        this.user.walkthrough = 2;
      }
    }
    this.userService.updateUser(this.user)
      .subscribe(user_api => {
        $('#progressIndicator').modal('hide');
        if (this.user.id === this.localData.getUser().id) {
          this.userService.userSource.next(user_api);
        }
        if (this.user.id === this.localData.getUser().id && this.user.walkthrough > 5) {
          this.showNotification('top', 'center', 'success', true, null, null,
            this.userTypeDisplay + ' profile has been updated.');
        }
        if (this.user.id !== this.localData.getUser().id) {
          this.showNotification('top', 'center', 'success', true, null, null,
            this.userTypeDisplay + ' profile has been updated.');
        }
        this.user_orig = Object.assign(new UserModel(), this.user);
        this.disableForm = false;
        this.updateStatus.emit(true);
        this.done.emit();
      }, err => {
        $('#progressIndicator').modal('hide');
        this.handleApiError('updateUser', err);
        this.disableForm = false;
      });
  }

  updateTeamUser(form: NgForm) {
    this.disableForm = true;
    $('#progressIndicator').modal('show');
    this.logger.logInfo(this.source, 'updateTeamUser', 'userService: updateUser');
    this.userService.updateUser(this.user).pipe(
      mergeMap(user_api => {
        if (this.user.id === this.localData.getUser().id) {
          this.userService.userSource.next(user_api);
        }
        switch (this.userTypeDisplay) {
          case 'Administrator': {
            return this.teamService.updateTeamUser(this.team.id, this.teamConstants.ADMIN, user_api.id, this.setupPermissionsObject());
          }
          case 'Coach': {
            return this.teamService.updateTeamUser(this.team.id, this.teamConstants.COACH, user_api.id, this.setupPermissionsObject());
          }
          default: {
            return this.teamService.updateTeamUser(this.team.id, this.teamConstants.CONTRIBUTOR, user_api.id, this.setupPermissionsObject());
          }
        }
      }),
      mergeMap(status => {
        return this.teamService.sourceUserTeamData(this.localData.getUser());
      }),)
      .subscribe(user_and_teams => {
        $('#progressIndicator').modal('hide');
        this.showNotification('top', 'center', 'success', true, null, null,
          this.userTypeDisplay + ' profile has been updated.');
        this.user_orig = Object.assign(new UserModel(), this.user);
        this.disableForm = false;
        this.done.emit();
      }, err => {
        $('#progressIndicator').modal('hide');
        this.handleApiError('updateUser', err);
        this.disableForm = false;
      });
  }

  addUserToTeam(form: NgForm, user: UserModel) {
    this.disableForm = true;
    $('#progressIndicator').modal('show');
    this.userService.getUser(user.id).pipe(
      mergeMap(user_api => {
        switch (this.userTypeDisplay) {
          case 'Administrator': {
            return this.teamService.updateTeamUser(this.team.id, this.teamConstants.ADMIN, user_api.id, this.setupPermissionsObject());
          }
          default: {
            return this.teamService.updateTeamUser(this.team.id, this.teamConstants.CONTRIBUTOR, user_api.id, this.setupPermissionsObject());
          }
        }
      }),
      mergeMap(status => {
        const creatorId = this.localData.getUser().id;
        return this.userService.sendEmail(creatorId, 'AddedToTeam', creatorId, user.id, this.team.name);
      }),
      mergeMap(status => {
        return this.teamService.sourceUserTeamData(this.localData.getUser());
      }),)
      .subscribe(user_and_teams => {
        $('#progressIndicator').modal('hide');
        // send system message
        const body = 'You have been added by ' + this.localData.getUser().displayName + ' as ' + this.userTypeDisplay + ' on team ' + this.team.name;
        this.messageService.sendMessage(this.team.id, this.localData.getUser().id, user.id, body);
        this.showNotification('top', 'center', 'success', true, null, null,
        this.user.displayName + ' has been invited to join this team.');
        this.done.emit();
        this.router.navigate(['/team', this.team.id]);
      }, err => {
        $('#progressIndicator').modal('hide');
        this.handleApiError('addUserToTeam', err);
      });
    this.disableForm = false;
  }

  createUser(form: NgForm) {
    this.disableForm = true;
    $('#progressIndicator').modal('show');
    this.user.invitedBy = this.authorized_user.email;
    let user: UserModel;
    this.userService.createUser(this.user).pipe(
      mergeMap(user_api => {
        user = user_api;
        const role = this.userTypeDisplay === 'Administrator' ? this.teamConstants.ADMIN : this.teamConstants.CONTRIBUTOR;
        return this.teamService.updateTeamUser(this.team.id, role, user_api.id, this.setupPermissionsObject());
      }),
      mergeMap(status => {
        user.state = this.userConstants.INVITED;
        return this.userService.updateUser(user);
      }),
      mergeMap(status => {
        const creatorId = this.localData.getUser().id;
        return this.userService.sendEmail(creatorId, 'Invited', creatorId, user.id, this.team.name);
      }),
      mergeMap(status => {
        return this.teamService.sourceUserTeamData(this.localData.getUser());
      }),)
      .subscribe(user_and_teams => {
        $('#progressIndicator').modal('hide');
        // send system message
        const body = 'You have been added by ' + this.localData.getUser().displayName + ' as ' + this.userTypeDisplay + ' on team ' + this.team.name;
        this.messageService.sendMessage(this.team.id, this.localData.getUser().id, user.id, body);
        this.showNotification('top', 'center', 'success', true, null, null,
          this.user.displayName + ' has been invited to join this team.');
        this.logger.logInfo(this.source, 'createUser', 'User Id: ' + user.id +  ' invited to Team Id: ' + this.team.id + ' by User Id: ' + this.authorized_user.id);
        this.done.emit();
        this.router.navigate(['/team', this.team.id]);
      }, err => {
        $('#progressIndicator').modal('hide');
        this.handleApiError('createUser', err);
      });
    this.disableForm = false;
  }

  updateTeam(form: NgForm) {
    this.disableForm = true;
    $('#progressIndicator').modal('show');
    this.logger.logInfo(this.source, 'updateUser', 'userService: updateUser');
    this.userService.updateUser(this.user).pipe(
      mergeMap(user_api => {
        if (this.user.id === this.localData.getUser().id) {
          this.userService.userSource.next(user_api);
        }
        this.team.name = this.user.displayName;
        this.team.primaryLanguage = this.teamPrimaryLanguage;
        return this.teamService.updateTeam(this.team);
      }),
      mergeMap(team_api => {
        return this.teamService.sourceUserTeamData(this.localData.getUser());
      }),)
      .subscribe(user_and_teams => {
        $('#progressIndicator').modal('hide');
        this.showNotification('top', 'center', 'success', true, null, null,
          this.userTypeDisplay + ' profile has been updated.');
        this.user_orig = Object.assign(new UserModel(), this.user);
        this.disableForm = false;
        if (!this.authorized_user.invitedBy && this.authorized_user.walkthrough < 4) {
          this.updateStatus.emit(true);
          this.done.emit();
        }
      }, err => {
        $('#progressIndicator').modal('hide');
        this.handleApiError('updateTeam', err);
        this.disableForm = false;
      });
  }

  createTeam(form: NgForm) {
    this.disableForm = true;
    $('#progressIndicator').modal('show');
    let newUser = new UserModel();
    let newTeam = new TeamModel();
    newTeam.name = this.user.displayName;
    newTeam.referrer = this.authorized_user.referer;
    newTeam.primaryLanguage = this.teamPrimaryLanguage;
    this.teamService.createTeam(newTeam).pipe(
      mergeMap(team_api => {
        newTeam = team_api;
        return this.userService.createUser(this.user);
      }),
      mergeMap(user_api => {
        newUser = user_api;
        newUser.state = this.userConstants.ACTIVE;
        return this.userService.updateUser(newUser);
      }),
      mergeMap(user_api => {
        return this.teamService.updateTeamUser(newTeam.id, this.teamConstants.ADMIN, this.localData.getUser().id, this.teamConstants.ADMIN_PERMISSIONS);
      }),
      mergeMap(status => {
        return this.teamService.updateTeamUser(newTeam.id, this.teamConstants.RECIPIENT, newUser.id, this.teamConstants.RECIPIENT_PERMISSIONS);
      }),
      mergeMap(card => {
        return this.safeKeeperService.createDefaultCards(newTeam.id);
      }),
      mergeMap(message_api => {
        return this.teamService.sourceUserTeamData(this.localData.getUser());
      }),)
      .subscribe(user_and_teams => {
        $('#progressIndicator').modal('hide');
        this.showNotification('top', 'center', 'success', true, null, null,
          'Your new recipient has been created.');
        if (!this.authorized_user.invitedBy && this.authorized_user.walkthrough < 3) {
          this.authorized_user.walkthrough = 3;
          this.userService.updateUser(this.authorized_user)
            .subscribe(user_api => {
              this.userService.userSource.next(user_api);
              this.logger.logInfo(this.source, 'updateUser', 'Walkthrough: step 2 completed');
              this.updateStatus.emit(true);
              this.done.emit({team: newTeam});
            }, err => {
              this.logger.logError(this.source, 'updateUser', err, 'Walkthrough: step 2 update error');
              this.updateStatus.emit(true);
              this.done.emit({team: newTeam});
            });
        } else {
          this.done.emit({team: newTeam});
          this.router.navigate(['/team', newTeam.id]);
        }
      }
      , err => {
        $('#progressIndicator').modal('hide');
        this.handleApiError('createTeam', err);
      });
    this.disableForm = false;
  }

  createTeamForExistingUser(form: NgForm, newUser: UserModel) {
    this.disableForm = true;
    $('#progressIndicator').modal('show');
    let newTeam = new TeamModel();
    newTeam.name = this.user.displayName;
    this.teamService.createTeam(newTeam).pipe(
      mergeMap(team_api => {
        newTeam = team_api;
        return this.teamService.updateTeamUser(newTeam.id, this.teamConstants.ADMIN, this.localData.getUser().id, this.teamConstants.ADMIN_PERMISSIONS);
      }),
      mergeMap(status => {
        return this.teamService.updateTeamUser(newTeam.id, this.teamConstants.RECIPIENT, newUser.id, this.teamConstants.RECIPIENT_PERMISSIONS);
      }),
      mergeMap(status => {
        return this.teamService.sourceUserTeamData(this.localData.getUser());
      }),)
      .subscribe(user_and_teams => {
        $('#progressIndicator').modal('hide');
        this.showNotification('top', 'center', 'success', true, null, null,
          'Your new recipient has been created.');
        if (!this.authorized_user.invitedBy && this.authorized_user.walkthrough < 3) {
          this.authorized_user.walkthrough = 3;
          this.userService.updateUser(this.authorized_user)
            .subscribe(user_api => {
              this.userService.userSource.next(user_api);
              this.logger.logInfo(this.source, 'updateUser', 'Walkthrough: step 2 completed');
              this.updateStatus.emit(true);
              this.done.emit();
            }, err => {
              this.logger.logError(this.source, 'updateUser', err, 'Walkthrough: step 2 update error');
              this.updateStatus.emit(true);
              this.done.emit();
            });
        } else {
          this.done.emit();
          this.router.navigate(['/team', newTeam.id]);
        }
      }, err => {
        $('#progressIndicator').modal('hide');
        this.handleApiError('createTeamForExistingUser', err);
      });
    this.disableForm = false;
  }

  reset(form: NgForm) {
    this.formErrMsg = null;
    form.reset(this.user_orig);
    return false;
  }

  goToEditUser() {
    this.router.navigate(['/team', this.team.id, 'user', this.user.id, 'edit']);
  }

  goToEditRecipient() {
    this.editRecipient.emit();
  }

  // permissions
  setupPermissions() {
    let teamUser: TeamUserModel;
    if (this.team && this.team.teamUsers) {
      teamUser = this.getUserTeamUser(this.user, this.team);
    }
    if (teamUser) {
      this.calendarPerm = this.getPermString(teamUser.permissions.calendar);
      this.mediaPerm = this.getPermString(teamUser.permissions.media);
      this.safekeeperPerm = this.getPermString(teamUser.permissions.safekeeper);
      this.messagesPerm = this.getPermString(teamUser.permissions.messages);
    } else {
      switch (this.userTypeDisplay) {
        case 'Administrator': {
          this.calendarPerm = this.getPermString(this.teamConstants.ADMIN_PERMISSIONS.calendar);
          this.mediaPerm = this.getPermString(this.teamConstants.ADMIN_PERMISSIONS.media);
          this.safekeeperPerm = this.getPermString(this.teamConstants.ADMIN_PERMISSIONS.safekeeper);
          this.messagesPerm = this.getPermString(this.teamConstants.ADMIN_PERMISSIONS.messages);
          break;
        }
        case 'Contributor': {
          this.calendarPerm = this.getPermString(this.teamConstants.CONTRIBUTOR_PERMISSIONS.calendar);
          this.mediaPerm = this.getPermString(this.teamConstants.CONTRIBUTOR_PERMISSIONS.media);
          this.safekeeperPerm = this.getPermString(this.teamConstants.CONTRIBUTOR_PERMISSIONS.safekeeper);
          this.messagesPerm = this.getPermString(this.teamConstants.CONTRIBUTOR_PERMISSIONS.messages);
          break;
        }
        default: { // recipient
          this.calendarPerm = this.getPermString(this.teamConstants.RECIPIENT_PERMISSIONS.calendar);
          this.mediaPerm = this.getPermString(this.teamConstants.RECIPIENT_PERMISSIONS.media);
          this.safekeeperPerm = this.getPermString(this.teamConstants.RECIPIENT_PERMISSIONS.safekeeper);
          this.messagesPerm = this.getPermString(this.teamConstants.RECIPIENT_PERMISSIONS.messages);
          break;
        }
      }
    }
  }

  setupPermissionsObject(): TeamPermissionsModel {
    const teamPermissions = new TeamPermissionsModel();
    teamPermissions.calendar = this.getPermNumber(this.calendarPerm);
    teamPermissions.media = this.getPermNumber(this.mediaPerm);
    teamPermissions.safekeeper = this.getPermNumber(this.safekeeperPerm);
    teamPermissions.messages = this.getPermNumber(this.messagesPerm);
    switch (this.userTypeDisplay) {
      case 'Administrator': {
        teamPermissions.health = this.teamConstants.ADMIN_PERMISSIONS.health;
        break;
      }
      case 'Contributor': {
        teamPermissions.health = this.teamConstants.CONTRIBUTOR_PERMISSIONS.health;
        break;
      }
      default: { // recipient
        teamPermissions.health = this.teamConstants.RECIPIENT_PERMISSIONS.health;
        break;
      }
    }
    return teamPermissions;
  }

  getPermString(number): string {
    switch (number) {
      case 1: {
        return this.teamConstants.TEAM_PERMISSION_NOACCESS;
      }
      case 2: {
        return this.teamConstants.TEAM_PERMISSION_READONLY;
      }
      case 3: {
        return this.teamConstants.TEAM_PERMISSION_EDIT;
      }
    }
  }

  getPermNumber(val): number {
    switch (val) {
      case this.teamConstants.TEAM_PERMISSION_NOACCESS: {
        return 1;
      }
      case this.teamConstants.TEAM_PERMISSION_READONLY: {
        return 2;
      }
      case this.teamConstants.TEAM_PERMISSION_EDIT: {
        return 3;
      }
    }
  }

  getRolesChoices() {
    if (this.userTypeDisplay === "Coach") {
      // remove other choices for coaches
      return ["Coach"];
    } else {
      return ['Administrator', 'Contributor'];
    }
  }

  // utils
  get isCoach() {
    return this.user.role === UserRoles.COACH;
  }

  showNotification(from: any, align: any, type: any, allow_dismiss: boolean, url: string, target: string, message: string) {
    let delay = 5000;
    let timer = 1000;
    if (!allow_dismiss) {
      delay = 0; timer = 0;
    }
    $.notify(
      { icon: 'notifications', message: message, url: url, target: target },
      { type: type, delay: delay, timer: timer, allow_dismiss: allow_dismiss, placement: { from: from, align: align } }
    );
  }

  isUserByEmailOnThisTeam(user: UserModel, team: TeamModel): boolean {
    let existingUser: TeamUserModel;
    existingUser = team.teamUsers.find((teamUser) => {
      return (teamUser.user.email === user.email);
    });
    if (existingUser) {
      return true;
    } else {
      return false;
    }
  }

  isUserByEmailOnAnyTeam(user: UserModel): boolean {
    const teams = this.localData.getTeams();
    let existingUser: TeamUserModel;
    for (const team of teams) {
      existingUser = team.teamUsers.find((teamUser) => {
        return (teamUser.user.email === user.email);
      });
    }
    if (existingUser) {
      return true;
    } else {
      return false;
    }
  }

  resendAlert(user: UserModel) {
    this.selectedResendUser = user;
    $('#pendingModal').modal('show');
    return false;
  }

  resendInvite(user: UserModel) {
    const creatorId = this.localData.getUser().id;
    this.userService.sendEmail(creatorId, 'Invited', creatorId, user.id, this.team.name)
      .subscribe(status => {
        this.showNotification('top', 'center', 'success', true, null, null,
          'The email invitation to join this team was resent to ' + this.user.displayName + '.');
        this.logger.logInfo(this.source, 'sendEmail', 'User Id: ' + user.id + ' invited to Team Id: ' + this.team.id + ' by User Id: ' + this.authorized_user.id);
        $('#pendingModal').modal('hide');
      }, err => {
        $('#pendingModal').modal('hide');
        this.handleApiError('createUser', err);
      });
  }

  handleRecipientRemoveEmailClick = () => {
     const dialogRef = this.dialog.open(RecipientRemoveEmailComponent,{
       data:{
         teamId: this.team.id
       },
      autoFocus: false
    })
    dialogRef.afterClosed().subscribe((r) => {
      if(r){
        window.location.reload();
      }
    })
  }

  get postalCodeHint() {
    switch (this.user.addressCountry) {
      case 'CA':
        return 'Format: X1X 1X1';

      default:
        return 'Format: 80204 or 80204-3333';
    }
  }

  get postalCodePattern() {
    switch (this.user.addressCountry) {
      case 'CA':
        return /^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i

      default:
        return /^[0-9]{5}(-[0-9]{4})?$/;
    }
  }

  // utils
  handleApiError(func, err) {
    this.showNotification('top', 'center', 'danger', true, null, null,
      'Server Error: ' + err.status + ' ' + err.statusText);
    // TODO implement ship logs
  }

  getUserTeamUser(user: UserModel, team: TeamModel): TeamUserModel {
    return team.teamUsers.find((teamUser) => {
      return (teamUser.userId === user.id);
    });
  }

  ngOnDestroy(): void {
    this.userService.clearPasswordComplexity()
  }
}
