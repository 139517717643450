import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../../../providers/user.service';
import { ExpiredPasswordUpdateModel } from '../../../models/expired-password-update.model';

// noinspection DuplicatedCode
@Component({
  selector: 'app-update-password-rotation',
  templateUrl: 'update-password-rotation.component.html'
})
export class UpdatePasswordRotationComponent implements OnInit{
  public form: FormGroup;
  public formErrMsg: string = null;
  constructor(public dialogRef: MatDialogRef<UpdatePasswordRotationComponent>,
              public fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public email: string,
              private userService: UserService,
              ) {
  }
  checkPasswords = () => {
    return (formGroup: FormGroup) => {
      const password = formGroup.controls['password'];
      const confirmPassword = formGroup.controls['confirmPassword'];

      if (password.errors && !confirmPassword.errors.noMatch) {
        return;
      }

      if (password.value !== confirmPassword.value) {
        confirmPassword.setErrors({ noMatch: true });
      } else {
        password.setErrors(null);
      }
    }
  }
  ngOnInit(): void {
    // password complexity will be validated server side
    this.form = this.fb.group({
      currentPassword: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['', Validators.required],
    },{
      validators: this.checkPasswords()
    })
  }
   onSubmit = () => {
          if(this.form.valid){
            this.formErrMsg = null
                let model = new ExpiredPasswordUpdateModel()
            model.currentPassword = this.form.get('currentPassword').value
            model.password = this.form.get('password').value
            model.confirmPassword = this.form.get('confirmPassword').value
            model.email = this.email;
            this.userService.updateExpiredPassword(model).subscribe(async (r) => {
              if (!r.isError) {
                this.dialogRef.close(r)
              } else
                this.formErrMsg = r.message
            })
          }
  }
}
