import { PaginatorModel } from './paginator.model';
import { extend } from "jquery";

export class GetFoldersModel extends PaginatorModel{
    public teamid: string;
}

export class GetUserTabsModel extends GetFoldersModel {
    public tabId: string;
}
