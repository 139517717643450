import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-footer-cmp',
    templateUrl: 'footer.component.html'
})

export class FooterComponent {
  public get year(){
    return moment().year()
  }
}
