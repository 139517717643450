export class PaginatorModelBase{
    public limit: number;
    public offset: number;
    constructor() {
        this.limit = -1;
        this.offset = 0;
    }
}

export class PaginatorModel extends PaginatorModelBase{
    public orderBy: string;
    public direction: ''|'asc'|'desc' = 'desc';
}



