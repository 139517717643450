import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

// rxjs
import { Observable, throwError } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';

// models
import {IPaginatedDataModel} from '../models/paginated-data-model';
import {CoachMessage, GetUserMessagesModel, MessageModel} from '../models/message.model';
import {CoachSurveyModel, GetCoachesModel, GetRequestsModel, PaginatedCoachRequests} from './../models/coach.model'
import {CoachTeamModel, GetCoachTeamsModel} from '../models/team.model';
import {UserModel} from 'models/user.model';

// providers
import { Logger } from './logger.service';
import { Utils } from './utils.service';
import { LocalData } from './local-data.service';

import { environment } from '../environments/environment';
import {CoachNoteModel} from '../models/coach-note.model';
import {EligibilityResponseModel} from '../models/eligibility-response-model';
import {UserInvitedByCoachModel} from '../models/user-invited-by-coach-model';
import { ISelectData } from '../models/select-model';
import { MessageCenterModel } from '../models/message-center-model';
import { MessageCenterSearchModel } from '../models/message-center-search.model';
import { CoachChatRoom, GetUserChatRoomsModel } from 'models/chat-room.model';
import { CoachMessagesReportTableModel, CreateReportModel } from '../models/coach-messages-report-model';
import { PaginatorModelBase } from '../models/paginator.model';
import {ReassignCoachModel} from '../models/reassign-coach.model';

@Injectable()
export class CoachService {
  private source = 'CoachService';

  constructor(
    private http: HttpClient,
    private localData: LocalData,
    private logger: Logger,
    private utils: Utils,
  ) {}

  sendSurvey(coachSurvey: CoachSurveyModel, url = 'survey'): Observable<Boolean> {
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(environment.apiBaseUrl + '/coach/'+url, coachSurvey, options).pipe(
        map((res: HttpResponse<any>) => {
            return true;
        }),
        catchError((err: any) => {
            this.logger.logError('coach', 'sendSurvey', err, 'API Error');
            return throwError(err);
        })
    );
  }

  getCoachRequests(model: GetRequestsModel): Observable<PaginatedCoachRequests> {
    const options = this.utils.getJsonHttpOptions();
    const userId = model.coachId;
    const body = model;
    return this.http.post(environment.apiBaseUrl + '/coach/requests', body, options)
      .pipe(
        map((res: HttpResponse<any>) => {
            return res.body;
        }),
        catchError((err: any) => {
            this.logger.logError('coach', 'getCoachRequests', err, 'API Error');
            return throwError(err);
        })
    );
  }

  getCoachRequest(requestId: string): Observable<CoachSurveyModel> {
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(environment.apiBaseUrl + '/coach/requests/' + requestId, options)
      .pipe(
        map((res: HttpResponse<any>) => {
          return res.body;
        }),
        catchError((err: any) => {
          this.logger.logError('coach', 'getCoachRequest', err, 'API Error');
          return throwError(err);
        })
      );
  }

  getCoachEligibility(email: string): Observable<EligibilityResponseModel> {
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(environment.apiBaseUrl + '/coach/eligible/' + email, options)
      .pipe(
        map((res: HttpResponse<EligibilityResponseModel>) => {
          return res.body;
        }),
        catchError((err: any) => {
          this.logger.logError('coach', 'getCoachEligibility', err, 'API Error');
          return throwError(err);
        })
      );
  }

  respondToRequest(userId: string, requestId: string, status: number): Observable<any> {
    const options = this.utils.getJsonHttpOptions();
    const body = status;
    return this.http.post(environment.apiBaseUrl + '/coach/' + userId + '/requests/' + requestId + '/respond' , body, options)
      .pipe(
        map((res: HttpResponse<any>) => {
            return res.body;
        }),
        catchError((err: any) => {
            this.logger.logError('coach', 'sendSurvey', err, 'API Error');
            return throwError(err);
        }),
      );
  }

  cancelRequest(requestId: string): Observable<any> {
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(environment.apiBaseUrl + '/coach/requests/' + requestId, options)
      .pipe(
        map((res: HttpResponse<any>) => {
          return res.body;
        }),
        catchError((err: any) => {
          this.logger.logError('coach', 'sendSurvey', err, 'API Error');
          return throwError(err);
        }),
      );
  }

  hideRequest(requestId: string): Observable<any> {
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(environment.apiBaseUrl + '/coach/requests/' + requestId + '/hide', options)
      .pipe(
        map((res: HttpResponse<any>) => {
          return res.body;
        }),
        catchError((err: any) => {
          this.logger.logError('coach', 'sendSurvey', err, 'API Error');
          return throwError(err);
        }),
      );
  }

  listCoachesNames(filter: string = ''): Observable<UserModel[]> {
    const options = this.utils.getJsonHttpOptions();
    let url = environment.apiBaseUrl + '/coach/names';
    if (filter?.length) {
      url += `?filter=${filter}`;
    }
    return this.http.get(url, options)
      .pipe(
        map((res: HttpResponse<UserModel[]>) => {
          return res.body;
        }),
        catchError((err: any) => {
          this.logger.logError('coach', 'listCoachesNames', err, 'API Error');
          return throwError(err);
        }),
      );
  }

  listCoaches(model: GetCoachesModel): Observable<IPaginatedDataModel<UserModel>> {
    const options = this.utils.getJsonHttpOptions();
    const url = environment.apiBaseUrl + '/coach/list';
    return this.http.post(url, model, options)
      .pipe(
        map((res: HttpResponse<IPaginatedDataModel<UserModel>>) => {
          return res.body;
        }),
        catchError((err: any) => {
          this.logger.logError('coach', 'listCoaches', err, 'API Error');
          return throwError(err);
        }),
      );
  }

  inviteCoach(coach: UserModel): Observable<UserModel> {
    const options = this.utils.getJsonHttpOptions();
    const body = coach;
    return this.http.post(environment.apiBaseUrl + '/coach/invite', body, options)
      .pipe(
        map((res: HttpResponse<any>) => {
            return res.body;
        }),
        catchError((err: any) => {
            this.logger.logError('coach', 'inviteCoach', err, 'API Error');
            return throwError(err);
        }),
      );
  }

  getReportData = (reportId: string) : Observable<MessageCenterModel[]> => {
    const options = this.utils.getJsonHttpOptions();
    const url = environment.apiBaseUrl + '/bulk-messages/report/'+reportId;
    return this.http.get(url,options).pipe(
      map((res: HttpResponse<MessageCenterModel[]>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('coach get report data', 'getReportData', err, 'API Error');
        return throwError(err);
      }), );
  }

  getCoachSearchCenterMessages = (model: MessageCenterSearchModel) : Observable<MessageCenterModel[]> => {
    const func = 'getCoachMessages';
    const call = 'API post /coach/messages-center/search"';
    const apiUrl = environment.apiBaseUrl + '/coach/messages-center/search';

    this.logger.logInfo(this.source, func, call);
    const options = this.utils.getJsonHttpOptions();

    return this.http.post(apiUrl, model, options).pipe(
      map((res: HttpResponse<MessageCenterModel[]>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, coachId: model.coachId }, call);
          return res.body
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }), );
  }


  getCoachMessages(model: GetUserMessagesModel): Observable<IPaginatedDataModel<CoachMessage>> {
    const func = 'getCoachMessages';
    const call = 'API post /coach/messages/';
    const apiUrl = environment.apiBaseUrl + '/coach/messages/';

    this.logger.logInfo(this.source, func, call);
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(apiUrl, model, options).pipe(
      map((res: HttpResponse<IPaginatedDataModel<CoachMessage>>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, userId: model.userId }, call);
        const data = this.utils.transformMessages(this.utils.adjustTimesForArray(res.body.data));
        return {
          count: res.body.count,
          data
        };
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  getCoachChatRooms(model: GetUserChatRoomsModel): Observable<IPaginatedDataModel<CoachChatRoom>> {
    const func = 'getCoachChatRooms';
    const call = 'API post /coach/chatrooms/';
    const apiUrl = environment.apiBaseUrl + '/coach/chatrooms/';

    this.logger.logInfo(this.source, func, call);
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(apiUrl, model, options).pipe(
      map((res: HttpResponse<IPaginatedDataModel<CoachChatRoom>>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, userId: model.userId, teamId: model.teamId }, call);
        const data = this.utils.transformMessages(this.utils.adjustTimesForArray(res.body.data));
        return {
          count: res.body.count,
          data
        };
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  getCoachTeams(model: GetCoachTeamsModel): Observable<IPaginatedDataModel<CoachTeamModel>> {
    const func = 'getCoachTeams';
    const call = 'API get /coach/teams/';
    const apiUrl = environment.apiBaseUrl + '/coach/teams/';

    this.logger.logInfo(this.source, func, call);
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(apiUrl, model, options).pipe(
      map((res: HttpResponse<IPaginatedDataModel<CoachTeamModel>>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, coachId: model.coachId }, call);
        const data = this.utils.adjustTimesForArray(res.body.data);
        return {
          count: res.body.count,
          data
        };
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  assignCoachToRequest(coachId: string, requestId: string): Observable<any> {
    const func = 'assignCoachToRequest';
    const call = 'API get /coach/:coachId/requests/:requestId/assign';
    const apiUrl = environment.apiBaseUrl + `/coach/${coachId}/requests/${requestId}/assign`;
    this.logger.logInfo(this.source, func, call);
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(apiUrl, {}, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, coachId, requestId }, call);
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  updateCoach(coach: UserModel): Observable<UserModel> {
    const func = 'updateCoach';
    const call = 'API put /coach/:coachId';
    const apiUrl = environment.apiBaseUrl + `/coach/${coach.id}`;
    this.logger.logInfo(this.source, func, call);
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(apiUrl, coach, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, id: coach.id }, call);
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }), );
  }

  deleteCoaches(id: string): Observable<any> {
    const func = 'deleteCoach';
    const call = 'API delete /coach/:coachId';
    const apiUrl = environment.apiBaseUrl + `/coach/${id}`;
    this.logger.logInfo(this.source, func, call);
    const options = this.utils.getJsonHttpOptions();
    return this.http.delete(apiUrl, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, id }, call);
        return res.body;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }), );
  }

    addCoachNote(note: CoachNoteModel): Observable<CoachNoteModel> {
        this.logger.logInfo(this.source, 'addCoachNote', 'API POST /teams/addcoachnote');
    const options = this.utils.getJsonHttpOptions();
        return this.http.post(environment.apiBaseUrl + '/teams/addcoachnote', note, options).pipe(
            map((res: HttpResponse<CoachNoteModel>) => {
                return res.body;
            }),
            catchError((err: any) => {
                this.logger.logError(this.source, 'addCoachNote', err, 'API Error');
                return observableThrowError(err);
            })
        )
    }

    updateCoachNote(note: CoachNoteModel): Observable<number> {
        this.logger.logInfo(this.source, 'updateCoachNote', 'API post /teams/updatecoachnote');
    const options = this.utils.getJsonHttpOptions();
        return this.http.post(environment.apiBaseUrl + '/teams/updatecoachnote', note, options).pipe(
            map((res: HttpResponse<number>) => {
                return res.body
            }),
            catchError((err: any) => {
                this.logger.logError(this.source, 'updateCoachNote', err, 'API Error')
                return observableThrowError(err);
            })
        )
    }

    deleteCoachNote(noteId: string, teamId: string): Observable<number> {
        this.logger.logInfo(this.source, 'deleteCoachNote', 'API GET /teams/deletecoachnote');
    const options = this.utils.getJsonHttpOptions();
        return this.http.get(environment.apiBaseUrl + '/teams/deletecoachnote/?noteid=' + noteId + '&teamId=' + teamId, options).pipe(
            map((res: HttpResponse<number>) => {
                return res.body
            }),
            catchError((err: any) => {
                this.logger.logError(this.source, 'deleteCoachNote', err, 'API Error')
                return observableThrowError(err);
            })
        )
    }

    getCoachNotes(teamId: string, limit = -1, offset = 0): Observable<CoachNoteModel[]> {
        this.logger.logInfo(this.source, 'getCoachNotes', 'API GET /teams/getcoachnotes');
    const options = this.utils.getJsonHttpOptions();
        return this.http.get(environment.apiBaseUrl + '/teams/getcoachnotes/?teamid=' + teamId + '&limit=' + limit + '&offset=' + offset, options).pipe(
            map((res: HttpResponse<CoachNoteModel[]>) => {
                return res.body;
            }),
            catchError((err: any) => {
                this.logger.logError(this.source, 'getCoachNotes', err, 'API Error')
                return observableThrowError(err)
            })
        )
    }

    createUser = (usr: UserModel): Observable<string> => {
    const options = this.utils.getJsonHttpOptions();
        return this.http.post(environment.apiBaseUrl + '/coach/newuser', usr, options)
            .pipe(
                map((res: HttpResponse<any>) => {
                    return res.body;
                }),
                catchError((err: any) => {
                    this.logger.logError('coach', 'createUser', err, 'API Error');
                    return throwError(err);
                })
            );
    }

    reassignCoach = (model: ReassignCoachModel): Observable<boolean> => {
      const options = this.utils.getJsonHttpOptions();
      return this.http.post(environment.apiBaseUrl + '/coach/reassign', model, options)
        .pipe(
          map((res: HttpResponse<any>) => {
            if(res.status !== 200){
              throwError("Failed to reassign coach");
            }
            return true
          }),
          catchError((err: any) => {
            this.logger.logError('coach', 'createUser', err, 'API Error');
            return throwError(err);
          })
        );
    }

  getCoachSelectData = () : Observable<ISelectData[]> => {
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(environment.apiBaseUrl+'/coach/get/select-model',options).pipe(
      map((res: HttpResponse<ISelectData[]>) => {
        return (res.body);
      }),
      catchError((err: any) => {
        this.logger.logError('admin, sys-manager', 'getClientsSelectData', err, 'API Error');
        return throwError(err);
      }), );
  }

  inviteUser = (email: string, teamName: string): Observable<HttpResponse<any>> => {
    const options = this.utils.getJsonHttpOptions();
        let obj = new UserInvitedByCoachModel()
        obj.email = email
        obj.teamName = teamName
        return this.http.post(environment.apiBaseUrl + '/coach/inviteUser',obj, options)
            .pipe(
                map((res: HttpResponse<any>) => {
                    return res.body;
                }),
                catchError((err: any) => {
                    this.logger.logError('coach', 'inviteUser', err, 'API Error');
                    return throwError(err);
                })
            );
    }

    createBulkMessageReport = (model: CreateReportModel): Observable<boolean> => {
      const options = this.utils.getJsonHttpOptions();

      return this.http.post(environment.apiBaseUrl + '/coach/bulk-messages/reports/create',model, options)
        .pipe(
          map((res: HttpResponse<any>) => {
            return true
          }),
          catchError((err: any) => {
            this.logger.logError('coach', 'createBulkMessageReport', err, 'API Error');
            return throwError(err);
          })
        );
    }

    getReports = (model: PaginatorModelBase): Observable<IPaginatedDataModel<CoachMessagesReportTableModel>> => {
      const options = this.utils.getJsonHttpOptions();
      return this.http.post(environment.apiBaseUrl + '/coach/bulk-messages/reports/get',model, options)
        .pipe(
          map((res: HttpResponse<IPaginatedDataModel<CoachMessagesReportTableModel>>) => {
            return res.body
          }),
          catchError((err: any) => {
            this.logger.logError('coach', 'getReports', err, 'API Error');
            return throwError(err);
          })
        );
    }

    deleteReport = (id: string): Observable<boolean> => {
      const options = this.utils.getJsonHttpOptions();
      return this.http.delete(environment.apiBaseUrl + '/coach/bulk-messages/reports/delete/'+id,options)
        .pipe(
          map((res: HttpResponse<boolean>) => {
            return res.body
          }),
          catchError((err: any) => {
            this.logger.logError('coach', 'deleteReport', err, 'API Error');
            return throwError(err);
          })
        );
    }
 }
