import {Routes} from '@angular/router';

// models
import {UserRoles} from 'models/user.model';

// layouts
import {AdminLayoutComponent} from './layouts/admin/admin-layout.component';
import {SystemManagerLayoutComponent} from './layouts/system-manager-layout/system-manager-layout.component';
import {UserLayoutComponent} from './layouts/user-layout/user-layout.component';
import {CoachLayoutComponent} from './layouts/coach-layout/coach-layout.component';

// pages
import {LoginComponent} from './login/login.component';
import {IntroComponent} from './coach/intro/intro.component';
import {SurveyComponent} from './coach/survey/survey.component';
import {CoachInfoComponent} from './coach/coach-info/coach-info.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {ConfirmationComponent} from './confirmation/confirmation.component';

// guards
import { AuthGuard } from 'guards/auth.guard';
import {RoleGuard} from 'guards/role.guard';
import {DefaultPageGuard} from 'guards/default-page.guard';
import { MultiFactorAuthGuard } from 'guards/multi-factor-auth.guard';
import { ClientManagerLayoutComponent } from './layouts/client-manager-layout/client-manager-layout.component';

export const AppRoutes: Routes = [
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'privacy', component: PrivacyComponent
  },
  {
    path: 'confirmation', component: ConfirmationComponent
  },
  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: 'walkthrough',
    loadChildren: () => import('./walkthrough/walkthrough.module').then(m => m.WalkthroughModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterModule)
  },
  {
    path: '',
    pathMatch: 'full',
    component: UserLayoutComponent, // dummy component never activated
    canActivate: [AuthGuard, DefaultPageGuard]
  },
  {
    path: '', component: UserLayoutComponent,
    data: {
      allowedRoles: [UserRoles.USER, UserRoles.COACH]
    },
    children: [
      {
        path: '',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'chat',
        redirectTo: 'chat/',
        pathMatch: 'full'
      },
      {
        path: 'chat/:id',
        loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)
      },
      {
        path: 'message',
        redirectTo: '/chat'
      },
      {
        path: 'calendar',
        loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule)
      },
      {
        path: 'media',
        loadChildren: () => import('./media/media.module').then(m => m.MediaModule)
      },
      {
        path: 'safekeeper',
        loadChildren: () => import('./safekeeper/safekeeper.module').then(m => m.SafekeeperModule)
      },
      {
        path: 'health',
        loadChildren: () => import('./health/health.module').then(m => m.HealthModule)
      },
      {
        path: 'financial',
        loadChildren: () => import('./financial/financial.module').then(m => m.FinancialModule)
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      },
      {
        path: 'team',
        loadChildren: () => import('./team/team.module').then(m => m.TeamModule)
      },
      {
        path: 'medicare-concierge',
        loadChildren: () => import('./medicare-concierge/medicare-concierge.module').then(m => m.MedicareConciergeModule)
      },
      {
        path: 'get-coach', component: IntroComponent,
        data: {
          allowedRoles: [UserRoles.USER]
        },
        canActivate: [RoleGuard]
      },
      {
        path: 'coach-survey', component: SurveyComponent,
        data: {
          allowedRoles: [UserRoles.USER]
        },
        canActivate: [RoleGuard]
      },
      {
        path: 'coach-info/:id', component: CoachInfoComponent,
        data: {
          allowedRoles: [UserRoles.USER]
        },
        canActivate: [RoleGuard]
      }
    ],
    canActivate: [AuthGuard, RoleGuard, MultiFactorAuthGuard]
  },
  {
    path: 'admin',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      },
    ],
    data: {
      allowedRoles: [UserRoles.ADMIN, UserRoles.SUPPORT]
    },
    canActivate: [AuthGuard, RoleGuard, MultiFactorAuthGuard]
  },
  {
    path: 'coach',
    component: CoachLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./coach/coach.module').then(m => m.CoachModule),
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      },
    ],
    data: {
      allowedRoles: [UserRoles.COACH, UserRoles.COACHING_ADMIN]
    },
    canActivate: [AuthGuard, RoleGuard, MultiFactorAuthGuard]
  },
  {
    path: 'manager',
    component: SystemManagerLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./system-manager/system-manager.module').then(m => m.SystemManagerModule),
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      },
    ],
    data: {
      allowedRoles: [UserRoles.SYSTEM_MANAGER]
    },
    canActivate: [AuthGuard, RoleGuard, MultiFactorAuthGuard]
  },
  {
    path: 'client-manager',
    component: ClientManagerLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./client-manager/client-manager.module').then(m => m.ClientManagerModule),
      },
      {
        path: 'user',
        loadChildren: () => import('./user/user.module').then(m => m.UserModule)
      },
    ],
    data: {
      allowedRoles: [UserRoles.CLIENT_MANAGER]
    },
    canActivate: [AuthGuard, RoleGuard, MultiFactorAuthGuard]
  },
  {
    path: '**',
    // TODO: Add 404 page instead
    redirectTo: '/'
  }
];
