type SITE_PARAMETERS = {
  SITE_PREFIX: string;
  URLS: {
    WALKTHROUGH_URL: string;
    BH_LOGIN_URL?: string;
    BH_BACKUP_URL?: string;
    BH_RESOURCE_LIBRARY_URL?: string
  };
  MESSAGE_SYSTEM: {
    NAME: string;
    AVATAR: string;
  };
}

export const SiteParameters: SITE_PARAMETERS = {
  SITE_PREFIX: '.bh',
  URLS: {
    BH_LOGIN_URL: 'https://bhlogin.brighthorizons.com/',
    BH_BACKUP_URL: 'https://backup.brighthorizons.com/',
    BH_RESOURCE_LIBRARY_URL: 'https://helpwhereyouare.com/login/1006/tmUf-rQRqpbqsqAZ__YhPbgtgyEsAx8DZANJQq4MbygDxv6p35Td9709L6Q0impt:YWJjZGVmOTg3NjU0MzIxMA==/L2RpdmlzaW9uLzAy',
    WALKTHROUGH_URL: '/register',
  },
  MESSAGE_SYSTEM: {
    NAME: 'Bright Horizons Elder Care Support',
    AVATAR: '/assets/img/bh-icon-Avatar-100.png',
  }
}
