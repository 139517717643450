import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';

// rxjs
import {Observable, throwError as observableThrowError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

// models
import {GetUserMessagesModel, MessageModel} from './../models/message.model';
import {UserConstants} from './../models/user.model';

// providers
import {environment} from './../environments/environment';
import {Utils} from './utils.service';
import {Logger} from './../providers/logger.service';
import {IPaginatedDataModel} from 'models/paginated-data-model';

@Injectable()
export class MessageService {
  private source = 'MessageService';
  private apiBaseUrl = environment.apiBaseUrl;
  private userConstants = new UserConstants();

  constructor(
    public http: HttpClient,
    public utils: Utils,
    private logger: Logger
  ) {}

  sendMessage(teamId: string, senderId: string, receiverId: string, body: string, isSystem: boolean = true) {
    const message = new MessageModel();
    if (!senderId) {
      message.senderUserId = this.userConstants.SYSTEM_USER_ID;
    } else {
      message.senderUserId = senderId;
    }
    message.receiverUserId = receiverId;
    message.body = body;
    message.isSystem = isSystem;
    this.createMessage(teamId, message)
      .subscribe(message_api => {
      }, err => {
        this.logger.logError(this.source, 'sendMessage', {err}, 'API error');
      });
  }
  getUserMessagesV2(model: GetUserMessagesModel): Observable<IPaginatedDataModel<MessageModel>> {
    const func = 'getUserMessagesV2';
    const call = 'API get /users/:id/messages/v2/direction=receiver';
    let apiUrl = this.apiBaseUrl + '/users/' + model.userId + '/messages/v2/direction=receiver';

    this.logger.logInfo(this.source, func, call);
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(apiUrl, model ,options).pipe(
      map((res: HttpResponse<IPaginatedDataModel<MessageModel>>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, userId: model.userId }, call);
        const data = this.utils.transformMessages(this.utils.adjustTimesForArray(res.body.data));
        return {
          count: res.body.count,
          data
        };
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  createMessage(teamId: string, message: MessageModel): Observable<MessageModel> {
    const func = 'createMessage';
    const call = 'API post /teams/:id/messages';
    this.logger.logInfo(this.source, func, call);
    message.teamId = teamId;
    const body = message;
    const options = this.utils.getJsonHttpOptions();
    return this.http.post(this.apiBaseUrl + '/teams/' + teamId + '/messages', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, teamId }, call);
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  updateMessage(userId: string, message: MessageModel): Observable<MessageModel> {
    const func = 'updateMessage';
    const call = 'API put /users/:id/messages/:id';
    this.logger.logInfo(this.source, func, call);
    const body = { delivered: message.delivered, read: message.read, archived: message.archived };
    const options = this.utils.getJsonHttpOptions();
    return this.http.put(this.apiBaseUrl + '/users/' + userId + '/messages/' + message.messageId, body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, userId }, call);
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  getRecipientMessaging(userId: string, recipientId: string, teamId: string, start: Date, count: number, status: string): Observable<MessageModel[]> {
    const func = 'getRecipientMessaging';
    const call = 'API get /users/:id/messages/recipient/:id?teamId=teamId&start=start&count=count&status=' + status;
    if (!status) {
      this.logger.logInfo(this.source, func, call);
    }
    const startParam = this.utils.setDateTimeQueryParam(start, 'MM/DD/YYYY HH:mm:ss');
    const options = this.utils.getJsonHttpOptions();
    return this.http.get(this.apiBaseUrl + '/users/' + userId + '/messages/recipient/' + recipientId + '?teamId=' + teamId + '&start=' + startParam + '&count=' + count + '&status=' + status, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, func, { status: res.status, statusText: res.statusText, userId, recipientId, teamId }, call);
        return this.utils.transformMessages(this.utils.adjustTimesForArray(res.body.items));
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, func, err, 'API Error');
        return observableThrowError(err);
      }),);
  }
}
