import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {ThemePalette} from '@angular/material/core';

export interface DialogData {
  message: string;
  color?: ThemePalette;
}

export type ConfirmationDialogExtendedResponse  = "Yes"|'No'|'Cancel'

@Component({
  selector: 'app-confirmation-extended-dialog',
  templateUrl: 'confirmation-dialog-extended.component.html'
})
export class ConfirmationDialogExtendedComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogExtendedComponent,ConfirmationDialogExtendedResponse>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
  }
}
