<h1 mat-dialog-title>Remove Team Member</h1>
<form [formGroup]="form" (ngSubmit)="onRemove()">
  <div mat-dialog-content>
    <h5>Are you sure you want to remove {{ data.member.user.displayName }} from this team ?</h5>
    <p><strong>You must choose another team member to take ownership
      <br>of the team data belonging to the user you want to remove.</strong>
    </p>
    <mat-form-field appearance="outline">
      <mat-select placeholder="Choose a new owner..." formControlName="altUserId">
        <mat-option *ngFor="let member of otherMembers" [value]="member.userId">
          {{ member.user.displayName }} ({{member.user.email}})
        </mat-option>
      </mat-select>
      <mat-error>Please select a user</mat-error>
    </mat-form-field>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button type="button" [mat-dialog-close]="false" cdkFocusInitial>Cancel</button>
    <button mat-button color="danger" type="submit" >Remove</button>
  </div>
</form>
