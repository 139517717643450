<ul class="teams-list">
  <li class="team-item" *ngFor="let team of teams">
    <a [routerLink]="['/team', team.id]" routerLinkActive="active" class="team-link">
      <ngx-avatar
        [src]="avatar(team)"
        [name]="fullName(team)"
        textSizeRatio="2.5"
        size="35"
        bgColor="#ffffff"
        fgColor="#227A95"
        ></ngx-avatar>
      {{team.name}}
    </a>
  </li>
  <li class="team-item" *ngIf="!hideNewTeamButton">
    <a class="team-link new-team-button" (click)="onNewTeamClicked()">
      <i class="material-icons add-team-icon">add_circle</i>
      Add Recipient
    </a>
  </li>
</ul>
