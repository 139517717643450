<!-- BEGIN help modal -->
<div class="modal fade" id="helpModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-notice">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="myModalLabel">Recipient Profile</h5>
        </div>
        <div class="modal-body">
          <div class="instruction">
            <div class="row">
              <div class="col-md-8 col-sm-7">
                <strong>1. Update the Profile</strong>
                <p>
                  The recipient profile provides information to your teams about the recipient. Optionally use the description to make important
                  information about the recipient easy to locate.
                </p>
              </div>
              <div class="col-md-4 col-sm-5">
                <div class="picture">
                  <img src="../../assets/img/image_placeholder.jpg" alt="Thumbnail Image" class="img-rounded img-responsive">
                </div>
              </div>
            </div>
          </div>
          <div class="instruction">
            <div class="row">
              <div class="col-md-8 col-sm-7">
                <strong>2. The Avatar</strong>
                <p>
                  eLivelihood users rely on the recipient avatar to quickly locate them within the apps. Best practice is to upload a headshot
                  that is square in shape and at least 200 pixels wide.
                </p>
              </div>
              <div class="col-md-4 col-sm-5">
                <div class="picture">
                  <img src="../../assets/img/image_placeholder.jpg" alt="Thumbnail Image" class="img-rounded img-responsive">
                </div>
              </div>
            </div>
          </div>
          <div class="instruction">
            <div class="row">
              <div class="col-md-8 col-sm-7">
                <strong>3. Password</strong>
                <p>
                  Please create a strong password to increase the security of your teams' and recipients' data. Your password must meet the
                  following requirements:
                </p>
              </div>
              <div class="col-md-4 col-sm-5">
                <div class="picture">
                  <img src="../../assets/img/image_placeholder.jpg" alt="Thumbnail Image" class="img-rounded img-responsive">
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <ul>
                    <li>Minimum length: 8 characters.</li>
                    <li>Must contain at least one number.</li>
                    <li>Must conatin at least one lower case character.</li>
                    <li>Must conatin at least one upper case character.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <p>
            <strong>Customer Service Center</strong>
          </p>
          <p>
            If you have more questions, please visit our customer service portal. Here you will find a knowledge base, FAQ, or you can
            open a support case with access to our cutomer service team. We're here to help!
          </p>
        </div>
        <div class="modal-footer text-center">
          <div class="row">
            <div class="col-sm-6 text-right">
              <button type="button" class="btn btn-default btn-round" data-dismiss="modal">
                <i class="material-icons">close</i> Close</button>
            </div>
            <div class="col-sm-6 text-left">
              <button type="button" class="btn btn-success btn-round" data-dismiss="modal" (click)="getHelp()">
                <i class="material-icons">help</i> Get Help</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END help modal -->