<div class="container-fluid nav-container">
  <nav class="row manager-nav">
    <div class="col-md-11 brand-and-nav">
      <div class="center-vertical mr-10">
        <a [routerLink]="['/manager']">
          <img
              src="./assets/img/logo_BH-1-170-white.png"
              alt="Bright Horizons"/>
        </a>
      </div>
      <div class="center-vertical">
        <ul class="nav navbar-nav text-uppercase">
          <li class="mr-3">
            <a [routerLink]="['/manager']" [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active">Overview</a>
          </li>
          <li>
            <a [routerLink]="['reports']" routerLinkActive="active">Reports</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-1 center-vertical justify-end">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>person</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <a mat-menu-item [routerLink]="['user']">Profile</a>
        <mat-divider></mat-divider>
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
    </div>
  </nav>
</div>
<router-outlet></router-outlet>
