import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { UserRoles } from 'models/user.model';

// providers
import { AuthService } from 'providers/auth.service';
import { UserService } from 'providers/user.service';

declare const $: any;

@Component({
  selector: 'app-layout',
  templateUrl: `./admin-layout.component.html`,
  styleUrls: ['./admin-layout.component.scss']
})

export class AdminLayoutComponent {
  private source = 'AdminLayoutComponent';

  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
  ) { }

  get userRole() {
    return this.userService.getUserSelfIfNotExists().pipe(
      map(u => u.role)
    );
  }

  get showReportsLinks() {
    return this.userRole.pipe(
      map(r => r === UserRoles.ADMIN || r === UserRoles.SYSTEM_MANAGER)
    )
  }

  logout() {
    this.authService.signOut()
      .subscribe(() => {
        this.router.navigate(['login']);
      });
  }
}
