import { Component, OnInit, OnDestroy, ViewChild, HostListener, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Location, PopStateEvent } from '@angular/common';

// rxjs
import { Subscription } from 'rxjs';
import {filter} from 'rxjs/operators';

// components
import { NavbarComponent } from './../../shared/navbar/navbar.component';
import PerfectScrollbar from 'perfect-scrollbar';

// providers
import { Utils } from './../../../providers/utils.service';
import { LocalData } from './../../../providers/local-data.service';
import { Logger } from './../../../providers/logger.service';
import { UserService } from './../../../providers/user.service';
import { TeamService } from './../../../providers/team.service';
import { SiteParameters } from './../../../environments/site.default';

declare const $: any;
@Component({
  selector: 'app-user-layout',
  templateUrl: `./user-layout.component.html`,
})
export class UserLayoutComponent implements OnInit, AfterViewInit {
  private source = 'UserLayoutComponent';
  private _router: Subscription;
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  private url: string;

  @ViewChild(NavbarComponent, { static: true }) navbar: NavbarComponent;
  constructor(
    private router: Router,
    private location: Location,
    private utils: Utils,
    private localData: LocalData,
    private logger: Logger,
    private userService: UserService,
    private teamService: TeamService
  ) {
    this.location = location;
  }

  ngOnInit() {
    // update local storage when user changes
    this.logger.logInfo(this.source, 'ngOnInit', 'subscribe: source: userTeamsSource');
    this.userService.userSource
      .subscribe((user) => {
        if (user) {
          if (user.logLevelWeb) {
            this.logger.logLevels = JSON.parse(user.logLevelWeb);
          }
          this.localData.setUser(user);
          this.logger.logTrace(this.source, 'ngOnInit', {userId: user.id}, 'source: userSource');
        }
      });

    // update local storage when teams changes
    this.logger.logInfo(this.source, 'ngOnInit', 'subscribe: source: userTeamsSource');
    this.teamService.userTeamsSource
      .subscribe((teams) => {
        if (teams) {
          this.localData.setTeams(teams);
          this.logger.logTrace(this.source, 'ngOnInit', null, 'source: userTeamsSource');
        }
      });

    const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
    const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY);
        }
      } else if (event instanceof NavigationEnd) {
        if (event.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else {
          window.scrollTo(0, 0);
        }
      }
    });
    this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      elemMainPanel.scrollTop = 0;
      elemSidebar.scrollTop = 0;
    });

    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      let ps = new PerfectScrollbar(elemMainPanel);
      ps = new PerfectScrollbar(elemSidebar);
    }

    this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.navbar.sidebarClose();
    });
  }

  ngAfterViewInit() {
    this.runOnRouteChange();
  }

  public isMap() {
    if (this.location.prepareExternalUrl(this.location.path()) === '/maps/fullscreen') {
      return true;
    } else {
      return false;
    }
  }

  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
      const elemMainPanel = <HTMLElement>document.querySelector('.main-panel');
      let ps = new PerfectScrollbar(elemMainPanel);
      ps = new PerfectScrollbar(elemSidebar);
      ps.update();
    }
  }

  // utils
  isMac(): boolean {
    let bool = false;
    if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
      bool = true;
    }
    return bool;
  }
}
