<div class="card card-profile text-left">
  <div class="row" style="margin-right: 0px; margin-left: 0px;">
    <div class="card-header card-header-icon" data-background-color="grey">
      <i class="material-icons">perm_identity</i>
    </div>
    <h4 class="card-title" style="margin-top: 10px; max-width: 310px !important;">
      {{ userTypeDisplay }} Profile
      <span
        style="font-size: 14px;"
        *ngIf="action === 'createUser' || action === 'createTeam'"
        ><br />(New {{ userTypeDisplay }})</span
      >
      <button
        *ngIf="
          action !== 'createUser' &&
          action !== 'createTeam' &&
          action !== 'viewRecipient' &&
          action !== 'updateTeam' &&
          user.id !== authorized_user.id &&
          pending
        "
        class="btn btn-warning btn-small"
        (click)="resendAlert(user)"
      >
        <i class="material-icons">warning</i>
        PENDING
      </button>
      <br>
      <span
        *ngIf="
          (action === 'viewRecipient' || action === 'updateTeam') &&
          user.id !== authorized_user.id &&
          pending
        "
        style="font-size: 14px; padding-bottom: 5px; margin-left: -7px !important;"
      >
        &nbsp; (Pending Recipient Tablet Login)
      </span>
    </h4>
    <div
      *ngIf="action === 'viewUser' || action === 'viewRecipient'"
      class="card-avatar pull-right"
      style="margin-right: 15px; margin-top: -80px;"
    >
      <img class="img" [src]="user.avatarUrl" />
    </div>
  </div>
  <div class="card-content">
    <form
      name="form"
      (ngSubmit)="submitForm(formUser)"
      #formUser="ngForm"
      novalidate
    >
      <p class="security-notice">Postal Code and Date of Birth will only be used as identity validation when you contact support.</p>
      <div
        class="row"
        *ngIf="team"
        [hidden]="
          !(
            action === 'createUser' ||
            action === 'viewUser' ||
            (action === 'updateTeamUser' && team)
          )
        "
      >
        <div class="col-sm-3">
          <mat-form-field hideRequiredMarker="true">
            <mat-placeholder>Team Role</mat-placeholder>
            <mat-select
              name="teamRole"
              [(ngModel)]="userTypeDisplay"
              #teamRole="ngModel"
              required
              [disabled]="readOnly"
            >
              <mat-option
                *ngFor="let role of getRolesChoices()"
                [value]="role"
              >
                {{ role }}
              </mat-option>
            </mat-select>
            <mat-error>Team role is required.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div
        class="row"
        *ngIf="team"
        [hidden]="
          !userIsTeamAdmin ||
          !(
            action === 'createUser' ||
            action === 'viewUser' ||
            (action === 'updateTeamUser' && team)
          )
        "
      >
        <div class="col-sm-3">
          <mat-form-field hideRequiredMarker="true">
            <mat-placeholder>Calendar</mat-placeholder>
            <mat-select
              name="calendarPermissions"
              [(ngModel)]="calendarPerm"
              #calendarPermissions="ngModel"
              required
              [disabled]="readOnly"
            >
              <mat-option
                *ngFor="let permission of teamConstants.TEAM_PERMISSIONS"
                [value]="permission"
              >
                {{ permission }}
              </mat-option>
            </mat-select>
            <mat-error>Team role is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-3">
          <mat-form-field hideRequiredMarker="true">
            <mat-placeholder>Media</mat-placeholder>
            <mat-select
              name="mediaPermissions"
              [(ngModel)]="mediaPerm"
              #mediaPermissions="ngModel"
              required
              [disabled]="readOnly"
              [class]="team.activeSubscriptionType.toString()"
            >
              <mat-option
                *ngFor="let permission of teamConstants.TEAM_PERMISSIONS"
                [value]="permission"
              >
                {{ permission }}
              </mat-option>
            </mat-select>
            <mat-error>Team role is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-3">
          <mat-form-field hideRequiredMarker="true">
            <mat-placeholder>Safe Keeper</mat-placeholder>
            <mat-select
              name="safekeeperPermissions"
              [(ngModel)]="safekeeperPerm"
              #safekeeperPermissions="ngModel"
              required
              [disabled]="readOnly"
            >
              <mat-option
                *ngFor="let permission of teamConstants.TEAM_PERMISSIONS"
                [value]="permission"
              >
                {{ permission }}
              </mat-option>
            </mat-select>
            <mat-error>Team role is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-3">
          <mat-form-field hideRequiredMarker="true">
            <mat-placeholder>Messages</mat-placeholder>
            <mat-select
              name="messagesPermissions"
              [(ngModel)]="messagesPerm"
              #messagesPermissions="ngModel"
              required
              [disabled]="readOnly"
            >
              <mat-option
                *ngFor="
                  let permission of teamConstants.TEAM_PERMISSIONS_MESSAGES
                "
                [value]="permission"
              >
                {{ permission }}
              </mat-option>
            </mat-select>
            <mat-error>Team role is required.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div
        class="row"
        *ngIf="
          userIsTeamAdmin &&
          (action === 'viewUser' || action === 'viewRecipient')
        "
      >
        <div
          *ngIf="action === 'viewUser' && canEdit"
          class="col-sm-12 clearfix"
        >
          <button class="btn btn-rose pull-right" (click)="goToEditUser()">
            Edit Membership
          </button>
        </div>
        <div
          *ngIf="action === 'viewRecipient' && (canEdit)"
          class="col-sm-12 clearfix"
        >
          <button class="btn btn-rose pull-right" (click)="goToEditRecipient()">
            Edit Profile
          </button>
        </div>
      </div>
      <div *ngIf="action === 'createTeam'" class="row">
        <div class="col-sm-6 col-sm-offset-6">
          <mat-checkbox
            [checked]="user.recipientNoEmail"
            (change)="toggleRecipientNoEmail()"
          ></mat-checkbox>
          Recipient does not have an email address.
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <mat-form-field hideRequiredMarker="true">
            <mat-placeholder>Display Name</mat-placeholder>
            <input
              matInput
              type="text"
              name="displayName"
              [(ngModel)]="user.displayName"
              #displayName="ngModel"
              required
              maxlength="250"
              [readonly]="readOnly || requiredProfileDataReadOnly()"
            />
            <mat-hint *ngIf="!user.displayName" align="start"
              >Typically just the first name or nickname.</mat-hint
            >
            <mat-error>Display name is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6" *ngIf="(action !== 'viewRecipient') || (action === 'viewRecipient' && !user.username)">
          <mat-form-field hideRequiredMarker="true">
            <mat-placeholder>Email Address</mat-placeholder>
            <input
              matInput
              type="email"
              name="email"
              [(ngModel)]="user.email"
              #email="ngModel"
              [required]="!user.username"
              [email]="!user.username"
              maxlength="100"
              [readonly]="readOnly || requiredProfileDataReadOnly() || (action === 'updateTeam')"
            />
            <mat-error>A valid email is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6" *ngIf="(action === 'createTeam' || action === 'updateTeam' || action === 'viewRecipient') && user.recipientNoEmail">
          <mat-form-field hideRequiredMarker="true">
            <mat-placeholder>Username</mat-placeholder>
            <input
              matInput
              type="text"
              name="username"
              [(ngModel)]="user.username"
              #username="ngModel"
              [required]="!user.email"
              maxlength="50"
              [readonly]="(readOnly || requiredProfileDataReadOnly())"
              pattern="[a-zA-Z0-9-]{8,50}"
            />
            <mat-error
              >Username must be between 8 and 50 alphanumeric characters, and
              dashes are allowed.</mat-error
            >
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="showAdditionalProfileData">
        <div class="col-sm-6">
          <mat-form-field hideRequiredMarker="true">
            <mat-placeholder>First Name</mat-placeholder>
            <input
              matInput
              required
              type="text"
              name="firstName"
              [(ngModel)]="user.firstName"
              #firstName="ngModel"
              maxlength="100"
              [readonly]="readOnly"
            />
            <mat-error>First name is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-6">
          <mat-form-field hideRequiredMarker="true">
            <mat-placeholder>Last Name</mat-placeholder>
            <input
              matInput
              required
              type="text"
              name="lastName"
              [(ngModel)]="user.lastName"
              #lastName="ngModel"
              maxlength="100"
              [readonly]="readOnly"
            />
            <mat-error>Last name is required.</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="showAdditionalProfileData">
        <div class="col-sm-4">
          <mat-form-field hideRequiredMarker="true">
            <mat-placeholder>Country</mat-placeholder>
            <mat-select name="addressCountry" [(ngModel)]="user.addressCountry" #addressCountry="ngModel" [readonly]="readOnly">
              <mat-option
                *ngFor="let country of countries" [value]="country.code"
                >{{country.name}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field hideRequiredMarker="true">
            <mat-placeholder>Postal Code</mat-placeholder>
            <input
              matInput
              type="text"
              name="addressPostalCode"
              [(ngModel)]="user.addressPostalCode"
              #addressPostalCode="ngModel"
              required
              [pattern]="postalCodePattern"
              minlength="5"
              maxlength="10"
              [readonly]="readOnly"
            />
            <mat-hint align="start">{{postalCodeHint}}</mat-hint >
          </mat-form-field>
        </div>
      </div>
      <div class="row" *ngIf="showAdditionalProfileData">
        <div class="col-sm-2">
          <mat-form-field hideRequiredMarker="true">
            <mat-placeholder>Phone Type</mat-placeholder>
            <mat-select
              name="phoneType"
              [(ngModel)]="phoneType"
              #state="ngModel"
            >
              <mat-option value="cell">
                Cell
              </mat-option>
              <mat-option value="home">
                Home
              </mat-option>
              <mat-option value="work">
                Work
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-sm-4">
          <mat-form-field *ngIf="phoneType == 'cell'" hideRequiredMarker="true">
            <mat-placeholder>Phone</mat-placeholder>
            <ngx-mat-intl-tel-input
              [ngClass]="{hidden: readOnly}"
              [preferredCountries]="['us', 'ca']"
              [enablePlaceholder]="true"
              [enableSearch]="true"
              format="national"
              name="cellPhone"
              [(ngModel)]="user.cellPhone"
              #cellPhone
              ></ngx-mat-intl-tel-input>
            <input
              *ngIf="readOnly"
              matInput
              type="text"
              readonly
              [value]="cellPhone.phoneNumber"
            />
            <mat-hint>e.g. {{cellPhone.selectedCountry.placeHolder}}</mat-hint>
            <mat-error *ngIf="formUser.form.controls['cellPhone']?.errors?.validatePhoneNumber">Invalid Number</mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="phoneType == 'work'" hideRequiredMarker="true">
            <mat-placeholder>Work Phone</mat-placeholder>
            <ngx-mat-intl-tel-input
              [ngClass]="{hidden: readOnly}"
              [preferredCountries]="['us', 'ca']"
              [enablePlaceholder]="true"
              [enableSearch]="true"
              format="national"
              name="workPhone"
              [(ngModel)]="user.workPhone"
              #workPhone
              ></ngx-mat-intl-tel-input>
            <input
              *ngIf="readOnly"
              matInput
              type="text"
              readonly
              [value]="workPhone.phoneNumber"
            />
            <mat-hint>e.g. {{workPhone.selectedCountry.placeHolder}}</mat-hint>
            <mat-error *ngIf="formUser.form.controls['workPhone']?.errors?.validatePhoneNumber">Invalid Number</mat-error>
          </mat-form-field>

          <mat-form-field *ngIf="phoneType == 'home'" hideRequiredMarker="true">
            <mat-placeholder>Home Phone</mat-placeholder>
            <ngx-mat-intl-tel-input
              [ngClass]="{hidden: readOnly}"
              [preferredCountries]="['us', 'ca']"
              [enablePlaceholder]="true"
              [enableSearch]="true"
              format="national"
              name="homePhone"
              [(ngModel)]="user.homePhone"
              #homePhone
              ></ngx-mat-intl-tel-input>
            <input
              *ngIf="readOnly"
              matInput
              type="text"
              readonly
              [value]="homePhone.phoneNumber"
            />
            <mat-hint>e.g. {{homePhone.selectedCountry.placeHolder}}</mat-hint>
            <mat-error *ngIf="formUser.form.controls['homePhone']?.errors?.validatePhoneNumber">Invalid Number</mat-error>
          </mat-form-field>
        </div>
        <div class="col-sm-4" [hidden]="dobHidden">
          <mat-form-field hideRequiredMarker="true">
            <input
              #dobInput
              [min]='minDate'
              [max]='maxDate'
              matInput
              appDateMask
              [matDatepicker]="dob"
              placeholder="Date of Birth"
              name="dob"
              [(ngModel)]="user.dob"
              [required]="dobRequired"
              date
              [readonly]="readOnly"
              (focus)="!readOnly && dob.open()"
            />
            <mat-datepicker-toggle
              matPrefix
              [for]="dob"
              [disabled]="readOnly"
            ></mat-datepicker-toggle>
            <mat-datepicker
              #dob
              [touchUi]="false"
              [startView]="'year'"
              [startAt]="dobStartDate"
              (closed)="dobInput.blur()"
            ></mat-datepicker>
            <mat-error>Must be a valid date.</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="['createTeam', 'updateTeam'].includes(action)" class="col-sm-4">
          <mat-form-field>
            <mat-label>Primary Language</mat-label>
            <mat-select
            name="primaryLanguage"
            #primaryLanguage="ngModel"
            [(ngModel)]="teamPrimaryLanguage" required>
              <mat-option
                *ngFor="let language of languages" [value]="language.code"
                >{{language.name}}</mat-option>
            </mat-select>
            <mat-error *ngIf="primaryLanguage.hasError('required')">Language is required</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="isCoach" class="col-sm-8">
        <mat-form-field>
          <mat-label>Bio</mat-label>
          <textarea
            matInput
            name="bio"
            [(ngModel)]="user.bio"
            rows="10"
            placeholder="Bio"></textarea>
        </mat-form-field>
        </div>
      </div>
      <div *ngIf='userPolicies'>
        <div class='col-sm-6' *ngIf='userPolicies.teamTwoFactorState == policiesState[1].value && !Recipient'>
          <mat-form-field>
            <mat-label>Two Factors</mat-label>
            <mat-select name='twoFactorState' (selectionChange)='handleUserTwoFactorsStateChanged($event)' [(ngModel)]='userPolicies.twoFactorState'>
              <mat-option
                *ngFor="let s of states" [value]="s.value"
              >{{s.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class='col-sm-6' *ngIf='userPolicies.teamPasswordRotationState === policiesState[1].value && !Recipient'>
          <mat-form-field>
            <mat-label>Reset Password after {{userPolicies.passwordRotationDuration}} days</mat-label>
            <mat-select name='passwordRotationState' (selectionChange)='handleUserPasswordRotationStateChanged($event)' [(ngModel)]='userPolicies.passwordRotationState'>
              <mat-option
                *ngFor="let s of states" [value]="s.value"
              >{{s.label}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div
        class="row margin-top-10"
        *ngIf="showAdditionalProfileData && showAcceptTermsOfUse"
      >
        <div class="col-sm-12 padding-top-10">
          &nbsp;
          <div class=" pull-right">
            <mat-checkbox
              [checked]="acceptTermsOfUse"
              (change)="toggleTermsOfUse()"
              [disabled]="
                user.tosAccepted &&
                user.tosAccepted.toString() !== '0001-01-01T00:00:00'
              ">
            </mat-checkbox>
            I accept the
            <a target="_blank" [routerLink]="['/terms']">eLivelihood Terms of Use.</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class=" pull-right">
          <a target="_blank" [routerLink]="['/privacy']">Privacy Policy.</a>
        </div>
      </div>
      </div>
      <div class="row" *ngIf="!readOnly">
        <div class="col-sm-12">
          <!-- <button class="btn btn-default btn-fill" [disabled]="disableForm" (click)="reset(formUser)">Reset</button> -->
          <span class="pull-right">
            <button
              *ngIf="showCancelButton"
              type="button"
              class="btn"
              [disabled]="disableForm"
              (click)="canceled.emit()"
            >
              Cancel
            </button>
            <button
              type="submit"
              class="btn btn-el-blue-med"
              [disabled]="disableForm"
            >
              Save
            </button>
             <!-- <button
               *ngIf='userIsTeamAdmin && user.email'
               type="button"
               class="btn btn-danger"
               [disabled]="disableForm"
               (click)='handleRecipientRemoveEmailClick()'
             >
              Remove Email
            </button> -->
          </span>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- BEGIN progress modal -->
<div
  class="modal fade"
  id="progressIndicator"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-notice">
    <div class="progress-modal">
      <div class="loader">
        <svg class="circular" viewBox="25 25 50 50">
          <circle
            class="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            stroke-width="4"
            stroke-miterlimit="10"
          />
        </svg>
      </div>
    </div>
  </div>
</div>
<!-- END progress modal -->

<!-- pending modal -->
<div
  class="modal fade"
  id="pendingModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
  #pendingModal
>
  <div class="modal-dialog modal-small ">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-hidden="true"
        >
          <i class="material-icons">clear</i>
        </button>
      </div>
      <div class="modal-body text-center">
        <h5>
          {{ selectedResendUser.displayName }} has not yet signed in to
          eLivelihood.
          <span *ngIf="!user.recipientNoEmail"
            >Would you like to resend the email invitation?</span
          >
        </h5>
      </div>
      <div class="modal-footer text-center">
        <button type="button" class="btn btn-simple" data-dismiss="modal">
          No, Cancel
        </button>
        <button
          *ngIf="!user.recipientNoEmail"
          type="button"
          class="btn btn-success btn-simple"
          (click)="resendInvite(selectedResendUser)"
        >
          Yes
        </button>
      </div>
    </div>
  </div>
</div>
<!-- end pending modal -->
