
export enum PasswordUpdateReasons{
  TeamOwnerChangingRecipient,
  Self,
  Admin,
  Support,
}

export class PasswordUpdateModel{
  public password: string;
  public confirmPassword: string;
  public userId: string;
  public teamId?: string;
  public reason: PasswordUpdateReasons
}
