<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-10 col-md-offset-1">
                <div class="row d-flex">
                    <div class="col-md-5">
                        <img src="assets/img/coach-intro-spot.png">
                    </div>
                    <div class="col-md-7 text-center">
                        <h3 class="coach-intro-title">Contact Your Personal <br> Care Coach Today</h3>
                        <div class="coach-button-container">
                            <a [routerLink]="['/coach-survey']">
                                <button class="btn btn-coach">Connect Now</button>
                            </a>
                        </div>
                        <div class="coach-intro-description">
                            <strong>
                                Connect with an experienced Aging Life Professional to advise you 
                                through every phase of the elder care journey.
                            </strong>
                        </div>
                        <div class="coach-button-container">
                            <button class="btn btn-coach-secondary">833-243-5337</button>
                        </div>
                    </div>
                </div>
                <div class="row coach-intro-row">
                    <div class="coach-intro-card">
                        <div class="coach-intro-content">
                            <div class="icon">
                                <i class="material-icons">live_help</i>
                            </div>
                            <h3>Care Consultants</h3>
                            <ul class="list">
                                <li>Scheduled Appointments</li>
                                <li>Family Meetings</li>
                                <li>On-going Check-ins</li>
                            </ul>
                        </div>
                    </div>
                    <div class="coach-intro-card">
                        <div class="coach-intro-content">
                            <div class="icon">
                                <i class="material-icons">fact_check</i>
                            </div>
                            <h3>In-Home Assessments</h3>
                            <ul class="list">
                                <li>Safety Assessment</li>
                                <li>Comprehensive Care Plan</li>
                                <li>Local Recommendations</li>
                            </ul>
                        </div>
                    </div>
                    <div class="coach-intro-card">
                        <div class="coach-intro-content">
                            <div class="icon">
                                <i class="material-icons">person_search</i>
                            </div>
                            <h3>Research & Referrals</h3>
                            <ul class="list">
                                <li>Long Term Care</li>
                                <li>Temporary Services</li>
                                <li>Financial Assistance Referrals</li>
                            </ul>
                        </div>
                    </div>
                    <div class="coach-intro-card">
                        <div class="coach-intro-content">
                            <div class="icon">
                                <i class="material-icons">aspect_ratio</i>
                            </div>
                            <h3>Legal Advice & Referrals</h3>
                            <ul class="list">
                                <li>Advance Directives</li>
                                <li>Wills</li>
                                <li>Entitlement Applications</li>
                            </ul>
                        </div>
                    </div>
                    <div class="coach-intro-card">
                        <div class="coach-intro-content">
                            <div class="icon">
                                <i class="material-icons">payments</i>
                            </div>
                            <h3>Financial Education</h3>
                            <ul class="list">
                                <li>Budgeting</li>
                                <li>Long Term Care Planning</li>
                                <li>Reserve Mortgages</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

