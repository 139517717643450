import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { UserRoles } from 'models/user.model';
import { UserService } from 'providers/user.service';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const allowedRoles = route.data.allowedRoles;

    if (allowedRoles == null || allowedRoles.length === 0) {
      return of(true);
    }

    let role: number;
    return this.userService.getUserSelfIfNotExists()
      .pipe(
        map(userSelf => {
          role = userSelf.role;
          return allowedRoles.includes(userSelf.role)
        }),
        tap(isAuthorized => {
          if (!isAuthorized) {
            this.router.navigate(['/']);
          }
        })
      );
  }
}
