import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';

// rxjs
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';

// models
import {UserRoles} from '../models/user.model';

// providers
import {UserService} from '../providers/user.service';

@Injectable({
  providedIn: 'root'
})
export class DefaultPageGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private router: Router
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userService.getUserSelfIfNotExists()
      .pipe(
        map(userSelf => {
          this.routeToDefaultPage(userSelf.role);
          return false;
        }),
      );
  }

  routeToDefaultPage(role: number) {
    switch (role) {
      case UserRoles.COACH:
        this.router.navigate(['coach']);
        break;
      case UserRoles.COACHING_ADMIN:
        this.router.navigate(['coach']);
        break;
      case UserRoles.ADMIN:
      case UserRoles.SUPPORT:
        this.router.navigate(['admin']);
        break;
      case UserRoles.SYSTEM_MANAGER:
        this.router.navigate(['manager']);
        break;
      case UserRoles.CLIENT_MANAGER:
        this.router.navigate(['client-manager']);
        break;
      case UserRoles.USER:
        this.router.navigateByUrl('/team/new?team');
        break;
    }
  }
}
