import { Component } from '@angular/core';

// components
import { AuthService } from 'providers/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-coach-layout',
  templateUrl: './coach-layout.component.html',
  styleUrls: ['./coach-layout.component.scss']
})
export class CoachLayoutComponent {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  logout() {
    this.authService.signOut()
      .subscribe(() => {
        this.router.navigate(['login']);
      });
  }
}
