import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[appSecondPasswordValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: SecondPasswordValidatorDirective,
    multi: true
  }]
})
export class SecondPasswordValidatorDirective implements Validator {

  @Input() password1;

  constructor() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value?.localeCompare(this.password1.model)) {
      return {
        'valid': false
      };
    } else {
      return null;
    }
  }

}
