<h1 mat-dialog-title>Enter username</h1>
<form [formGroup]='removeMailForm' (ngSubmit)='handleSubmit()'>
  <div mat-dialog-content>
    <div>
      <mat-form-field>
        <mat-placeholder>Username</mat-placeholder>
        <input formControlName="username" matInput
                required
        >
        <mat-error *ngIf="removeMailForm.get('username').hasError('required')">Username required</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions align="end">
    <button color="secondary" mat-button [mat-dialog-close]="" cdkFocusInitial>Cancel</button>
    <button color="primary" type='submit' mat-button >Save</button>
  </div>
</form>
