import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { SiteParameters } from 'environments/site.default';
import { NgForm } from '@angular/forms';
import { RecaptchaComponent } from 'ng-recaptcha';

// rxjs
import { mergeMap } from 'rxjs/operators';

// models
import { DeviceData } from '../../models/device.model';
import { UserConstants, UserModel, UserRoles } from '../../models/user.model';
import { TeamConstants, TeamModel } from '../../models/team.model';

// providers
import { AuthService } from '../../providers/auth.service';
import { UserService } from '../../providers/user.service';
import { TeamService } from '../../providers/team.service';
import { Utils } from '../../providers/utils.service';
import { LocalData } from '../../providers/local-data.service';
import { Logger } from '../../providers/logger.service';

import * as moment from 'moment';
import { environment } from 'environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { ContactUsComponent } from 'app/shared/contact-us/contact-us.component';
import {
  ApiResponseModel,
  ShouldChangePasswordModel,
} from '../../models/api-response.model';
import {
  PasswordUpdateModel,
  PasswordUpdateReasons,
} from '../../models/password-update-model';
import { UpdatePasswordRotationComponent } from './update-password-rotation/update-password-rotation.component';
import { MatSnackBar } from '@angular/material/snack-bar';

declare var $: any;

@Component({
  selector: 'app-login-cmp',
  templateUrl: `login.component.html`,
  styleUrls: [`login.component.scss`]
})
export class LoginComponent implements OnInit {
  private source = 'LoginComponent';
  private userConstants: UserConstants = new UserConstants();
  public user: UserModel;
  public teams: TeamModel[] = new Array<TeamModel>();
  public model: any = {};
  public disableForm = false;
  public formErrMsg: string = null;
  public formChangePasswordErrMsg: string = null;
  public showLocked = false;
  public display = 'login';
  public acceptTermsOfUse = false;
  private deviceInfo: DeviceInfo;
  private returnUrl = '';
  public walkthroughUrl = SiteParameters.URLS.WALKTHROUGH_URL;
  private loginToken = "";
  private referer: string = null;
  public landingPageUrl = environment.landingPageUrl;
  public resetEmail: string;
  public showPassword = false;
  @ViewChild('f1') private loginForm: NgForm;
  @ViewChild('recaptcha') private recaptcha: RecaptchaComponent;

  constructor(
    private element: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService,
    private authService: AuthService,
    private userService: UserService,
    private teamService: TeamService,
    private logger: Logger,
    private utils: Utils,
    private localData: LocalData,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.setDeviceData();
    this.returnUrl = this.route.snapshot.queryParams['return_url'];
    this.referer = this.route.snapshot.queryParams['referer'];
    if (this.route.snapshot.queryParams['email']) {
      this.model.email = this.route.snapshot.queryParams['email'];
    }

    if (this.route.snapshot.queryParams['signup']) {
      this.display = 'register';
    }

    if (this.isLoggedIn() && !this.route.snapshot.queryParams['logout']) {
      this.user = this.localData.getUser();
      if (
        this.user.state === this.userConstants.REGISTERED ||
        this.user.state === this.userConstants.RESET_PASS
      ) {
        this.display = 'changepass';
      } else {
        let returnUrl = this.returnUrl || '/';
        const teams = this.localData.getTeams();
        if (teams && teams.length > 0) {
          returnUrl = '/team/' + teams[0].id;
        }
        this.router.navigateByUrl(returnUrl);
      }
    }

    if (this.route.snapshot.queryParams['logout']) {
      this.authService.signOut().subscribe();
    }
    if(this.route.snapshot.queryParams['token']){
      this.loginToken = this.route.snapshot.queryParams['token'];
      this.display = 'invitedRecaptcha';
    }

    setTimeout(function () {
      $('.card').removeClass('card-hidden');
    }, 700);

    this.dialog.closeAll()
  }

  handleInvitedRecaptchaResolved = () => {
    setTimeout(() => {
      this.login()
    }, 500);
  }

  public isLoggedIn(): boolean {
    return this.localData.isLoggedIn();
  }

  get hasAcceptedTOS() {
    return (
      this.user.tosAccepted &&
      this.user.tosAccepted.toString() !== '0001-01-01T00:00:00'
    );
  }

  public login() {
    this.disableForm = true;
    this.formErrMsg = null;

    if (!this.isRecaptchaValid()) {
      return;
    }

    this.setDeviceData();
    this.logger.logInfo(this.source, 'login', 'login');
    this.authService
      .login(
        this.model.email,
        this.model.password,
        this.localData.getDevice(),
        this.utils.getDeviceUuid(),
        this.model.recaptchaToken,
        this.loginToken
      )
      .pipe(
        mergeMap((login_response) => {
          if ((login_response as ApiResponseModel).isError) {
            let resp = login_response as ApiResponseModel;
            if (
              resp.data &&
              (resp.data as ShouldChangePasswordModel).passwordExpired
            ) {
              this.resetEmail = (resp.data as ShouldChangePasswordModel).email;
              throw {
                status: 423,
              };
            }
            console.log(login_response);
            this.showLocked = true;
            this.formErrMsg = '';
            throw {
              status: 418,
            };
          }
          //this.showLocked = false
          const loggedIn = login_response as boolean;
          this.localData.login(loggedIn);
          this.logger.logInfo(this.source, 'login', 'getUserSelf');
          return this.userService.getUserSelfIfNotExists();
        }),
        mergeMap((user_api) => {
          this.user = user_api;
          this.localData.setUser(user_api);
          if(this.loginToken){
            throw {
              status: 1000,
            };
          }
          if (user_api.logLevelWeb) {
            this.logger.logLevels = JSON.parse(user_api.logLevelWeb);
          }
          this.logger.logTrace(
            this.source,
            'login',
            {userId: this.user.id},
            'teamService: sourceUserTeamData'
          );
          // source and expand user and team data
          const fetchOnlyCurrentTeam = user_api.role !== UserRoles.USER;
          return this.teamService.sourceUserTeamData(
            user_api,
            fetchOnlyCurrentTeam
          );
        })
      )
      .subscribe(
        (user_and_teams_api) => {
          this.resetRecaptcha();
          if (user_and_teams_api.teams.length > 0) {
            let t = user_and_teams_api.teams[0];
            let u = t.teamUsers.find((x) => x.userId === this.user.id);
            let tc = new TeamConstants();
            if (u.teamRoleId === tc.RECIPIENT) {
              this.localData.clearRecipient();
              this.disableForm = false;
              this.formErrMsg = `Please use ${
                SiteParameters.SITE_PREFIX === '.bh'
                  ? 'Bright Horizons Care Assist'
                  : 'eLivelihood - Recipient'
              } app to login`;
              return;
            }
          }
          this.teams = user_and_teams_api.teams;
          this.logger.logTrace(
            this.source,
            'login',
            {userId: this.user.id},
            'teamService: sourceUserTeamData'
          );
          if (user_and_teams_api.teams && user_and_teams_api.teams.length > 0) {
            this.localData.setTeams(user_and_teams_api.teams);
          }
          if (
            this.route.snapshot.queryParams['token'] ||
            this.user.state === this.userConstants.REGISTERED ||
            this.user.state === this.userConstants.INVITED ||
            this.user.state === this.userConstants.RESET_PASS
          ) {
            this.display = 'changepass';
          } else if (!this.hasAcceptedTOS) {
            this.acceptTermsOfUse = false;
            this.display = 'AcceptTOS';
          } else {
            if (user_and_teams_api.teams.length < 1) {
              if (this.user.walkthrough < 6) {
                this.router.navigate([this.walkthroughUrl]);
              } else {
                this.router.navigateByUrl('/team/new');
              }
            } else {
              if (this.user.walkthrough < 6) {
                this.router.navigate([this.walkthroughUrl]);
              } else {
                if (this.returnUrl != undefined) {
                  this.router.navigateByUrl(this.returnUrl);
                } else {
                  this.router.navigateByUrl('/');
                }
              }
            }
          }
          this.disableForm = false;
        },
        (err) => {
          this.resetRecaptcha();
          this.logger.logError(
            this.source,
            'login',
            err,
            'teamService: sourceUserTeamData'
          );
          if (err.status === 401) {
            this.logger.logError(this.source, 'login', null, 'Invalid Username/Password')
           if(!this.loginToken){
             this.formErrMsg = 'Invalid Username/Password';
           }else{
             this.router.navigate(['/login'], {
             }).then(() => {
               window.location.reload()
             });
           }

          } else if (err.status === 422) {
            // The error message is "Server Error" because the user can only
            // submit the form if reCAPTCHA was solved client-side; which means
            // that the only case where server side validation will fail is when
            // the keys (site key and secret key) are set incorrectly.
            this.formErrMsg = 'Error: Server Error';
            this.logger.logError(this.source, 'login', null, 'Error: Server Error')
            this.handleApiError('login', err);
          } else if (err.status === 423) {
            const dialogRef = this.dialog.open(
              UpdatePasswordRotationComponent,
              {
                data: this.resetEmail,
                autoFocus: false,
              }
            );
            dialogRef.afterClosed().subscribe((r: ApiResponseModel) => {
              if (r) {
                this.snackBar.open(r.message, 'close', {
                  duration: 2000,
                });
              }
            });
          }
          else if(err.status === 1000) {
            this.display = 'changepass';
            this.formErrMsg = ''
          } else {
            // 418 is an unused status code: https://www.iana.org/assignments/http-status-codes/http-status-codes.xhtml
            if (err.status !== 418)
              this.formErrMsg = 'Error: Service Unavailable';
            this.logger.logError(this.source, 'login', null, 'Error: Service Unavailable')
            this.handleApiError('login', err);
          }
          this.resetRecaptcha();
          this.disableForm = false;
        }
      );
  }

  private resetRecaptcha() {
    this.recaptcha.reset();
    this.model.recaptchaToken = undefined;
  }

  changePassword() {
    if (this.model.password1 !== this.model.password2) {
      this.formChangePasswordErrMsg = 'Passwords do not match.';
      return;
    }
    if (this.model.password === this.model.password1) {
      this.formChangePasswordErrMsg =
        'Your new password cannot be the same as your temporary password.';
      return;
    }

    this.disableForm = true;
    this.formChangePasswordErrMsg = null;
    this.logger.logInfo(
      this.source,
      'updatePassword',
      'userService: updatePassword'
    );
    let updateModel = new PasswordUpdateModel();
    updateModel.password = this.model.password1;
    updateModel.confirmPassword = this.model.password2;
    updateModel.userId = this.user.id;
    updateModel.reason = PasswordUpdateReasons.Self;
    this.userService.updatePassword(updateModel).subscribe(
      (update_response) => {
        if ((update_response as ApiResponseModel).isError) {
          this.formChangePasswordErrMsg = (update_response as ApiResponseModel).message;
          throw {
            status: 418,
          };
        }
        this.disableForm = false;
        this.model.password2 = '';
        if (this.localData.getUser().state === this.userConstants.INVITED) {
          this.userService.getUserSelf().subscribe(
            (user_api) => {
              this.model.password1 = '';
              this.userService.userSource.next(user_api);
              if (!this.hasAcceptedTOS) {
                this.acceptTermsOfUse = false;
                this.display = 'AcceptTOS';
              } else {
                if (user_api.walkthrough < 6) {
                  this.router.navigate([this.walkthroughUrl]);
                } else {
                  this.router.navigateByUrl('/user');
                }
              }
            },
            (err) => {
              if (this.user.walkthrough < 6) {
                this.router.navigate([this.walkthroughUrl]);
              } else {
                this.router.navigateByUrl('/user');
              }
              this.model.password1 = '';
            }
          );
        } else {
          if (this.user.role === 5) {
            this.acceptTermsOfUse = false;
            this.display = 'AcceptTOS';
            return;
          }
          if (this.user.walkthrough < 6) {
            this.router.navigate([this.walkthroughUrl]);
          } else {
            if (this.teams.length < 1) {
              this.router.navigateByUrl('/team/new');
            } else {
              this.router.navigateByUrl('/team/' + this.teams[0].id);
            }
          }
        }
        this.disableForm = false;
      },
      (err) => {
        this.userService.resetPasswordComplexity();
        this.formChangePasswordErrMsg = 'The change password operation failed. Try again.';
        this.handleApiError('updatePassword', err);
        this.disableForm = false;
      }
    );
  }

  goToRegister() {
    // temporary
    if (SiteParameters.SITE_PREFIX === '.bh') {
      this.router.navigateByUrl('/register');
      return;
    }
    this.formErrMsg = null;
    this.model.password = '';
    this.display = 'register';
  }

  register() {
    const idx = this.model.email.indexOf('@');
    this.userService
      .isValidDomain(this.model.email.substring(idx + 1))
      .subscribe(
        (status) => {},
        (err) => {
          this.showNotification(
            'top',
            'center',
            'danger',
            true,
            null,
            null,
            'Disallowed email address @domain.'
          );
          return false;
        }
      );
  }

  doAction() {
    this.disableForm = true;
    this.formErrMsg = null;
    this.logger.logInfo(this.source, 'register', 'userService: register');
    this.userService
      .register(
        this.model.email,
        this.model.displayName,
        this.model.password,
        this.localData.getDevice(),
        this.utils.getDeviceUuid(),
        this.model.promoCode
      )
      .pipe(
        mergeMap((loggedIn) => {
          this.localData.login(loggedIn);
          this.logger.logInfo(this.source, 'register', 'getUserSelf');
          return this.userService.getUserSelf();
        }),
        mergeMap((user_api) => {
          this.user = user_api;
          this.localData.setUser(user_api);
          // source and expand user and team data
          this.logger.logInfo(
            this.source,
            'register',
            'teamService: sourceUserTeamData'
          );
          return this.teamService.sourceUserTeamData(this.localData.getUser());
        })
      )
      .subscribe(
        (user_and_teams_api) => {
          this.logger.logTrace(
            this.source,
            'register',
            null,
            'teamService: sourceUserTeamData'
          );
          this.teams = user_and_teams_api.teams;
          if (user_and_teams_api.teams.length < 1) {
            if (this.user.walkthrough < 6) {
              this.router.navigate([this.walkthroughUrl]);
            } else {
              this.router.navigateByUrl('/team/new');
            }
          } else {
            this.router.navigateByUrl(
              '/team/' + user_and_teams_api.teams[0].id
            );
          }
          this.disableForm = false;
        },
        (err) => {
          if (err.status === 409) {
            this.formErrMsg = 'You already have an account using this email.';
            this.disableForm = false;
          } else {
            this.formErrMsg = 'Error: (' + err.status + ')';
            this.handleApiError('register', err);
            this.disableForm = false;
          }
        }
      );
  }

  forgotPassword() {
    this.disableForm = true;
    this.formErrMsg = null;
    if (!this.isRecaptchaValid()) {
      return;
    }
    this.logger.logInfo(
      this.source,
      'forgotPassword',
      'userService: resetPassword'
    );
    this.userService
      .resetPassword(this.model.email, this.model.recaptchaToken)
      .subscribe(
        (status) => {
          this.disableForm = false;
          this.showNotification(
            'top',
            'center',
            'primary',
            true,
            null,
            null,
            'If we have an account on file with the email address ' +
              this.model.email +
              ', you will receive a temporary password soon.'
          );
          this.display = 'login';
          this.model.password = '';
          this.resetRecaptcha();
          this.disableForm = false;
        },
        (err) => {
          if (err.status === 404) {
            this.showNotification(
              'top',
              'center',
              'primary',
              true,
              null,
              null,
              'If we have an account on file with the email address ' +
                this.model.email +
                ', you will receive a temporary password soon.'
            );
            this.display = 'login';
            this.model.password = '';
            this.resetRecaptcha();
            this.disableForm = false;
          } else {
            this.formErrMsg = 'Error: (' + err.status + ')';
            this.handleApiError('forgotPassword', err);
            this.resetRecaptcha();
            this.disableForm = false;
          }
        }
      );
  }

  private isRecaptchaValid() {
    if (typeof this.model.recaptchaToken === 'undefined') {
      this.disableForm = false;
      this.formErrMsg = 'Please solve the reCAPTCHA to continue.';
      return false;
    }
    return true;
  }

  showForgotPassword() {
    this.display = 'forgotpass';
    this.model.password = '';
    this.formErrMsg = null;
    this.resetRecaptcha();
    this.disableForm = false;
  }

  backToLogin() {
    this.formErrMsg = null;
    this.model.password = '';
    this.resetRecaptcha();
    this.display = 'login';
  }

  showContactInfo() {
    this.dialog.open(ContactUsComponent, {
      width: '500px',
    });
  }
  public get year(){
    return moment().year()
  }
  setDeviceData() {
    const deviceInfo = this.deviceService.getDeviceInfo();
    console.log(deviceInfo);
    const deviceData: DeviceData = new DeviceData(
      deviceInfo.browser,
      deviceInfo.browser_version,
      deviceInfo.os,
      deviceInfo.os_version,
      'web',
      deviceInfo.device?.toLowerCase(),
      deviceInfo.userAgent,
      null,
      null,
      this.localData.getFirebaseMessagingToken()
    );
    this.localData.setDevice(deviceData);
  }

  // utils
  showNotification(
    from: any,
    align: any,
    type: any,
    allow_dismiss: boolean,
    url: string,
    target: string,
    message: string
  ) {
    let delay = 5000;
    let timer = 1000;
    if (!allow_dismiss) {
      delay = 0;
      timer = 0;
    }
    $.notify(
      { icon: 'notifications', message: message, url: url, target: target },
      {
        type: type,
        delay: delay,
        timer: timer,
        allow_dismiss: allow_dismiss,
        placement: { from: from, align: align },
      }
    );
  }

  handleApiError(func, err) {
    // TODO
  }
  toggleTermsOfUse() {
    this.acceptTermsOfUse = !this.acceptTermsOfUse;
    if (this.acceptTermsOfUse) {
      this.user.tosAccepted = moment().utc().toDate();
    }
  }
  TosAccepted() {
    this.userService.acceptTAC().subscribe((x) => {
      this.router.navigate(['/']);
    });
  }
}
