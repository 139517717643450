import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { VerificationDialogComponent } from 'app/verification/verification-dialog/verification-dialog.component';
import { UserService } from 'providers/user.service';
import { Observable, of } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { bh_card_modal_width } from 'variables/dialog';

@Injectable({
  providedIn: 'root'
})
export class MultiFactorAuthGuard implements CanActivate {

  constructor(
    private userService: UserService,
    public dialog: MatDialog
    ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

     return this.userService.needsAnotherVerification().pipe(
      mergeMap(({required, phonePreview, phoneVerified}) => {
        if (required) {
          const dialog = this.dialog.open(VerificationDialogComponent, {
            data: {
              phonePreview,
              phoneVerified
            },
            maxWidth: bh_card_modal_width,
            disableClose: true
          });
          return dialog.afterClosed().pipe(map(res => !!res));
        }

        return of(true);
      }),
    );
  }
}
