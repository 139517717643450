import { LocalData } from '../providers/local-data.service';
import { environment } from '../environments/environment';
import { ChangeDetectorRef, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import {Title} from "@angular/platform-browser";
import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { SiteParameters } from 'environments/site.default';
import {DOCUMENT} from '@angular/common';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { AuthService } from '../providers/auth.service';
import { Router } from '@angular/router';
import { Keepalive } from '@ng-idle/keepalive';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $: any;

@Component({
    selector: 'app-my-app',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
  firebaseApp: firebase.app.App;
  messaging: firebase.messaging.Messaging;
  idleState = "NOT_STARTED";

  constructor (
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private localData: LocalData,
    private renderer: Renderer2,
    private titleService:Title,
    private idle: Idle, cd: ChangeDetectorRef, private keepalive: Keepalive,
    private router: Router,
    private authService: AuthService,
    private snackBar: MatSnackBar,
  ) {
    this.renderer.addClass(document.body, SiteParameters.SITE_PREFIX === '.bh' ? 'app-bh' : 'app-el');
    this.idle.setIdle(environment.timeoutInSeconds);
    this.idle.setTimeout(false);
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.idle.onIdleStart.subscribe(() => {
      if (!this.localData.isLoggedIn()) {
        return;
      }
      this.idleState = "IDLE";
      this.logout();
    });

    this.idle.onIdleEnd.subscribe(() => {
      this.idleState = "NOT_IDLE";
      cd.detectChanges();
    });

    this.idle.watch()
    this.keepalive.interval(10);
    this.keepalive.onPing.subscribe(() => {
      if (!this.localData.isLoggedIn()) {
        return;
      }
      const currentDate = new Date().getTime();
      const lastHttpRequestCall = this.localData.getLastHttpRequestDate()?.getTime()
      const elapsedTime = lastHttpRequestCall ? Math.abs(currentDate - lastHttpRequestCall): 0;
      if(elapsedTime >= environment.timeoutInSeconds * 1000){
        this.authService.canRefreshToken().subscribe((canRefreshToken) => {
          if (!canRefreshToken) {
            this.logout();
          }
        })
      }
    });
    this.keepalive.start();
  }

  ngOnInit() {
    $.material.init();
    try {
      this.firebaseApp = firebase.initializeApp(environment.firebaseConfig);
      this.messaging = this.firebaseApp.messaging();
      this.messaging.getToken(
        {vapidKey: environment.FIRE_BASE_WebPush_Certs}
      )
      .then(x => {
        this.localData.setFirebaseMessagingToken(x);
      });
      this.messaging.onMessage((payload) => {
        console.log('Message received. ', payload);
      });
    } catch (err){
        console.log(err);
    }
    this.setupApp()
  }

  setupApp =() => {
    switch (SiteParameters.SITE_PREFIX){
      case '.bh':{
        this.titleService.setTitle('BrightHorizons')
        this._document.getElementById('appFavicon').setAttribute('href', 'favicon-bh.ico');
        break;
      }
      default: {
        this._document.getElementById('appFavicon').setAttribute('href', 'favicon.ico');
        this.titleService.setTitle('eLivelihood')
        break;
      }
    }
  }

  private logout() {
    this.authService.signOut().subscribe(() => {
      this.snackBar.open('Authentication expired', 'close', {
        duration: 4000,
      });
      this.router.navigate(['login']);
    });
  }
}
