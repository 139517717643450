import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

// providers
import { Utils } from './../../../providers/utils.service';
import { Logger } from './../../../providers/logger.service';
import { AuthService } from 'providers/auth.service';
import { TeamService } from '../../../providers/team.service';
import { LocalData } from '../../../providers/local-data.service';
import { environment } from 'environments/environment';
import { HttpParams } from '@angular/common/http';
import { UserService } from 'providers/user.service';

const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};

declare var $: any;
@Component({
  selector: 'app-navbar-cmp',
  templateUrl: `navbar.component.html`,
  styleUrls: [`navbar.component.scss`]
})

export class NavbarComponent implements OnInit {
  private source = 'NavbarComponent';
  private nativeElement: Node;
  private toggleButton: any;
  private sidebarVisible: boolean;
  public tutorialTitle: string;
  public hasTutorial = false;
  public notifications: number;
  public tickets: number;
  public hideTutorialVideo = true;

  constructor(
    private element: ElementRef,
    private router: Router,
    private utils: Utils,
    private logger: Logger,
    private authService: AuthService,
    private teamService: TeamService,
    public userService: UserService,
    private localData: LocalData
  ) {
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.logger.logInfo(this.source, 'ngOnInit', 'subscribe: source: pageTitleSource');

    const currentRoute = this.router.url;
    if (currentRoute.includes('/team/')) {
      this.hasTutorial = true
      this.tutorialTitle = 'Overview'
    }
    if (currentRoute.includes('/chat/')) {
      this.hasTutorial = true
      this.tutorialTitle = 'Messages'
    }
    if (currentRoute.includes('/calendar')) {
      this.hasTutorial = true
      this.tutorialTitle = 'Calendar'
    }

    const navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    if ($('body').hasClass('sidebar-mini')) {
      misc.sidebar_mini_active = true;
    }
    if ($('body').hasClass('hide-sidebar')) {
      misc.hide_sidebar_active = true;
    }
    $('#minimizeSidebar').click(function () {
      if (misc.sidebar_mini_active === true) {
        $('body').removeClass('sidebar-mini');
        misc.sidebar_mini_active = false;

      } else {
        setTimeout(function () {
          $('body').addClass('sidebar-mini');

          misc.sidebar_mini_active = true;
        }, 300);
      }

      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(function () {
        window.dispatchEvent(new Event('resize'));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(function () {
        clearInterval(simulateWindowResize);
      }, 1000);
    });

    $('#hideSidebar').click(function () {
      if (misc.hide_sidebar_active === true) {
        setTimeout(function () {
          $('body').removeClass('hide-sidebar');
          misc.hide_sidebar_active = false;
        }, 300);
        setTimeout(function () {
          $('.sidebar').removeClass('animation');
        }, 600);
        $('.sidebar').addClass('animation');

      } else {
        setTimeout(function () {
          $('body').addClass('hide-sidebar');
          // $('.sidebar').addClass('animation');
          misc.hide_sidebar_active = true;
        }, 300);
      }

      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(function () {
        window.dispatchEvent(new Event('resize'));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(function () {
        clearInterval(simulateWindowResize);
      }, 1000);
    });
  }

  get pageTitle() {
    return this.utils.pageTitleSource;
  }

  onResize(event) {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const body = document.getElementsByTagName('body')[0];
    setTimeout(function () {
      toggleButton.classList.add('toggled');
    }, 500);
    body.classList.add('nav-open');
    this.sidebarVisible = true;
  };

  sidebarClose() {
    const body = document.getElementsByTagName('body')[0];
    this.toggleButton.classList.remove('toggled');
    this.sidebarVisible = false;
    body.classList.remove('nav-open');
  };

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  };

  showTutorial() {
    this.teamService.tutorialClicked(this.localData.getCurrentTeamId()).subscribe(() => {});
    this.hideTutorialVideo = false;
    $('#tutorialModal').modal('show');
  }

  closeTutorialVideo() {
    this.hideTutorialVideo = true;
  }

  async openSurvey() {
    const baseUrl = environment.surveyBaseUrl;
    let params = new HttpParams();
    const user = this.localData.getUser();
    params = params.append('userId', user.id);
    params = params.append('userEmail', encodeURI(user.email));
    params = params.append('employeeEmail', encodeURI(user.originalInvitedByEmail || ''));
    params = params.append('clientId', user.clientId || '');
    const coach = await this.teamService.getTeamCoach(this.localData.getCurrentTeamId()).toPromise();
    const coachName = `${coach?.firstName || ''} ${coach?.lastName || ''}`.trim();
    params = params.append('coachName', encodeURI(coachName));
    if (user.clientId) {
      const clientName = await this.userService.getUserClientName().toPromise()
      params = params.append('clientName', encodeURI(clientName.clientName));
    }
    const url = `${baseUrl}?${params.toString()}`;
    window.open(url, '_blank');
  }

  // utils
  getHelp() {
    setTimeout(function () {
      window.open('https://support.elivelihood.com', '_blank');
    }, 500);
  }

  logout(event) {
    event.preventDefault()
    this.authService.signOut()
      .subscribe(() => {
        this.router.navigate(['login']);
      });
  }
}
