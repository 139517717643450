import {Injectable} from '@angular/core';

// models
import {DeviceData} from './../models/device.model';
import {UserModel} from './../models/user.model';
import {TeamModel} from './../models/team.model';

// providers
import {Logger} from './logger.service';
import {Subject} from 'rxjs';

@Injectable()
export class LocalData {
  private source = 'LocalData';
  private currentTeamChangedSource = new Subject<string>();
  public currentTeamChanged$ = this.currentTeamChangedSource.asObservable();

  constructor(
    private logger: Logger,
  ) { }

  login(isLoggedIn: boolean): boolean {
    this.logger.logInfo(this.source, 'login', 'Login.');
    return this.setIsLoggedIn(isLoggedIn);
  };
clearRecipient = () => {
  localStorage.removeItem('loggedIn');
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  // email is retained for subsequent login, and to mitigate additional registration
  localStorage.removeItem('teams');
  localStorage.removeItem('currentTeamId');
  localStorage.removeItem('lastHttpRequestDate');
  this.logger.logInfo(this.source, 'logout', 'User logout.');
}
  logout() {
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('token');
    localStorage.removeItem('device');
    localStorage.removeItem('user');
    // email is retained for subsequent login, and to mitigate additional registration
    localStorage.removeItem('teams');
    localStorage.removeItem('currentTeamId');
    localStorage.removeItem('createTeamNotNow');
    localStorage.removeItem('lastHttpRequestDate');
    this.logger.logInfo(this.source, 'logout', 'User logout.');
  };

  setIsLoggedIn(isLoggedIn: boolean): boolean {
    localStorage.setItem('loggedIn', JSON.stringify(isLoggedIn));
    return JSON.parse(localStorage.getItem('loggedIn'));
  };

  isLoggedIn(): boolean {
    return !!JSON.parse(localStorage.getItem('loggedIn'));
  }

  setDevice(device: DeviceData): DeviceData {
    localStorage.setItem('device', JSON.stringify(device));
    const device_local: DeviceData = JSON.parse(localStorage.getItem('device'));
    if (device_local && device_local.uuid) {
      this.logger.device = device_local;
    }
    this.logger.logTrace(this.source, 'setDevice', null, 'getItem');
    return device_local;
  };

  getDevice(): DeviceData {
    const device_local: DeviceData = JSON.parse(localStorage.getItem('device'));
    this.logger.logTrace(this.source, 'getDevice', null, 'getItem');
    return device_local;
  }

  setUser(user: UserModel): UserModel {
    localStorage.setItem('user', JSON.stringify(user));
    return JSON.parse(localStorage.getItem('user'));
  };

  getUser(): UserModel {
    return JSON.parse(localStorage.getItem('user'));
  };

  setTeams(teams: Array<TeamModel>): Array<TeamModel> {
    localStorage.setItem('teams', JSON.stringify(teams));
    return JSON.parse(localStorage.getItem('teams'));
  };

  getTeams(): Array<TeamModel> {
    const teams_local: Array<TeamModel> = JSON.parse(localStorage.getItem('teams'));
    this.logger.logTrace(this.source, 'getTeams', null, 'getItem');
    if(teams_local) {
      return teams_local;
    } else {
      return [];
    }
  };

  getTeam(teamId: string): TeamModel {
    const teams: Array<TeamModel> = this.getTeams();
    return teams.find(function (t) {
      return (t.id.toLowerCase() === teamId.toLocaleLowerCase());
    });
  }


  setCurrentTeamId(teamId: string): string {
    localStorage.setItem('currentTeamId', teamId);
    const currentTeamId_local: string = localStorage.getItem('currentTeamId');
    this.currentTeamChangedSource.next(currentTeamId_local);
    return currentTeamId_local;
  };

  getCurrentTeamId(): string {
    return localStorage.getItem('currentTeamId');
  };

  getCreateTeamNotNow(): boolean {
    if (localStorage.getItem('createTeamNotNow')) {
      return true;
    } else {
      return false;
    }
  }

  setCreateTeamNotNow(val) {
    if (!val) {
      localStorage.removeItem('createTeamNotNow');
    } else {
      localStorage.setItem('createTeamNotNow', val.toString());
    }
  }

  setRefreshCalList(val) {
    if (!val) {
      localStorage.removeItem('refreshCalList');
    } else {
      localStorage.setItem('refreshCalList', val.toString());
    }
  }

  getRefreshCalList(): boolean {
    if (localStorage.getItem('refreshCalList')) {
      return true;
    } else {
      return false;
    }
  }

  // Chat Rooms
  setCurrentChatRoomId(chatRoomId: string): string {
    localStorage.setItem('currentChatRoomId', chatRoomId);
    return localStorage.getItem('currentChatRoomId');
  };

  getCurrentChatRoomId(): string {
    return localStorage.getItem('currentChatRoomId');
  };

  setCurrentArchivedChatRoomId(chatRoomId: string): string {
    localStorage.setItem('currentArchivedChatRoomId', chatRoomId);
    return localStorage.getItem('currentArchivedChatRoomId');
  }

  getCurrentArchivedChatRoomId(): string {
    return localStorage.getItem('currentArchivedChatRoomId');
  };

  addTmpMediaFile(fileUri: string) {
    let tmpMediaFiles: Array<string> = JSON.parse(localStorage.getItem('tmpMediaFiles'));
    if (!tmpMediaFiles) {
      tmpMediaFiles = new Array<string>();
    }
    tmpMediaFiles.push(fileUri);
    localStorage.setItem('tmpMediaFiles', JSON.stringify(tmpMediaFiles));
  }
  setLastHttpRequestDate = () => localStorage.setItem("lastHttpRequestDate", new Date().toISOString())

  getLastHttpRequestDate = () : Date => {
    const lastHttpRequestDate = localStorage.getItem("lastHttpRequestDate")
    return lastHttpRequestDate ? new Date(lastHttpRequestDate) : null
  }

  setFirebaseMessagingToken = (token: string) =>  localStorage.setItem('firebaseMessagingToken', token);

  getFirebaseMessagingToken = () => localStorage.getItem('firebaseMessagingToken');
}
