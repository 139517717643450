import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpResponse } from '@angular/common/http';

// rxjs
import {of as observableOf, throwError as observableThrowError,  Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';

// models
import { Media, MediaItem } from './../models/media.model';

// providers
import { environment } from './../environments/environment';
import { Utils } from './utils.service';
import { Logger } from './logger.service';
import {DatePaginator} from '../models/date-paginator.model';
import {DatePaginatorResponseModel} from '../models/date-paginator-response.model';


@Injectable()
export class MediaService {
  private source = 'MediaService';
  private apiBaseUrl = environment.apiBaseUrl;

  constructor(
    private http: HttpClient,
    private utils: Utils,
    private logger: Logger
  ) { }

  listMedia(teamId: string, type?: number): Observable<Media> {
    let call = 'API GET /teams/:id/media';
    if (type || type === 0) {
      call = call + '?type=' + type;
    }
    this.logger.logInfo(this.source, 'listMedia', call);
    let params = new HttpParams()
    if (type || type === 0) {
      params = params.append("type", type.toString())
    }
    const options = this.utils.getJsonHttpOptionsWithParams(null, params)
    return this.http.get(this.apiBaseUrl + '/teams/' + teamId + '/media', options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'listMedia', { status: res.status, statusText: res.statusText, teamId }, call);
        const items = this.utils.adjustTimesForArray(res.body.items);
        return {items};
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'listMedia', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  updateMedia(teamId: string, media: MediaItem): Observable<MediaItem> {
    const call = 'API PUT /teams/:id/media/:id';
    this.logger.logInfo(this.source, 'updateMedia', call);
    const body = media;
    const options = this.utils.getJsonHttpOptions()
    return this.http.put(this.apiBaseUrl + '/teams/' + teamId + '/media/' + media.id, body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'updateMedia', { status: res.status, statusText: res.statusText, teamId }, call);
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'updateMedia', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  uploadMedia(teamId: string, userId: string, mediaFile: any, thumbnailFile): Observable<MediaItem> {
    const call = 'API POST /teams/:id/media';
    this.logger.logInfo(this.source, 'uploadMedia', call);
    const body = new FormData();
    body.append('ownerId', userId);
    body.append('file', mediaFile);
    if (thumbnailFile) {
      body.append('thumbnail', thumbnailFile);
    }
    const options = this.utils.getJsonHttpOptions()
    return this.http.post(this.apiBaseUrl + '/teams/' + teamId + '/media', body, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'uploadMedia', { status: res.status, statusText: res.statusText, teamId, userId }, call);
        return this.utils.adjustTimes(res.body);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'uploadMedia', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  deleteMedia(teamId: string, mediaId: string): Observable<Observable<number>> {
    const call = 'API DELETE /teams/:id/media/:id';
    this.logger.logInfo(this.source, 'deleteMedia', call);
    const options = this.utils.getJsonHttpOptions()
    return this.http.delete(this.apiBaseUrl + '/teams/' + teamId + '/media/' + mediaId, options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'deleteMedia', { status: res.status, statusText: res.statusText, teamId, mediaId }, call);
        return observableOf(res.status);
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'deleteMedia', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  listMediaV2 = (teamId: string,model: DatePaginator): Observable<DatePaginatorResponseModel<MediaItem>> => {
    const url = `teams/${teamId}/media/list`
    const call = `API POST ${url}`
    const options = this.utils.getJsonHttpOptions()
    return this.http.post(`${this.apiBaseUrl}/${url}`,model,options).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'listMediaV2', { status: res.status, statusText: res.statusText, teamId }, call);
        return res.body
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'listMediaV2', err, 'API Error');
        return observableThrowError(err);
      }),);
  }
}
