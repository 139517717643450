import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

// rxjs
import {throwError as observableThrowError,  Observable, of, EMPTY } from 'rxjs';
import {catchError, map, mergeMap, tap} from 'rxjs/operators';

// models
import { DeviceData } from './../models/device.model';

// providers
import { environment } from './../environments/environment';
import { Logger } from './logger.service';
import { UserService } from './user.service';
import { LocalData } from './local-data.service';
import { ApiResponseModel } from '../models/api-response.model';

@Injectable()
export class AuthService {
  private source = 'AuthService';
  private apiBaseUrl = environment.apiBaseUrl;

  constructor(
    private http: HttpClient,
    private logger: Logger,
    private userService: UserService,
    private localData: LocalData
  ) { }

  // login and authentication
  login(email: string, password: string, device: DeviceData, uuid: string, recaptchaToken: string, token?: string): Observable<boolean | ApiResponseModel> {
    this.logger.logInfo(this.source, 'login', 'API post /tokens');
    const body = { username: email, password, device: JSON.stringify(device), uuid, recaptchaToken, token };
    const headers = new HttpHeaders({elNoRefresh: 'yes'});
    return this.http.post(this.apiBaseUrl + '/tokens/authenticate', body, {observe: "response", headers}).pipe(
      map((res: HttpResponse<any |ApiResponseModel>) => {
        this.logger.logDebug(this.source, 'login', { status: res.status, statusText: res.statusText }, 'API get /tokens');
        return res.body || res.ok;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'login', err, 'API Error');
        return observableThrowError(err);
      }));
  }

  signUp(email: string, recaptchaToken: string, check_allowed_domains: boolean, clientApp: string = "EL"): Observable<boolean> {
    this.logger.logInfo(this.source, 'signUp', 'API post /tokens/signup');
    const body = { email, recaptchaToken, check_allowed_domains};
    return this.http.post(this.apiBaseUrl + '/tokens/signup?clientApp=' + clientApp, body, {observe: "response"}).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'signUp', { status: res.status, statusText: res.statusText }, 'API post /tokens');
        return res.status === 200;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'signUp', err, 'API Error');
        return observableThrowError(err);
      }));
  }

  signOut(): Observable<any> {
    this.logger.logInfo(this.source, 'signOut', 'API post /tokens/signout');
    const headers = new HttpHeaders({elNoRefresh: 'yes'});
    return this.http.get(this.apiBaseUrl + '/tokens/signout', {observe: "response", headers}).pipe(
      mergeMap((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'signOut', { status: res.status, statusText: res.statusText }, 'API post /tokens/signout');
        return res.status === 200 ? of(true) : EMPTY;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'signOut', err, 'API Error');
        if (err.status === 401) {
          return of(true)
        }
        return observableThrowError(err);
      }),
      tap(_ => {
        this.localData.logout();
        this.userService.removeCachedUserSelf();
      }),);
  }

  refreshToken(): Observable<any> {
    this.logger.logInfo(this.source, 'refreshToken', 'API get /tokens/refresh-token');
    const headers = new HttpHeaders({elNoRefresh: 'yes'});
    return this.http.get(this.apiBaseUrl + '/tokens/refresh-token', {observe: "response", headers}).pipe(
      mergeMap((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'refreshToken', { status: res.status, statusText: res.statusText }, 'API get /tokens/refresh-token');
        return res.status === 200 ? of(true) : EMPTY;
      }),
      catchError((err: any) => {
        this.logger.logError(this.source, 'refreshToken', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  canRefreshToken = (): Observable<boolean> => {
    this.logger.logInfo(this.source, 'refreshToken', 'API get /tokens/can-refresh-token');
    const headers = new HttpHeaders({elNoRefresh: 'yes'});
    return this.http.get(this.apiBaseUrl + '/tokens/can-refresh-token', {observe: "response", headers}).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'refreshToken', { status: res.status, statusText: res.statusText }, 'API get /tokens/can-refresh-token');
        return res.ok;
      }),
      catchError((err: any) => {
        if (err.status === 401) {
          return of(false)
        }
        this.logger.logError(this.source, 'refreshToken', err, 'API Error');
        return observableThrowError(err);
      }),);
  }

  isAuthenticated(): Observable<any> {
    this.logger.logInfo(this.source, 'isAuthenticated', 'API get /tokens/authenticated');
    return this.http.get(this.apiBaseUrl + '/tokens/authenticated', {observe: "response"}).pipe(
      map((res: HttpResponse<any>) => {
        this.logger.logDebug(this.source, 'isAuthenticated', { status: res.status, statusText: res.statusText }, 'API get /tokens/authenticated');
        return res.ok;
      }),
      catchError((err: any) => {
        if (err.status === 401) {
          return of(false);
        }
        this.logger.logError(this.source, 'isAuthenticated', err, 'API Error');
        return observableThrowError(err);
      }),);
  }
}
