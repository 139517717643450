<h1 mat-dialog-title>Contact Us</h1>
<div mat-dialog-content>
  <mat-list>
    <mat-list-item>
      Phone: <a href="tel:303-663-0306" style="margin-left: 1rem;">303-663-0306</a>
    </mat-list-item>
    <mat-list-item>
      Email: <a href="mailto:support@elivelihood.com" style="margin-left: 1rem;">support@elivelihood.com</a>
    </mat-list-item>
    <mat-list-item>
      Hours of Operation: <span style="margin-left: 1rem;">8am-5pm MST</span>
    </mat-list-item>
  </mat-list>
</div>

<h1 mat-dialog-title style="margin-top:1em">Tutorials</h1>
<div mat-dialog-content>
  <mat-tab-group>
    <mat-tab label="Overview">
      <iframe src="https://player.vimeo.com/video/964639249?h=ccb7e772e4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=0" class="tutorialVideo" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </mat-tab>
    <mat-tab label="Messages">
      <iframe src="https://player.vimeo.com/video/964639164?h=781eba1377&title=0" class="tutorialVideo" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </mat-tab>
    <mat-tab label="Calendar">
      <iframe src="https://player.vimeo.com/video/964639130?h=bb56572af4&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479&title=0" class="tutorialVideo" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </mat-tab>
  </mat-tab-group>
</div>
<div mat-dialog-actions align="end">
  <button mat-button color="primary" mat-dialog-close cdkFocusInitial>Close</button>
</div>
