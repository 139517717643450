import {
  AfterViewInit,
  ContentChildren,
  Directive,
  ElementRef,
  QueryList
} from '@angular/core';


@Directive({
  selector: '[appStretchMatErrors]'
})
export class StretchMatErrorsDirective implements AfterViewInit {
  @ContentChildren('matError', { descendants: false }) matErrors!: QueryList<ElementRef>;
  private matFormFieldHeight: number;

  constructor(private elRef: ElementRef) {
  }

  ngAfterViewInit() {
    this.matFormFieldHeight = parseFloat(
      getComputedStyle(this.elRef.nativeElement)
        .getPropertyValue('height')
    );
    this.registerDomChangedEvent(this.elRef.nativeElement);
  }

  registerDomChangedEvent(el) {
    const observer = new MutationObserver((list) => {
      this.handleChanges(this.elRef, this.matErrors);
    });
    const childList = true;
    const subtree = true;
    const attributes = true;
    observer.observe(el, { childList, subtree, attributes });
  }

  private handleChanges(elRef, matErrors) {
    if (matErrors.length < 2) {
      elRef.nativeElement.style.height = `${this.matFormFieldHeight}px`;
      return;
    }

    const oneErrorHeight = parseFloat(
      getComputedStyle(matErrors.first.nativeElement)
        .getPropertyValue('height')
    );
    const numberOfExtraErrors = matErrors.length - 1;

    const newHeight = this.matFormFieldHeight + oneErrorHeight * numberOfExtraErrors;
    elRef.nativeElement.style.height = `${newHeight}px`;
  }
}
