<div class="container-fluid nav-container">
  <nav class="row coach-nav">
    <div class="col-md-10 brand-and-nav">
      <div class="center-vertical mr-10">
        <a [routerLink]="['/coach']">
          <img
              src="./assets/img/logo_BH-1-170-white.png"
              alt="Bright Horizons"/>
        </a>
      </div>
    </div>
    <div class="col-md-2 center-vertical justify-end">
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>person</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <a mat-menu-item color="primary" [routerLink]="['user']">Profile</a>
        <mat-divider></mat-divider>
        <button mat-menu-item color="primary" (click)="logout()">Logout</button>
      </mat-menu>
    </div>
  </nav>
</div>
<router-outlet></router-outlet>
