export const timezones = [
  {
    name: '(PT) - Pacific Time',
    value: 'PT'
  },
  {
    name: '(MT) - Mountain Time',
    value: 'MT'
  },
  {
    name: '(CT) - Central Time',
    value: 'CT'
  },
  {
    name: '(ET) - Eastern Time',
    value: 'ET'
  },
  {
    name: '(AT) - Alaska Time',
    value: 'AT'
  },
  {
    name: '(HT) - Hawaii Time',
    value: 'HT'
  }
];
